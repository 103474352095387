export default {
  port: 8081,
  apiUrl: 'https://api-production.bln.hm',
  octaneUrl: 'https://octane.bln.hm',
  host: 'https://belonghome.com',
  gaId: 'UA-122475191-1',
  ga4Id: 'G-VRYJSZZ0YB',
  gtmId: 'GTM-T84J732',
  gtmView: '',
  lAExperimentOptimizeId: '3scwZ8o_SlaSUuZqzlUgig',
  homeownersLpExperimentOptimizeId: '9setPTeYThe5sLPgwv5yTw',
  zendeskKey: 'df4d785d-b0da-4fe3-a0cd-0e51ddedb568',
  intercomId: 'f0yozsg1',
  facebookAppId: '301983933729862',
  plaidEnv: 'production',
  mixpanelApiKey: '7a66fd8954f817909009499258604849',
  smsAssistUrl: 'https://belonghomersp-one.smsassist.com/BHI/Account/SingleSignOn?token=',
  googleRecaptchaSitekey: '6LfVENMpAAAAAIV2g_yF0CbOGt1P0WKF9CLyrIx_',
};
