import { EmployeeAssignmentTypes } from 'models/enums';

export const findEmployeeByType = (employees, type) =>
  employees.find((employee) => employee.employeeAssignment.assignmentType === type);

export const chrisProfile = {
  employee: {
    bannerImageUrl: 'https://bln-prd-employee-banner-images.s3-us-west-1.amazonaws.com/profile_banner_chirs.jpg',
  },
  employeeAssignment: {
    assignmentType: EmployeeAssignmentTypes.HeadOfOperations,
  },
  user: {
    firstName: 'Chris',
    lastName: 'Woods',
    aboutMe:
      'I joined Belong because i have rented apartments across 4 continents and every one of them was a terrible experience; I am determined for Belong to do this better than anyone. I manage Operations at Belong, bringing 11 years of experience in Operations and Finance to the role. Prior to Belong, I spent many years working at Uber. I am a big fan of changing the world.',
    homeTown: 'Anglo-Aussie with no particular "hometown"',
    profileImageUrl: 'https://s3-us-west-1.amazonaws.com/bln-prd-usr-pics/1146-1dad20db859cf0a',
  },
};

export const erinProfile = {
  employee: {
    bannerImageUrl: 'https://bln-prd-employee-banner-images.s3-us-west-1.amazonaws.com/profile_banner_erin.jpg',
  },
  employeeAssignment: {
    assignmentType: EmployeeAssignmentTypes.HeadOfExperience,
  },
  user: {
    firstName: 'Erin',
    lastName: 'Anderson',
    aboutMe:
      'I joined Belong because I believe every person deserves to experience the feeling of home. I personally have moved around to new cities in search of the feeling of home, often finding it harder than it should be. My driving mission as Head of Experience at Belong is to ensure each person feels at home on day one. Prior to Belong, I worked on building magical experiences for community members at Airbnb, Postmates, and Lyft. I am big fan of dogs, skiing, Bay area hikes, and NY pizza.',
    homeTown: 'East Northport, New York',
    profileImageUrl: 'https://s3-us-west-1.amazonaws.com/bln-prd-usr-pics/5549-37ff4a85442e8d6d',
  },
};

export const FORMATTED_EMPLOYEES = {
  HEAD_OF_OPERATIONS: {
    aboutMe:
      'An avid sailor and skier born and raised in Sweden but has been living abroad for the last two decades - on three different continents. I love building/fixing things, mechanical watches and great food -',
    assignmentType: 'HeadOfOperations',
    bannerImage: 'https://s3-us-west-1.amazonaws.com/bln-prd-employee-banner-images/55924-2924b62a1b879667',
    bannerImageUrl: 'https://s3-us-west-1.amazonaws.com/bln-prd-employee-banner-images/55924-2924b62a1b879667',
    college: '',
    firstName: 'Anthon',
    homeTown: 'Halmstad Sweden',
    jobTitle: 'Head of Operations',
    lastName: 'Hollstein Ivarsson',
    memberSince: '2021-09-27T02:41:07.0561366+00:00',
    name: 'Anthon Ivarsson',
    profileImage: 'https://s3-us-west-1.amazonaws.com/bln-prd-usr-pics/55924-108a46af06924d6e',
    profileImageUrl: 'https://s3-us-west-1.amazonaws.com/bln-prd-usr-pics/55924-108a46af06924d6e',
    quote: '',
    sourceType: 'Home',
    workEmail: 'ivarsson.anthon@belonghome.com',
  },
  HEAD_OF_PRICING: {
    aboutMe:
      "Grew up in a small town in Romania. Moved to Chicago then Los Angeles for graduate school. For the past 3+ years, has been enjoying San Francisco's eclectic cuisine, music and culture. Enjoys traveling, reading and spending quality time with family & friends. Leads data and pricing at Belong.",
    assignmentType: 'HeadOfPricing',
    bannerImage: 'https://s3-us-west-1.amazonaws.com/bln-prd-employee-banner-images/91647-1d6ef22ccbf91ff3',
    bannerImageUrl: 'https://s3-us-west-1.amazonaws.com/bln-prd-employee-banner-images/91647-1d6ef22ccbf91ff3',
    college: '',
    firstName: 'Madelaine',
    homeTown: 'Romania',
    jobTitle: 'Pricing Lead',
    lastName: 'Daianu',
    memberSince: '2022-04-16T19:53:37.3783279+00:00',
    name: 'Madelaine Daianu',
    profileImage: 'https://s3-us-west-1.amazonaws.com/bln-prd-usr-pics/91647-3eba533d78ee5dce',
    profileImageUrl: 'https://s3-us-west-1.amazonaws.com/bln-prd-usr-pics/91647-3eba533d78ee5dce',
    quote: '',
    sourceType: 'Home',
    workEmail: 'daianu.madelaine@belonghome.com',
  },
  HEAD_OF_EXPERIENCE: {
    aboutMe:
      "I live in Salt Lake City with my wife and daughter - we love to be outdoors and spend time in the mountains. When the weather is nice you'll often find me playing sand volleyball or pickleball.",
    assignmentType: 'HeadOfExperience',
    bannerImage: 'https://s3-us-west-1.amazonaws.com/bln-prd-employee-banner-images/40566-16351f696b5c7f27',
    bannerImageUrl: 'https://s3-us-west-1.amazonaws.com/bln-prd-employee-banner-images/40566-16351f696b5c7f27',
    college: '',
    firstName: 'Spencer',
    homeTown: 'Boise',
    jobTitle: 'Head of Experience',
    lastName: 'Vail',
    memberSince: '2021-06-07T21:39:41.8223133+00:00',
    name: 'Spencer Vail',
    profileImage: 'https://s3-us-west-1.amazonaws.com/bln-prd-usr-pics/40566-8f9852303090ef4d',
    profileImageUrl: 'https://s3-us-west-1.amazonaws.com/bln-prd-usr-pics/40566-8f9852303090ef4d',
    quote: '',
    sourceType: 'Home',
    workEmail: 'vail.spencer@belonghome.com',
  },
};

export const betterRenewals = {
  title: 'Renewing Your Resident’s Lease',
  residentTitle: 'Let’s Renew Your Lease',
};

const bubbleTexts = {
  // These are milestones
  AgreementSigned: `Welcome, {{ customer.first_name }}! Your home's about to get a whole lot of love. To start, let's set your home up for success by making sure we have everything we need to get your home\u00a0loved.`,
  InspectionScheduled: `Time to get your home ready to be loved! We'll put a plan in place to make sure it is ready to be shown and meets Belong's standards for quality and\u00a0care.`,
  InspectionCompleted: `Time to get your home ready to be loved! We'll put a plan in place to make sure it is ready to be shown and meets Belong's standards for quality and\u00a0care.`,
  PostInspectionProposalSent: `Your home's plan is ready for review. Once approved, we'll get right to work, and have your home loved in no\u00a0time.`,
  PostInspectionProposalApproved: `Sounds like a plan! We'll get right to work preparing your home for\u00a0love.`,
  ShowReady: `Now's the time to get your home ready for the big stage. We'll make sure it's shown in it's best\u00a0light.`,
  FullListingPublished: `It's show time! We'll chase every lead that comes your home's way, and send you weekly updates on\u00a0progress.`,
  LeaseSigned: `Alright! We found someone to love your\u00a0home!`,
  LeaseExecuted: `Alright! We found someone to love your\u00a0home!`,
  MoveInReady: `Your home is all set, ready and waiting for your new Residents to\u00a0arrive.`,
  ResidentMovedIn: `Your home is feeling the love! Your Residents are settled in, and we're making sure they're well taken care\u00a0of.`,
  ManagingAdoptedResidents: `Your Residents are all set up with their Belong account. We excited to take care of\u00a0them.`,
  ResidentGaveMoveOutNotice: `Looks like your Residents are moving on. Time for us to work our magic and find someone new to love your\u00a0home!`,

  // This is an event.
  LeaseRenewed: `Looks like your Residents really love your home! They've renewed their lease, and we've renewed our vows to make sure your home is cared\u00a0for.`,
};

export const getBubbleText = (unit, timeline, userFirstName) => {
  if (unit?.basicInfo?.onboardingInfo?.notInterestedInBelongServices) {
    return `Thank you for letting us know, ${userFirstName}. We'll be in touch soon to discuss next steps (and see if there's anything we can do to change <span>your mind).</span>`;
  }

  // All Text changes match a milestone except for LeaseRenewed
  let lastMilestoneOrRenewal;
  if (Array.isArray(timeline)) {
    for (const event of timeline) {
      if (event.timelineEventType === 'LeaseRenewed') {
        lastMilestoneOrRenewal = bubbleTexts.LeaseRenewed;
        break;
      }

      // If event has milestones, check if one of them matches a bubble text.
      const milestoneMatch = event?.milestones?.find((milestone) => {
        return bubbleTexts[milestone.name];
      });
      if (milestoneMatch) {
        lastMilestoneOrRenewal = bubbleTexts[milestoneMatch.name];
        break;
      }
    }
  }

  return lastMilestoneOrRenewal?.replace('{{ customer.first_name }}', userFirstName);
};
