import { IconInfo } from '@belong/icons';
import { Tooltip, Money } from '@belong/ui';
import { MaintenanceModel, ReportDetailModel } from 'api/models';
import classNames from 'classnames/bind';
import Collapsible from 'components/Collapsible/Collapsible';
import NameValueTable from 'components/NameValueTable/NameValueTable';
import { BackedByBelong } from 'containercomponents/MaintenanceStatus/MaintenanceCostDisplay/MaintenanceCostDisplay';
import { SplitInfoBox } from 'containercomponents/MaintenanceStatus/split-infobox/split-infobox';
import Space from 'corecomponents/Space/Space';
import { Flex, Box, Text } from 'design-system';
import { useModal } from 'hooks/useModal';
import { MaintenanceRequester } from 'models/enums';
import PriceTable, {
  getHomeownerBundlePriceSection,
} from 'pages/PostInspectionFlow/steps/Improvements/Approval/PriceTable/PriceTable';
import { formatString } from 'strings';
import styles from './GroupHomeownerReceiptCosts.module.css';

const cx = classNames.bind(styles);

type GroupHomeownerReceiptCostsProps = {
  groupTask: MaintenanceModel;
  report?: ReportDetailModel;
};

export const GroupHomeownerReceiptCosts = ({ groupTask, report }: GroupHomeownerReceiptCostsProps) => {
  const [isModalOpen, setModalOpen, setModalClose] = useModal();

  const homeownerPayment = report.details?.group?.payments?.find((p) => p.paidBy === MaintenanceRequester.Homeowner);

  if (!homeownerPayment || !groupTask) return null;

  const residentPayment = report.details.group.payments.find((p) => p.paidBy === MaintenanceRequester.Resident);
  const belongPayment = report.details.group.payments.find((p) => p.paidBy === MaintenanceRequester.Belong);

  const isBilledByGroup = report.details.group.isDoNotExceedGroup && report.details.group.isLegacyInvoicing;
  const isInvoiced = report.details.group.requestTypeDetails.hasPublishedInvoices && !isBilledByGroup;
  const { isLegacyInvoicing, financedPayment, result, payments, priceRange } = groupTask;

  const residentRange = priceRange?.priceRangeBreakdown?.find((range) => range.paidBy === 'Resident');
  const belongRange = priceRange?.priceRangeBreakdown?.find((range) => range.paidBy === 'Belong');

  const isMonthly = homeownerPayment.paymentPlan === 'Monthly';

  const showMonthlyAmount = !isInvoiced && isMonthly && homeownerPayment.installmentCount;

  const isImprovementsReport =
    ['HomeOnboarding', 'MoveOutRepairs'].includes(groupTask?.requestCategory) &&
    ['Improvements', 'Move Out Repairs'].includes(groupTask?.summary);

  const isMoveOut = groupTask?.requestCategory === 'MoveOutRepairs';

  const hideItemsCost = !!(isImprovementsReport && groupTask?.isDoNotExceedGroup);

  const bundleSections = getHomeownerBundlePriceSection({
    bundles: report.details.bundles,
    priceRange: null,
    isInvoiced,
    hideItemsCost,
    inspectionType: {
      isOnboarding: groupTask?.requestCategory === 'HomeOnboarding',
      isMoveOut,
    },
    onChange: null,
  });

  const groupPayment = payments.find((p) => p.paidBy === MaintenanceRequester.Homeowner);
  const financePlanId = groupPayment?.balances?.find((balance) => !!balance.currentFinanceId)?.currentFinanceId;
  const hasElegibleOpenBalance = !!groupPayment?.balances?.find((balance) => balance.isOpen && balance.isFinanceable);

  let costs;
  if (isInvoiced) {
    costs = {
      subtotal: 'actualAdjustmentApplied',
      discount: 'actualDiscountAmount',
      netTotal: 'actualNetTotal',
    };
  } else {
    costs = {
      subtotal: 'estimatedAdjustmentApplied',
      discount: 'estimatedDiscountAmount',
      netTotal: 'estimatedDiscountApplied',
      installmentEach: 'estimatedInstallmentEach',
    };
  }

  const homeownerSubtotal =
    homeownerPayment[costs.subtotal] +
    (residentPayment?.[costs.subtotal] || 0) +
    (belongPayment?.[costs.subtotal] || 0);

  const tooltip = (
    <Tooltip
      content={homeownerPayment.installmentCount === 1 ? '1% fee ($20 minimum)' : '1% fee/month ($20 minimum)'}
    />
  );

  const isSubtotalVisible =
    homeownerPayment[costs.discount] > 0 || report?.report?.subType !== 'ImprovementsRepairsInspection';

  const isDueUpfrontCostVisible = !groupTask?.isDoNotExceedGroup && financedPayment?.dueNow > 0 && !isInvoiced;

  const isDueNowVisible = !isInvoiced && homeownerPayment.estimatedUpfrontAmount > 0 && !groupTask?.isDoNotExceedGroup;

  const neverExceedCopy = (
    <div className="flex">
      <span className="mr-2xs">Never Exceed</span>

      <Tooltip
        contentProps={{ className: 'z-50' }}
        content={
          <div className="p-2sm rounded bg-dark-navy max-w-md border-dark-navy">
            <p className="p1 text-white font-regular text-left">
              We guarantee you&apos;ll never pay more than this approved amount. If costs exceed it, Belong will cover
              the difference.
            </p>
          </div>
        }
        arrowProps={{
          className: 'fill-dark-navy border-dark-navy',
        }}
      >
        <button className="cursor-pointer fill-navy flex items-center" type="button">
          <IconInfo width={18} />
        </button>
      </Tooltip>
    </div>
  );

  const totalCostCopy = isInvoiced ? 'Subtotal' : groupTask?.isDoNotExceedGroup ? neverExceedCopy : 'Est. Total';

  const hoTotalCopy = isInvoiced ? 'Total' : isMoveOut ? 'Your Estimated Expenses' : neverExceedCopy;

  return (
    <Collapsible
      createMarginAndPaddingClassName={cx('margin-padding')}
      collapsed={false}
      title={isInvoiced ? 'Receipt' : 'Estimated Pricing'}
      inner={
        <div className={cx('content')}>
          <BackedByBelong showModal={isModalOpen} onClick={setModalOpen} onHide={setModalClose} />
          <Space />
          <div className={cx('maintenanceCostDisplay')}>
            <PriceTable nameHeader="Item" valueHeader="Amount" sections={[bundleSections]} />
            {isSubtotalVisible && (
              <>
                <div className={cx('underline')} />

                <NameValueTable
                  noBold
                  items={[
                    {
                      name: totalCostCopy,
                      value: <Money value={homeownerSubtotal} />,
                    },
                  ]}
                />

                {/* Deductions */}
                {!!homeownerPayment[costs.discount] && (
                  <NameValueTable
                    noBold
                    discountValue
                    items={[
                      {
                        name: 'Savings',
                        value: (
                          <span className="font-semibold flex">
                            -<Money format="DOLLARS_NO_CENTS" value={homeownerPayment[costs.discount]} />
                          </span>
                        ),
                      },
                    ]}
                  />
                )}
              </>
            )}
            {(residentPayment?.[costs.subtotal] > 0 || residentRange?.upperBound > 0) && (
              <NameValueTable
                noBold
                discountValue
                items={[
                  {
                    name: 'Resident Responsibility',
                    value: (
                      <p className="text-navy">
                        -<Money as="span" value={residentPayment[costs.subtotal]} />
                      </p>
                    ),
                  },
                ]}
              />
            )}
            {(belongPayment?.[costs.subtotal] > 0 || belongRange?.upperBound > 0) && (
              <NameValueTable
                noBold
                discountValue
                items={[
                  {
                    name: 'Belong Responsibility',
                    value: (
                      <p>
                        -<Money as="span" value={belongPayment[costs.subtotal]} />
                      </p>
                    ),
                  },
                ]}
              />
            )}

            {!isMoveOut && <div className={cx('underline')} />}

            {isLegacyInvoicing ? (
              <>
                <NameValueTable
                  boldValue
                  items={[
                    {
                      name: hoTotalCopy,
                      value: (
                        <Money
                          className="font-semibold"
                          value={homeownerPayment[costs.netTotal]}
                          format="DOLLARS_WITH_CENTS"
                        />
                      ),
                    },
                  ]}
                />
                {isInvoiced && isMonthly && (
                  <Flex justifyContent="flex-end">
                    <Text variant="body-responsive" mr="xs">
                      (w/ installment fees)
                    </Text>
                    {tooltip}
                  </Flex>
                )}
                {showMonthlyAmount && (
                  <>
                    <div className={cx('underline')} />
                    <NameValueTable
                      boldValue
                      items={[
                        {
                          name: (
                            <Flex>
                              <Box mr="xs">
                                {formatString(
                                  `With ${homeownerPayment.installmentCount}-Month <span>Payment Plan</span>`
                                )}
                              </Box>
                              {tooltip}
                            </Flex>
                          ),
                          value: (
                            <>
                              <Money format="DOLLARS_NO_CENTS" value={homeownerPayment[costs.installmentEach]} />
                              /mo.
                            </>
                          ),
                        },
                      ]}
                    />
                  </>
                )}
                {isDueNowVisible && (
                  <NameValueTable
                    boldValue
                    items={[
                      {
                        name: (
                          <Flex>
                            <Box mr="xs">Due Now</Box>
                          </Flex>
                        ),
                        value: (
                          <>
                            <Money format="DOLLARS_NO_CENTS" value={homeownerPayment.estimatedUpfrontAmount} />
                          </>
                        ),
                      },
                    ]}
                  />
                )}
              </>
            ) : (
              <>
                <NameValueTable
                  boldValue
                  items={[
                    {
                      name: totalCostCopy,
                      value: <Money value={homeownerPayment[costs.netTotal]} />,
                    },
                  ]}
                />
                {isDueUpfrontCostVisible && (
                  <NameValueTable
                    boldValue
                    items={[
                      {
                        name: (
                          <Flex>
                            <Box mr="xs">Due Upfront</Box>
                          </Flex>
                        ),
                        value: (
                          <>
                            <Money format="DOLLARS_NO_CENTS" value={financedPayment?.dueNow} />
                          </>
                        ),
                      },
                    ]}
                  />
                )}
                {!hideItemsCost && (
                  <div className="mt-sm">
                    <SplitInfoBox
                      financePlanId={financePlanId}
                      hasOpenBalance={hasElegibleOpenBalance}
                      isMaintenanceCompleted={result === 'Completed'}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      }
    />
  );
};
