import config from 'config/config';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import './GoogleMap.module.css';

const defaultIcon = '/map_icons/home_map_pricing_pin_05.png';

const Point = () => (
  <img
    src={defaultIcon}
    alt=""
    style={{
      height: '62px',
      width: '34px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }}
  />
);

const GoogleMap = ({ lat, lng, zoom, controls, ...rest }) => {
  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: `https://maps.googleapis.com/maps/api/js?key=${config.googleMapsApiKey}&libraries=placesgeometry,drawing&language=en`,
      }}
      options={
        controls
          ? {
              streetViewControl: true,
            }
          : {
              streetViewControl: false,
              zoomControl: false,
              disableDefaultUI: false,
              fullscreenControl: false,
              draggable: false,
            }
      }
      zoom={zoom}
      center={{
        lat,
        lng,
      }}
      {...rest}
    >
      <Point lat={lat} lng={lng} />
    </GoogleMapReact>
  );
};

GoogleMap.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  zoom: PropTypes.number,
  controls: PropTypes.bool,
};

GoogleMap.defaultProps = {
  zoom: 11,
  controls: true,
};

export default GoogleMap;
