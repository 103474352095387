import { Image, Money } from '@belong/ui';
import { STRINGS } from 'post-inspection/constants/strings';
import type { ReportPayment } from 'post-inspection/models/improvements-report';

type Props = {
  homeownerReportPayment: ReportPayment;
  totalCost: number;
};

export const MoveoutHomeownerImprovementsTotal = ({ totalCost, homeownerReportPayment }: Props) => {
  return (
    <>
      <div className="flex justify-between mb-md">
        <p className="font-semibold text-p1 md:text-body">{STRINGS['homeowner.moveoyt.total.title']}</p>
        <p className="flex flex-col items-end">
          <Money fontWeight="semibold" format="DOLLARS_NO_CENTS" className="text-p1 md:text-body" value={totalCost} />
          {Boolean(homeownerReportPayment.groupDueNow) && (
            <div className="-mt-2xs">
              <Money
                as="span"
                className="text-p1"
                format="DOLLARS_WITH_CENTS"
                value={homeownerReportPayment.groupDueNow}
              />
              &nbsp;
              <span className="text-p1">{STRINGS['due-now']}</span>
            </div>
          )}
        </p>
      </div>
      <div className="flex flex-col sm:items-start sm:flex-row md:items-center sm:justify-between">
        <div className="flex items-start md:items-center mb-lg sm:mb-0">
          <div className="mr-xs">
            <Image alt="plan-it" className="h-auto w-[40px]" src="/improvement-reports/plan.svg" />
          </div>
          <div className="flex flex-col">
            <p className="mb-2xs text-p1 md:text-body">{STRINGS['up-to-12-month-financing-available']}</p>
            <div className="flex md:hidden -mt-xs">
              <span className="font-semibold text-p1 md:text-body">{STRINGS.est}</span>
              &nbsp;
              <Money
                as="span"
                fontWeight="semibold"
                format="DOLLARS_NO_CENTS"
                className="text-p1 md:text-body"
                value={homeownerReportPayment.monthlyPayment}
              />
              <span className="font-semibold text-p1 md:text-body">{STRINGS.monthly}</span>
            </div>
          </div>
        </div>

        <div className="hidden md:flex justify-end">
          <span className="font-semibold">{STRINGS.est}</span>
          &nbsp;
          <Money
            as="span"
            fontWeight="semibold"
            format="DOLLARS_NO_CENTS"
            value={homeownerReportPayment.monthlyPayment}
          />
          <span className="font-semibold">{STRINGS.monthly}</span>
        </div>
      </div>
    </>
  );
};
