import { getApiActionTypes } from '../redux_utils';

export const ACTIONS = {
  // Register
  USER_REGISTER_REQUEST: 'user/register/request',
  USER_REGISTER_SUCCESS: 'user/register/success',
  USER_REGISTER_FAILURE: 'user/register/failure',

  // Login
  USER_LOGIN_REQUEST: 'user/login/request',
  USER_LOGIN_SUCCESS: 'user/login/success',
  USER_LOGIN_FAILURE: 'user/login/failure',

  // Fetch
  USER_FETCH_REQUEST: 'user/fetch/request',
  USER_FETCH_SUCCESS: 'user/fetch/success',
  USER_FETCH_FAILURE: 'user/fetch/failure',

  // Forgot password
  USER_FORGOT_PASSWORD_REQUEST: 'user/forgot-password/request',
  USER_FORGOT_PASSWORD_SUCCESS: 'user/forgot-password/success',
  USER_FORGOT_PASSWORD_FAILURE: 'user/forgot-password/failure',

  // Reset password
  USER_RESET_PASSWORD_REQUEST: 'user/reset-password/request',
  USER_RESET_PASSWORD_SUCCESS: 'user/reset-password/success',
  USER_RESET_PASSWORD_FAILURE: 'user/reset-password/failure',

  // Set password
  USER_SET_PASSWORD_REQUEST: 'user/set-password/request',
  USER_SET_PASSWORD_SUCCESS: 'user/set-password/success',
  USER_SET_PASSWORD_FAILURE: 'user/set-password/failure',

  USER_DATA_CLEAR: 'USER_DATA_CLEAR',
  USER_SET_SESSION: 'USER_SET_SESSION',

  ...getApiActionTypes('USER_SESSION_REGISTER'),
  ...getApiActionTypes('USER_FETCH_DWOLLA_TOKEN'),
  ...getApiActionTypes('USER_ADD_PAYMENT_METHOD'),
  ...getApiActionTypes('USER_ADD_PAYMENT_METHOD_CC'),
  ...getApiActionTypes('USER_FETCH_PAYMENT_METHODS'),
  ...getApiActionTypes('UPDATE_DEFAULT_PAYMENT'),
  ...getApiActionTypes('UPDATE_USER_PROFILE'),
  ...getApiActionTypes('UPDATE_USER_LEGAL_IDENTITY_INFO'),
  ...getApiActionTypes('FETCH_IDENTITY_VERIFICATION_INFO'),
  ...getApiActionTypes('CREATE_IDENTITY_VERIFICATION_INFO'),
  ...getApiActionTypes('FETCH_USER_DETAILS_BY_ID'),
  ...getApiActionTypes('FETCH_CURRENT_USER_DETAILS'),
  ...getApiActionTypes('FETCH_USER_CLAIMS'),
  ...getApiActionTypes('UPDATE_USER_PHONE'),
  ...getApiActionTypes('FETCH_USER_TODOS'),
  ...getApiActionTypes('FETCH_REPORTS'),
  ...getApiActionTypes('FETCH_FLOWS'),
  ...getApiActionTypes('FETCH_USER_DEVICES'),
  ...getApiActionTypes('FETCH_USER_AUTO_SPLIT_PAYMENT_METHOD'),
  ...getApiActionTypes('UPDATE_USER_AUTO_SPLIT_PAYMENT_METHOD'),
  ...getApiActionTypes('FETCH_USER_PAYMENT_METHOD_CONFIGURATIONS'),
  ...getApiActionTypes('FETCH_USER_VERIFICATIONS'),
  ...getApiActionTypes('FETCH_USER_IDENTITY_VERIFICATION'),
  ...getApiActionTypes('CREATE_USER_IDENTITY_VERIFICATION'),
};
