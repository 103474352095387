export const PET_TYPES = {
  ALL: 'any_pets',
  DOGS: 'dogs_only',
  CATS: 'cats_only',
  NONE: 'null',
};

export const NO_LIMIT_ITEM = {
  value: 'No limit (within reason)',
  key: 'no_limit',
};

export const DOG_BREEDS = [
  { value: 'Aberdeen Terrier', key: 'Aberdeen Terrier' },
  { value: 'Affenpinscher', key: 'Affenpinscher' },
  { value: 'Afghan Hound', key: 'Afghan Hound' },
  { value: 'African Lion Hound', key: 'African Lion Hound' },
  { value: 'Airedale Terrier', key: 'Airedale Terrier' },
  { value: 'Akita', key: 'Akita' },
  { value: 'Akita Inu', key: 'Akita Inu' },
  { value: 'Alaskan Malamute', key: 'Alaskan Malamute' },
  { value: 'Alpine Mastiff', key: 'Alpine Mastiff' },
  { value: 'Alsatian', key: 'Alsatian' },
  { value: 'American Black and Tan Coonhound', key: 'American Black and Tan Coonhound' },
  { value: 'American Cocker Spaniel', key: 'American Cocker Spaniel' },
  { value: 'American Eskimo', key: 'American Eskimo' },
  { value: 'American Foxhound', key: 'American Foxhound' },
  { value: 'American Pit Bull Terrier', key: 'American Pit Bull Terrier' },
  { value: 'American Staffordshire Terrier', key: 'American Staffordshire Terrier' },
  { value: 'American Toy Terrier', key: 'American Toy Terrier' },
  { value: 'American Water Spaniel', key: 'American Water Spaniel' },
  { value: 'Amertoy', key: 'Amertoy' },
  { value: 'Anatolian Shepherd', key: 'Anatolian Shepherd' },
  { value: 'Arctic Husky', key: 'Arctic Husky' },
  { value: 'Australian Cattle', key: 'Australian Cattle' },
  { value: 'Australian Shepherd', key: 'Australian Shepherd' },
  { value: 'Australian Silky Terrier', key: 'Australian Silky Terrier' },
  { value: 'Australian Terrier', key: 'Australian Terrier' },
  { value: 'Balearic', key: 'Balearic' },
  { value: 'Baluchi Hound', key: 'Baluchi Hound' },
  { value: 'Barbone', key: 'Barbone' },
  { value: 'Bas-Rouge', key: 'Bas-Rouge' },
  { value: 'Basenji', key: 'Basenji' },
  { value: 'Basset Hound', key: 'Basset Hound' },
  { value: 'Beagle', key: 'Beagle' },
  { value: 'Bearded Collie', key: 'Bearded Collie' },
  { value: 'Beauceron', key: 'Beauceron' },
  { value: 'Bedlington Terrier', key: 'Bedlington Terrier' },
  { value: 'Belgian Cattle', key: 'Belgian Cattle' },
  { value: 'Belgian Griffon', key: 'Belgian Griffon' },
  { value: 'Belgian Shepherd', key: 'Belgian Shepherd' },
  { value: 'Berger de Beauce', key: 'Berger de Beauce' },
  { value: 'Berger de Brie', key: 'Berger de Brie' },
  { value: 'Berner Sennenhund', key: 'Berner Sennenhund' },
  { value: 'Bernese Cattle', key: 'Bernese Cattle' },
  { value: 'Bernese Mountain', key: 'Bernese Mountain' },
  { value: 'Bichon a Poil Frise', key: 'Bichon a Poil Frise' },
  { value: 'Bichon Frise', key: 'Bichon Frise' },
  { value: 'Bichon Havanais', key: 'Bichon Havanais' },
  { value: 'Bichon Maltiase', key: 'Bichon Maltiase' },
  { value: 'Bichon Tenerife', key: 'Bichon Tenerife' },
  { value: 'Bingley Terrier', key: 'Bingley Terrier' },
  { value: 'Black and Tan Coonhound', key: 'Black and Tan Coonhound' },
  { value: 'Black and Tan Terrier', key: 'Black and Tan Terrier' },
  { value: 'Black and Tan Toy Terrier', key: 'Black and Tan Toy Terrier' },
  { value: 'Black Russian Terrier', key: 'Black Russian Terrier' },
  { value: 'Bloodhound', key: 'Bloodhound' },
  { value: 'Blue Heeler', key: 'Blue Heeler' },
  { value: 'Bluetick Coonhound', key: 'Bluetick Coonhound' },
  { value: 'Boerboel', key: 'Boerboel' },
  { value: 'Bordeaux Bulldog', key: 'Bordeaux Bulldog' },
  { value: 'Bordeaux Mastiff', key: 'Bordeaux Mastiff' },
  { value: 'Border Collie', key: 'Border Collie' },
  { value: 'Border Terrier', key: 'Border Terrier' },
  { value: 'Borzoi', key: 'Borzoi' },
  { value: 'Boston Terrier', key: 'Boston Terrier' },
  { value: 'Bouledogue Francais', key: 'Bouledogue Francais' },
  { value: 'Bouvier des Flandres', key: 'Bouvier des Flandres' },
  { value: 'Boxer', key: 'Boxer' },
  { value: 'Boykin Spaniel', key: 'Boykin Spaniel' },
  { value: 'Briard', key: 'Briard' },
  { value: 'Brittany Spaniel', key: 'Brittany Spaniel' },
  { value: 'Brushwood', key: 'Brushwood' },
  { value: 'Brussels Griffon', key: 'Brussels Griffon' },
  { value: 'Bull Terrier', key: 'Bull Terrier' },
  { value: 'Bullmastiff', key: 'Bullmastiff' },
  { value: 'Ca Eivissenc', key: 'Ca Eivissenc' },
  { value: 'Cairn Terrier', key: 'Cairn Terrier' },
  { value: 'Canaan', key: 'Canaan' },
  { value: 'Cane Corso', key: 'Cane Corso' },
  { value: 'Caniche', key: 'Caniche' },
  { value: 'Cao de Agua', key: 'Cao de Agua' },
  { value: 'Cardigan Welsh Corgi', key: 'Cardigan Welsh Corgi' },
  { value: 'Carlin', key: 'Carlin' },
  { value: 'Cavalier King Charles Spaniel', key: 'Cavalier King Charles Spaniel' },
  { value: 'Chesapeake Bay Retriever', key: 'Chesapeake Bay Retriever' },
  { value: 'Chien de Berger Belge', key: 'Chien de Berger Belge' },
  { value: 'Chien de Montagne des Pyrenees', key: 'Chien de Montagne des Pyrenees' },
  { value: 'Chien des Pyrenees', key: 'Chien des Pyrenees' },
  { value: 'Chien St. Hubert', key: 'Chien St. Hubert' },
  { value: 'Chihuahua', key: 'Chihuahua' },
  { value: 'Chinese Crested', key: 'Chinese Crested' },
  { value: 'Chinese Shar-Pei', key: 'Chinese Shar-Pei' },
  { value: 'Chinook', key: 'Chinook' },
  { value: 'Chornyi', key: 'Chornyi' },
  { value: 'Chow Chow', key: 'Chow Chow' },
  { value: 'Chrysanthemum', key: 'Chrysanthemum' },
  { value: 'Cirneco dell’Etna', key: 'Cirneco dell’Etna' },
  { value: 'Clumber Spaniel', key: 'Clumber Spaniel' },
  { value: 'Coban Kopegi', key: 'Coban Kopegi' },
  { value: 'Collie', key: 'Collie' },
  { value: 'Congo', key: 'Congo' },
  { value: 'Congo Terrier', key: 'Congo Terrier' },
  { value: 'Continental Toy Spaniel', key: 'Continental Toy Spaniel' },
  { value: 'Coton de Tulear', key: 'Coton de Tulear' },
  { value: 'Curly-Coated Retriever', key: 'Curly-Coated Retriever' },
  { value: 'Dachshund', key: 'Dachshund' },
  { value: 'Dalmatian', key: 'Dalmatian' },
  { value: 'Dandie Dinmont Terrier', key: 'Dandie Dinmont Terrier' },
  { value: 'Deerhound', key: 'Deerhound' },
  { value: 'Deutsche Dogge', key: 'Deutsche Dogge' },
  { value: 'Deutscher Drahthaariger Vorstehund', key: 'Deutscher Drahthaariger Vorstehund' },
  { value: 'Deutscher Kurzhaariger Vorstehund', key: 'Deutscher Kurzhaariger Vorstehund' },
  { value: 'Deutscher Schaferhund', key: 'Deutscher Schaferhund' },
  { value: 'Dhokhi Apso', key: 'Dhokhi Apso' },
  { value: 'Do-khyi', key: 'Do-khyi' },
  { value: 'Doberman Pinscher', key: 'Doberman Pinscher' },
  { value: 'Dobermann', key: 'Dobermann' },
  { value: 'Dogue de Bordeaux', key: 'Dogue de Bordeaux' },
  { value: 'Drahthaar', key: 'Drahthaar' },
  { value: 'Drotszoru Magyar Vizsla', key: 'Drotszoru Magyar Vizsla' },
  { value: 'Elkhound', key: 'Elkhound' },
  { value: 'English Beagle', key: 'English Beagle' },
  { value: 'English Bull Terrier', key: 'English Bull Terrier' },
  { value: 'English Bulldog', key: 'English Bulldog' },
  { value: 'English Cocker Spaniel', key: 'English Cocker Spaniel' },
  { value: 'English Coonhound', key: 'English Coonhound' },
  { value: 'English Foxhound', key: 'English Foxhound' },
  { value: 'English Mastiff', key: 'English Mastiff' },
  { value: 'English Pointer', key: 'English Pointer' },
  { value: 'English Setter', key: 'English Setter' },
  { value: 'English Springer Spaniel', key: 'English Springer Spaniel' },
  { value: 'English Toy Spaniel', key: 'English Toy Spaniel' },
  { value: 'English Toy Terrier', key: 'English Toy Terrier' },
  { value: 'Entlebucher Cattle', key: 'Entlebucher Cattle' },
  { value: 'Entlebucher Mountain', key: 'Entlebucher Mountain' },
  { value: 'Entlebucher Sennenhund', key: 'Entlebucher Sennenhund' },
  { value: 'Epagneul Breton', key: 'Epagneul Breton' },
  { value: 'Epagneul Nain', key: 'Epagneul Nain' },
  { value: 'Field Spaniel', key: 'Field Spaniel' },
  { value: 'Finnish Lapphund', key: 'Finnish Lapphund' },
  { value: 'Finnish Spitz', key: 'Finnish Spitz' },
  { value: 'Finsk Spets', key: 'Finsk Spets' },
  { value: 'Flat-Coated Retriever', key: 'Flat-Coated Retriever' },
  { value: 'French Bulldog', key: 'French Bulldog' },
  { value: 'French Mastiff', key: 'French Mastiff' },
  { value: 'Friaar', key: 'Friaar' },
  { value: 'Friffon Bruxellois', key: 'Friffon Bruxellois' },
  { value: 'Gazelle Hound', key: 'Gazelle Hound' },
  { value: 'German Mastiff', key: 'German Mastiff' },
  { value: 'German Pinscher', key: 'German Pinscher' },
  { value: 'German Shepherd', key: 'German Shepherd' },
  { value: 'German Shorthaired Pointer', key: 'German Shorthaired Pointer' },
  { value: 'German Wirehaired Pointer', key: 'German Wirehaired Pointer' },
  { value: 'Giant Schnauzer', key: 'Giant Schnauzer' },
  { value: 'Glen of Imaal Terrier', key: 'Glen of Imaal Terrier' },
  { value: 'Golden Retriever', key: 'Golden Retriever' },
  { value: 'Gordon Setter', key: 'Gordon Setter' },
  { value: 'Great Dane', key: 'Great Dane' },
  { value: 'Great Pyrenees', key: 'Great Pyrenees' },
  { value: 'Great Swiss Cattle', key: 'Great Swiss Cattle' },
  { value: 'Greater Swiss Mountain', key: 'Greater Swiss Mountain' },
  { value: 'Greyhound', key: 'Greyhound' },
  { value: 'Griffon Beige', key: 'Griffon Beige' },
  { value: 'Griffon D’Arrêt a Poil Dur', key: 'Griffon D’Arrêt a Poil Dur' },
  { value: 'Groenendael', key: 'Groenendael' },
  { value: 'Grosser Schweizer Sennenhund', key: 'Grosser Schweizer Sennenhund' },
  { value: "Hall's Heeler", key: "Hall's Heeler" },
  { value: 'Harrier', key: 'Harrier' },
  { value: 'Havanese', key: 'Havanese' },
  { value: 'Hungarian Kuvasz', key: 'Hungarian Kuvasz' },
  { value: 'Hungarian Pointer', key: 'Hungarian Pointer' },
  { value: 'Hungarian Puli', key: 'Hungarian Puli' },
  { value: 'Hungarian Vizsla', key: 'Hungarian Vizsla' },
  { value: 'Hungarian Water', key: 'Hungarian Water' },
  { value: 'Ibizan Hound', key: 'Ibizan Hound' },
  { value: 'Iceland', key: 'Iceland' },
  { value: 'Iceland Spitz', key: 'Iceland Spitz' },
  { value: 'Icelandic Sheepdog', key: 'Icelandic Sheepdog' },
  { value: 'Irish Blue Terrier', key: 'Irish Blue Terrier' },
  { value: 'Irish Glen of Imaal Terrier', key: 'Irish Glen of Imaal Terrier' },
  { value: 'Irish Red Terrier', key: 'Irish Red Terrier' },
  { value: 'Irish Setter', key: 'Irish Setter' },
  { value: 'Irish Terrier', key: 'Irish Terrier' },
  { value: 'Irish Water Spaniel', key: 'Irish Water Spaniel' },
  { value: 'Irish Wolfhound', key: 'Irish Wolfhound' },
  { value: 'Íslenskur Fjárhundur', key: 'Íslenskur Fjárhundur' },
  { value: 'Italian Greyhound', key: 'Italian Greyhound' },
  { value: 'Italian Griffon', key: 'Italian Griffon' },
  { value: 'Italian Mastiff', key: 'Italian Mastiff' },
  { value: 'Jack Russell Terrier', key: 'Jack Russell Terrier' },
  { value: 'Japanese Akita', key: 'Japanese Akita' },
  { value: 'Japanese Chin', key: 'Japanese Chin' },
  { value: 'Japanese Small-size', key: 'Japanese Small-size' },
  { value: 'Japanese Spaniel', key: 'Japanese Spaniel' },
  { value: 'Kalef K’naani', key: 'Kalef K’naani' },
  { value: 'Kangal', key: 'Kangal' },
  { value: 'Karabash', key: 'Karabash' },
  { value: 'Keeshond', key: 'Keeshond' },
  { value: 'Kelb-tal Fenek', key: 'Kelb-tal Fenek' },
  { value: 'Kelev Cana’ani', key: 'Kelev Cana’ani' },
  { value: 'Kerry Blue Terrier', key: 'Kerry Blue Terrier' },
  { value: 'King Charles Spaniel', key: 'King Charles Spaniel' },
  { value: 'Komondor', key: 'Komondor' },
  { value: 'Korhals Griffon', key: 'Korhals Griffon' },
  { value: 'Kurzhaar', key: 'Kurzhaar' },
  { value: 'Kuvasz', key: 'Kuvasz' },
  { value: 'Labrador Retriever', key: 'Labrador Retriever' },
  { value: 'Lakeland Terrier', key: 'Lakeland Terrier' },
  { value: 'Leonberger', key: 'Leonberger' },
  { value: 'Lhasa Apso', key: 'Lhasa Apso' },
  { value: 'Lion', key: 'Lion' },
  { value: 'Little Griffon Vendeen Basset', key: 'Little Griffon Vendeen Basset' },
  { value: 'Little Lion', key: 'Little Lion' },
  { value: 'Little River Duck', key: 'Little River Duck' },
  { value: 'Lowchen', key: 'Lowchen' },
  { value: 'Magyar Vizsla', key: 'Magyar Vizsla' },
  { value: 'Malinois', key: 'Malinois' },
  { value: 'Maltese', key: 'Maltese' },
  { value: 'Manchester Terrier', key: 'Manchester Terrier' },
  { value: 'Mastiff', key: 'Mastiff' },
  { value: 'Mastino Napoletano', key: 'Mastino Napoletano' },
  { value: 'Mexican Hairless', key: 'Mexican Hairless' },
  { value: 'Miniature Bull Terrier', key: 'Miniature Bull Terrier' },
  { value: 'Miniature Dachshund', key: 'Miniature Dachshund' },
  { value: 'Miniature Pinscher', key: 'Miniature Pinscher' },
  { value: 'Miniature Poodle', key: 'Miniature Poodle' },
  { value: 'Miniature Schnauzer', key: 'Miniature Schnauzer' },
  { value: 'Mittelshnauzer', key: 'Mittelshnauzer' },
  { value: 'Mixed Breed', key: 'Mixed Breed' },
  { value: 'Mops', key: 'Mops' },
  { value: 'Mustaterrieri', key: 'Mustaterrieri' },
  { value: 'Mutt', key: 'Mutt' },
  { value: 'Neapolitan Mastiff', key: 'Neapolitan Mastiff' },
  { value: 'Newfoundland', key: 'Newfoundland' },
  { value: 'Norfolk Terrier', key: 'Norfolk Terrier' },
  { value: 'Norsk Buhund', key: 'Norsk Buhund' },
  { value: 'Norsk Elghund', key: 'Norsk Elghund' },
  { value: 'Norwegian Buhund', key: 'Norwegian Buhund' },
  { value: 'Norwegian Elkhound', key: 'Norwegian Elkhound' },
  { value: 'Norwegian Lundehund', key: 'Norwegian Lundehund' },
  { value: 'Norwich Terrier', key: 'Norwich Terrier' },
  { value: 'Nova Scotia Duck Tolling Retriever', key: 'Nova Scotia Duck Tolling Retriever' },
  { value: 'Old English Sheepdog', key: 'Old English Sheepdog' },
  { value: 'Otterhound', key: 'Otterhound' },
  { value: 'Papillon', key: 'Papillon' },
  { value: 'Parson Jack Russell Terrier', key: 'Parson Jack Russell Terrier' },
  { value: 'Parson Russell Terrier', key: 'Parson Russell Terrier' },
  { value: 'Peking Palasthund', key: 'Peking Palasthund' },
  { value: 'Pekingese', key: 'Pekingese' },
  { value: 'Pembroke Welsh Corgi', key: 'Pembroke Welsh Corgi' },
  { value: 'Perro de Agua Espanol', key: 'Perro de Agua Espanol' },
  { value: 'Persian Greyhound', key: 'Persian Greyhound' },
  { value: 'Petit Basset Griffon Vendeen', key: 'Petit Basset Griffon Vendeen' },
  { value: 'Phalene', key: 'Phalene' },
  { value: 'Pharaoh Hound', key: 'Pharaoh Hound' },
  { value: 'Piccolo Levrieve Italiani', key: 'Piccolo Levrieve Italiani' },
  { value: 'Plott Hound', key: 'Plott Hound' },
  { value: 'Podenco Ibicenco', key: 'Podenco Ibicenco' },
  { value: 'Pointing', key: 'Pointing' },
  { value: 'Polish Lowland Sheepdog', key: 'Polish Lowland Sheepdog' },
  { value: 'Polski Owczarek Nizinny', key: 'Polski Owczarek Nizinny' },
  { value: 'Poltalloch Terrier', key: 'Poltalloch Terrier' },
  { value: 'Pomeranian', key: 'Pomeranian' },
  { value: 'PON', key: 'PON' },
  { value: 'Poodle', key: 'Poodle' },
  { value: 'Portuguese Podengo', key: 'Portuguese Podengo' },
  { value: 'Portuguese Warren Hound', key: 'Portuguese Warren Hound' },
  { value: 'Portuguese Water', key: 'Portuguese Water' },
  { value: 'Puffin', key: 'Puffin' },
  { value: 'Pug', key: 'Pug' },
  { value: 'Puli', key: 'Puli' },
  { value: 'Pyrenean Mountain', key: 'Pyrenean Mountain' },
  { value: 'Pyrenean Shepherd', key: 'Pyrenean Shepherd' },
  { value: 'Queensland Heeler', key: 'Queensland Heeler' },
  { value: 'Rat Terrier', key: 'Rat Terrier' },
  { value: 'Red Heeler', key: 'Red Heeler' },
  { value: 'Red Setter', key: 'Red Setter' },
  { value: 'Redbone Coonhound', key: 'Redbone Coonhound' },
  { value: 'Redbone Hound', key: 'Redbone Hound' },
  { value: 'Redtick Coonhound', key: 'Redtick Coonhound' },
  { value: 'Reh Pinscher', key: 'Reh Pinscher' },
  { value: 'Rhodesian Ridgeback', key: 'Rhodesian Ridgeback' },
  { value: 'Riesenshnauzer', key: 'Riesenshnauzer' },
  { value: 'Rothbury Terrier', key: 'Rothbury Terrier' },
  { value: 'Rottweiler', key: 'Rottweiler' },
  { value: 'Russian Black Terrier', key: 'Russian Black Terrier' },
  { value: 'Russian Wolfhound', key: 'Russian Wolfhound' },
  { value: 'Saint Bernard', key: 'Saint Bernard' },
  { value: 'Saluki', key: 'Saluki' },
  { value: 'Samoyed', key: 'Samoyed' },
  { value: 'Samoyedskaya', key: 'Samoyedskaya' },
  { value: 'Schipperke', key: 'Schipperke' },
  { value: 'Schnauzer', key: 'Schnauzer' },
  { value: 'Schwarzer Russischer Terrier', key: 'Schwarzer Russischer Terrier' },
  { value: 'Scottie', key: 'Scottie' },
  { value: 'Scottish Deerhound', key: 'Scottish Deerhound' },
  { value: 'Scottish Terrier', key: 'Scottish Terrier' },
  { value: 'Sealyham Terrier', key: 'Sealyham Terrier' },
  { value: 'Shar-Pei', key: 'Shar-Pei' },
  { value: 'Sheepdog', key: 'Sheepdog' },
  { value: 'Sheltie', key: 'Sheltie' },
  { value: 'Shetland Sheepdog', key: 'Shetland Sheepdog' },
  { value: 'Shiba Inu', key: 'Shiba Inu' },
  { value: 'Shih Tzu', key: 'Shih Tzu' },
  { value: 'Siberian Husky', key: 'Siberian Husky' },
  { value: 'Sicilian Hound', key: 'Sicilian Hound' },
  { value: 'Silky Terrier', key: 'Silky Terrier' },
  { value: 'Skye Terrier', key: 'Skye Terrier' },
  { value: 'Smooth Fox Terrier', key: 'Smooth Fox Terrier' },
  { value: 'Spanish Water', key: 'Spanish Water' },
  { value: 'Spinone Italiano', key: 'Spinone Italiano' },
  { value: 'St. Bernard', key: 'St. Bernard' },
  { value: 'St. Bernhardshund', key: 'St. Bernhardshund' },
  { value: "St. Hubert's Hound", key: "St. Hubert's Hound" },
  { value: 'Staffordshire Bull Terrier', key: 'Staffordshire Bull Terrier' },
  { value: 'Suomenlapinkoira', key: 'Suomenlapinkoira' },
  { value: 'Suomenpystykorva', key: 'Suomenpystykorva' },
  { value: 'Sussex Spaniel', key: 'Sussex Spaniel' },
  { value: 'Svart Terrier', key: 'Svart Terrier' },
  { value: 'Swedish Vallhund', key: 'Swedish Vallhund' },
  { value: 'Sydney Silky', key: 'Sydney Silky' },
  { value: 'Tchiorny Terrier', key: 'Tchiorny Terrier' },
  { value: 'Teckel', key: 'Teckel' },
  { value: 'Tepezcuintli', key: 'Tepezcuintli' },
  { value: 'Terrier Noir Russem', key: 'Terrier Noir Russem' },
  { value: 'Tervuren', key: 'Tervuren' },
  { value: 'Tibetan Mastiff', key: 'Tibetan Mastiff' },
  { value: 'Tibetan Spaniel', key: 'Tibetan Spaniel' },
  { value: 'Tibetan Terrier', key: 'Tibetan Terrier' },
  { value: 'Toy Fox Terrier', key: 'Toy Fox Terrier' },
  { value: 'Toy Manchester Terrier', key: 'Toy Manchester Terrier' },
  { value: 'Treeing Walker Coonhound', key: 'Treeing Walker Coonhound' },
  { value: 'Turco Andaluz', key: 'Turco Andaluz' },
  { value: 'Västgötaspets', key: 'Västgötaspets' },
  { value: 'Vizsla', key: 'Vizsla' },
  { value: 'Waterside Terrier', key: 'Waterside Terrier' },
  { value: 'Weimaraner', key: 'Weimaraner' },
  { value: 'Weimaraner Vorstehund', key: 'Weimaraner Vorstehund' },
  { value: 'Welsh Springer Spaniel', key: 'Welsh Springer Spaniel' },
  { value: 'Welsh Terrier', key: 'Welsh Terrier' },
  { value: 'West Highland White Terrier', key: 'West Highland White Terrier' },
  { value: 'Westie', key: 'Westie' },
  { value: 'Wheaten Terrier', key: 'Wheaten Terrier' },
  { value: 'Whippet', key: 'Whippet' },
  { value: 'Wire Fox Terrier', key: 'Wire Fox Terrier' },
  { value: 'Wirehaired Griffon', key: 'Wirehaired Griffon' },
  { value: 'Wirehaired Pointing Griffon', key: 'Wirehaired Pointing Griffon' },
  { value: 'Wolf Spitz', key: 'Wolf Spitz' },
  { value: 'Xoloitzcuintli', key: 'Xoloitzcuintli' },
  { value: 'Yellow Retriever', key: 'Yellow Retriever' },
  { value: 'Yorkshire Terrier', key: 'Yorkshire Terrier' },
  { value: 'Zwergpinscher', key: 'Zwergpinscher' },
  { value: 'Zwergschnauzer', key: 'Zwergschnauzer' },
  { value: 'Zwergteckel', key: 'Zwergteckel' },
];

export const CAT_BREEDS = [
  { value: 'Abyssinian', key: 'Abyssinian' },
  { value: 'American Bobtail', key: 'American Bobtail' },
  { value: 'American Curl', key: 'American Curl' },
  { value: 'American Shorthair', key: 'American Shorthair' },
  { value: 'American Wirehair', key: 'American Wirehair' },
  { value: 'Balinese', key: 'Balinese' },
  { value: 'Bengal', key: 'Bengal' },
  { value: 'Birman', key: 'Birman' },
  { value: 'Bombay', key: 'Bombay' },
  { value: 'British Shorthair', key: 'British Shorthair' },
  { value: 'Burmese', key: 'Burmese' },
  { value: 'Burmilla', key: 'Burmilla' },
  { value: 'Chartreux', key: 'Chartreux' },
  { value: 'Cornish Rex', key: 'Cornish Rex' },
  { value: 'Cymric', key: 'Cymric' },
  { value: 'Devon Rex', key: 'Devon Rex' },
  { value: 'DLH', key: 'DLH' },
  { value: 'DMH', key: 'DMH' },
  { value: 'DSH', key: 'DSH' },
  { value: 'Egyptian Mau', key: 'Egyptian Mau' },
  { value: 'Exotic Shorthair', key: 'Exotic Shorthair' },
  { value: 'Hairless', key: 'Hairless' },
  { value: 'Havana', key: 'Havana' },
  { value: 'Himalayan', key: 'Himalayan' },
  { value: 'Japanese Bobtail', key: 'Japanese Bobtail' },
  { value: 'Javanese', key: 'Javanese' },
  { value: 'Korat', key: 'Korat' },
  { value: 'LaPerm', key: 'LaPerm' },
  { value: 'Maine Coon', key: 'Maine Coon' },
  { value: 'Manx', key: 'Manx' },
  { value: 'Mixed Breed', key: 'Mixed Breed' },
  { value: 'Munchkin', key: 'Munchkin' },
  { value: 'Nebelung', key: 'Nebelung' },
  { value: 'Norwegian Forest', key: 'Norwegian Forest' },
  { value: 'Ocicat', key: 'Ocicat' },
  { value: 'Oriental Short Hair', key: 'Oriental Short Hair' },
  { value: 'Persian', key: 'Persian' },
  { value: 'Pixiebob', key: 'Pixiebob' },
  { value: 'Ragamuffin', key: 'Ragamuffin' },
  { value: 'Ragdoll', key: 'Ragdoll' },
  { value: 'Russian Blue', key: 'Russian Blue' },
  { value: 'Scottish Fold', key: 'Scottish Fold' },
  { value: 'Selkirk Rex', key: 'Selkirk Rex' },
  { value: 'Siamese', key: 'Siamese' },
  { value: 'Siberian', key: 'Siberian' },
  { value: 'Singapura', key: 'Singapura' },
  { value: 'Snowshoe', key: 'Snowshoe' },
  { value: 'Somali', key: 'Somali' },
  { value: 'Sphynx', key: 'Sphynx' },
  { value: 'Tonkinese', key: 'Tonkinese' },
  { value: 'Turkish Angora', key: 'Turkish Angora' },
  { value: 'Turkish Van', key: 'Turkish Van' },
  { value: 'York Chocolate', key: 'York Chocolate' },
];

export const OTHER_SPECIES = [
  { value: 'Alpaca', key: 'Alpaca' },
  { value: 'Amphibian', key: 'Amphibian' },
  { value: 'Canary', key: 'Canary' },
  { value: 'Chicken', key: 'Chicken' },
  { value: 'Chincilla', key: 'Chincilla' },
  { value: 'Cow', key: 'Cow' },
  { value: 'Degu', key: 'Degu' },
  { value: 'Donkey', key: 'Donkey' },
  { value: 'Dove', key: 'Dove' },
  { value: 'Duck', key: 'Duck' },
  { value: 'Emu', key: 'Emu' },
  { value: 'Ferret', key: 'Ferret' },
  { value: 'Finch', key: 'Finch' },
  { value: 'Fish', key: 'Fish' },
  { value: 'Gerbil', key: 'Gerbil' },
  { value: 'Goat', key: 'Goat' },
  { value: 'Goldfish', key: 'Goldfish' },
  { value: 'Goose', key: 'Goose' },
  { value: 'Guinea Pig', key: 'Guinea Pig' },
  { value: 'Guppy', key: 'Guppy' },
  { value: 'Hamster', key: 'Hamster' },
  { value: 'Hedgehog', key: 'Hedgehog' },
  { value: 'Horse', key: 'Horse' },
  { value: 'Koi', key: 'Koi' },
  { value: 'Llama', key: 'Llama' },
  { value: 'Miniature Horse', key: 'Miniature Horse' },
  { value: 'Mink', key: 'Mink' },
  { value: 'Mouse', key: 'Mouse' },
  { value: 'Mule', key: 'Mule' },
  { value: 'Newt', key: 'Newt' },
  { value: 'Ostrich', key: 'Ostrich' },
  { value: 'Parakeet', key: 'Parakeet' },
  { value: 'Parrot', key: 'Parrot' },
  { value: 'Peacock', key: 'Peacock' },
  { value: 'Peafowl', key: 'Peafowl' },
  { value: 'Pheasant', key: 'Pheasant' },
  { value: 'Pig', key: 'Pig' },
  { value: 'Pigeon', key: 'Pigeon' },
  { value: 'Pony', key: 'Pony' },
  { value: 'Pot Bellied', key: 'Pot Bellied' },
  { value: 'Prairie', key: 'Prairie' },
  { value: 'Quail', key: 'Quail' },
  { value: 'Rabbit', key: 'Rabbit' },
  { value: 'Rat', key: 'Rat' },
  { value: 'Reptile', key: 'Reptile' },
  { value: 'Rhea', key: 'Rhea' },
  { value: 'Salamander', key: 'Salamander' },
  { value: 'Sheep', key: 'Sheep' },
  { value: 'Silkmoth', key: 'Silkmoth' },
  { value: 'Skunk', key: 'Skunk' },
  { value: 'Snake', key: 'Snake' },
  { value: 'Sugar Glider', key: 'Sugar Glider' },
  { value: 'Swan', key: 'Swan' },
  { value: 'Toad', key: 'Toad' },
  { value: 'Tortoise', key: 'Tortoise' },
  { value: 'Toucan', key: 'Toucan' },
  { value: 'Turkey', key: 'Turkey' },
  { value: 'Turtle', key: 'Turtle' },
];
