import config from 'config/config';
import GoogleMapReact from 'google-map-react';
import { isNil } from 'lodash-es';
import PropTypes from 'prop-types';
import Card from './Card/Card';
import ComparableMarker from './Markers/ComparableMarker';
import CurrentHomeMarker from './Markers/CurrentHomeMarker';
import RentedMarker from './Markers/RentedMarker';

const getMarkerBasedOffType = ({ home, ...rest }) => {
  if (home.current)
    return (
      <CurrentHomeMarker
        lat={parseFloat(home.address.coordinates.latitude)}
        lng={parseFloat(home.address.coordinates.longitude)}
        home={home}
        {...rest}
      />
    );
  if (!home.recentlyOffMarket)
    return (
      <ComparableMarker
        lat={parseFloat(home.address.coordinates.latitude)}
        lng={parseFloat(home.address.coordinates.longitude)}
        home={home}
        {...rest}
      />
    );
  return (
    <RentedMarker
      {...rest}
      lat={parseFloat(home.address.coordinates.latitude)}
      lng={parseFloat(home.address.coordinates.longitude)}
      home={home}
    />
  );
};

getMarkerBasedOffType.propTypes = {
  home: PropTypes.object.isRequired,
};

const CurrentSupplyMap = ({
  gesture,
  zoomControlPosition,
  setActive,
  homes,
  hover,
  active,
  handleApiLoaded,
  ...rest
}) => {
  const getMarkers = () =>
    homes.map((home, index) => {
      return getMarkerBasedOffType({
        home,
        isActive: active === index,
        isHover: hover === index,
      });
    });

  return (
    <>
      <GoogleMapReact
        {...rest}
        onGoogleApiLoaded={({ map }) => {
          handleApiLoaded(map);
        }}
        bootstrapURLKeys={{
          key: `https://maps.googleapis.com/maps/api/js?key=${config.googleMapsApiKey}&libraries=placesgeometry,drawing&language=en`,
        }}
        options={(e) => {
          return {
            gestureHandling: gesture,
            clickableIcons: false,
            fullscreenControl: false,
            zoomControlOptions: {
              position: e.ControlPosition[zoomControlPosition],
            },
            scrollwheel: false,
          };
        }}
        center={{
          lat: parseFloat(homes[0].address.coordinates.latitude),
          lng: parseFloat(homes[0].address.coordinates.longitude),
        }}
        zoom={8}
      >
        {getMarkers()}
      </GoogleMapReact>

      {!isNil(active) && <Card home={homes[active]} onHide={() => setActive(null)} />}
    </>
  );
};

CurrentSupplyMap.propTypes = {
  setActive: PropTypes.func.isRequired,
  homes: PropTypes.arrayOf(PropTypes.object),
  hover: PropTypes.number,
  active: PropTypes.number,
  handleApiLoaded: PropTypes.func.isRequired,
  gesture: PropTypes.string,
  zoomControlPosition: PropTypes.string,
};

CurrentSupplyMap.defaultProps = {
  active: null,
  hover: null,
  homes: [],
  gesture: 'greedy',
  zoomControlPosition: 'RIGHT_TOP',
};

export default CurrentSupplyMap;
