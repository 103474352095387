import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { COOKIES_CONFIG } from '@belong/common';
import FullPageSpinner from 'components/FullPageSpinner/FullPageSpinner';
import { MobileMenuContext } from 'components/HeaderMain/HeaderMain';
import RouterHeaderNavLink from 'components/HeaderMain/RouterHeaderNavLink/RouterHeaderNavLink';
import MetaNoIndex from 'components/Metatags/MetaNoIndex';
import { getDefaultHomeownerGrowth } from 'consts/employee-assignments';
import { ContactUs } from 'containers/ContactUs/ContactUs';
import { useModal } from 'hooks/useModal';
import SingleColumnFlowLayout from 'layouts/SingleColumnFlowLayout/SingleColumnFlowLayout';
import { EmployeeAssignmentTypes } from 'models/enums';
import { parseCookies } from 'nookies';
import Page404 from 'pages/Page404/Page404';
import PropTypes from 'prop-types';
import { BASE_PATHS } from 'routes/paths';
import { fetchAccountProperties } from 'store/redux/homeowner-accounts/actions';
import { _selectAccountProperties } from 'store/redux/homeowner-accounts/selectors';
import { selectUser } from 'store/redux/user/selectors';
import { EMPLOYEE_TITLES } from 'strings/common.strings';
import { NoLongerNeedServices } from './NoLongerNeedServices/NoLongerNeedServices';
import { OccupancyStandaloneLandingPage } from './OccupancyStandaloneLandingPage/OccupancyStandaloneLandingPage';
import { OccupancyStandaloneSuccess } from './OccupancyStandaloneSuccess/OccupancyStandaloneSuccess';

const HeaderLinks = ({ saveAndExitPath }) => {
  const { hideMobileMenu } = React.useContext(MobileMenuContext);
  return [
    <ContactUs key="ContactUs" />,
    <RouterHeaderNavLink key="save" label="SAVE & EXIT" to={saveAndExitPath} onClick={hideMobileMenu} />,
  ];
};

export const OccupancyStandalone = ({ history, location, match }) => {
  const { homeId } = match.params;
  const dispatch = useDispatch();
  const accountProperties = useSelector(_selectAccountProperties);
  const user = useSelector(selectUser);
  const cookies = parseCookies();

  const comeFromTheMobileApp = Boolean(cookies[COOKIES_CONFIG.MOBILE_APP.name] === 'true');
  const currentProperty = accountProperties?.find((_property) =>
    _property.units.some((_unit) => _unit.basicInfo.unitId === homeId)
  );

  const currentUnit = currentProperty?.units?.find((_unit) => _unit.basicInfo.unitId === homeId);

  const saveAndExitPath = currentProperty?.basicInfo?.propertyId
    ? `${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${currentProperty.basicInfo.propertyId}`
    : BASE_PATHS.HOMEOWNER_SETUP_FLOW;

  const [loading, startLoader, stopLoader] = useModal(true);

  useEffect(() => {
    const fetchData = async () => {
      startLoader();

      try {
        await dispatch(fetchAccountProperties());
      } catch (e) {
        console.error(e);
      }

      stopLoader();
    };
    fetchData();
  }, [location.pathname]);

  if (loading) {
    return (
      <SingleColumnFlowLayout
        headerMainProps={{
          navigationComponents: <HeaderLinks saveAndExitPath={saveAndExitPath} />,
        }}
        header={!comeFromTheMobileApp}
        footer={!comeFromTheMobileApp}
      >
        <MetaNoIndex />
        <FullPageSpinner />
      </SingleColumnFlowLayout>
    );
  }

  if (!currentProperty) {
    return <Redirect to={BASE_PATHS.ACCOUNTS} />;
  }

  let employeeAssignment = currentUnit?.employeeAssignments?.find(
    (ea) => ea.employeeAssignment.assignmentType === EmployeeAssignmentTypes.HomeownerGrowth
  );

  const defaultHomeownerGrowth = getDefaultHomeownerGrowth(currentProperty?.basicInfo?.coverageRegionId);
  if (!employeeAssignment) {
    employeeAssignment = defaultHomeownerGrowth;
  }

  const formattedEmployee = {
    ...employeeAssignment.employee,
    ...employeeAssignment.user,
    profileImage: employeeAssignment.user.profileImageUrl,
    bannerImage: employeeAssignment.employee.bannerImageUrl,
    jobTitle: EMPLOYEE_TITLES[employeeAssignment.employeeAssignment.assignmentType],
  };

  return (
    <SingleColumnFlowLayout
      headerMainProps={{
        navigationComponents: <HeaderLinks />,
      }}
      header={!comeFromTheMobileApp}
      footer={!comeFromTheMobileApp}
    >
      <MetaNoIndex />
      <Switch>
        <Route strict exact path={`${match.path}`}>
          <OccupancyStandaloneLandingPage
            history={history}
            location={location}
            match={match}
            employee={formattedEmployee}
            unit={currentUnit}
            user={user}
            startLoader={startLoader}
            stopLoader={stopLoader}
          />
        </Route>
        <Route strict exact path={`${match.path}/no-longer-need-services`}>
          <NoLongerNeedServices
            history={history}
            match={match}
            employee={formattedEmployee}
            unitId={currentUnit.basicInfo.unitId}
            user={user}
          />
        </Route>
        <Route strict exact path={`${match.path}/success`}>
          <OccupancyStandaloneSuccess
            location={location}
            employee={formattedEmployee}
            unit={currentUnit}
            property={currentProperty}
          />
        </Route>

        <Route path="*">
          <Page404 />
        </Route>
      </Switch>
    </SingleColumnFlowLayout>
  );
};

OccupancyStandalone.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};
