import { useContext, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Measure from 'react-measure';
import { Route } from 'react-router';
import WindowSizeListener from 'react-window-size-listener';
import type { WindowSize } from 'react-window-size-listener';
import { COOKIES_CONFIG, PATHS } from '@belong/common';
import { Fallback } from 'accounts/components/fallback';
import classNames from 'classnames/bind';
import { MobileMenuContext } from 'components/HeaderMain/HeaderMain';
import RouterHeaderNavLink from 'components/HeaderMain/RouterHeaderNavLink/RouterHeaderNavLink';
import { MobileTipButton } from 'components/MobileTipButton/MobileTipButton';
import SidebarWithTextBubble from 'components/SidebarWithTextBubble/SidebarWithTextBubble';
import Spinner from 'components/Spinner/Spinner';
import { TipBox } from 'components/TipBox/TipBox';
import { ContactUs } from 'containers/ContactUs/ContactUs';
import { Grid } from 'forkedlibraries/react-bootstrap';
import { useModal } from 'hooks/useModal';
import { MobileTipModal } from 'layouts/FlowLayout/MobileTipModal/MobileTipModal';
import Page from 'layouts/Page/Page';
import RenterAccountLease from 'models/renterAccounts/RenterAccountLease';
import { parseCookies } from 'nookies';
import styles from './steps-layout.module.css';

const cx = classNames.bind(styles);

const sidebarWidth = 350;

type Props = {
  currentStep: any;
  employeeAssignment: any;
  home: any;
  isFlowLoading: boolean;
  lease?: RenterAccountLease;
  onSubmit: () => void;
  setLoading: (isLoading: boolean) => void;
  setSidebarTip: any;
  showEmployeeTip?: boolean;
  sidebarProps?: any;
  steps: any;
  tip: any;
};

export const StepsLayout = ({
  currentStep,
  employeeAssignment,
  home,
  isFlowLoading,
  lease,
  onSubmit,
  setLoading,
  setSidebarTip,
  showEmployeeTip = false,
  sidebarProps = {},
  steps,
  tip,
}: Props) => {
  const { hideMobileMenu } = useContext<any>(MobileMenuContext);
  const cookies = parseCookies();

  const comeFromTheMobileApp = Boolean(cookies[COOKIES_CONFIG.MOBILE_APP.name] === 'true');

  const [measure, setMeasure] = useState({
    containerWidth: 0,
    windowWidth: 0,
  });

  const [isModalOpen, setModalOpen, setModalClose] = useModal();

  const handleWindowResize = (windowSize: WindowSize) =>
    setMeasure((prevMeasure) => ({
      ...prevMeasure,
      windowWidth: windowSize.windowWidth,
    }));

  const handleContainerReize = ({ bounds }) =>
    setMeasure((prevMeasure) => ({
      ...prevMeasure,
      containerWidth: bounds.width,
    }));

  const saveAndExitPath =
    lease?.leaseInfo?.basicInfo?.leaseId && lease?.unitInfo?.unitId
      ? `${PATHS.RESIDENTS_ACCOUNT_PROPERTIES}/${lease?.unitInfo?.unitId}/${lease.leaseInfo.basicInfo.leaseId}`
      : PATHS.RESIDENTS_ACCOUNT_PROPERTIES;

  return (
    <Page
      footer={false}
      header={!comeFromTheMobileApp}
      headerMainProps={{
        additionalNavigationComponents: () => <MobileTipButton onClick={setModalOpen} />,
        disableLogo: false,
        fixed: true,
        navigationComponents: [
          <ContactUs key="ContactUs" />,
          <RouterHeaderNavLink key="save" label="SAVE & EXIT" onClick={hideMobileMenu} to={saveAndExitPath} />,
        ],
        transparent: false,
      }}
    >
      {steps.map((stepConfig) => {
        const Banner = stepConfig.banner;

        if (!Banner) {
          return null;
        }

        return (
          <Route
            key={stepConfig.path}
            path={stepConfig.path}
            exact
            render={() => (
              <div className={cx('banner-container')}>
                <Banner home={home} openTipModal={setModalOpen} />
              </div>
            )}
          />
        );
      })}
      <Grid>
        <ErrorBoundary FallbackComponent={Fallback}>
          <WindowSizeListener onResize={handleWindowResize} />
          <Measure bounds onResize={handleContainerReize}>
            {({ measureRef }) => {
              const { containerWidth, windowWidth } = measure;

              return (
                <div ref={measureRef} className={cx('layout')}>
                  <div className={cx('form')}>
                    {isFlowLoading && (
                      <div className={cx('spinner')} style={{ width: containerWidth - sidebarWidth }}>
                        <Spinner />
                      </div>
                    )}
                    {steps?.map((stepConfig) => {
                      const StepComponent = stepConfig.component || stepConfig.form;

                      return (
                        <Route
                          key={stepConfig.path}
                          path={stepConfig.path}
                          render={() => (
                            <StepComponent
                              currentStep={currentStep}
                              employeeAssignment={employeeAssignment}
                              home={home}
                              lease={lease}
                              onSubmit={onSubmit}
                              setLoading={setLoading}
                              setSidebarTip={setSidebarTip}
                              steps={steps}
                              tip={tip}
                            />
                          )}
                        />
                      );
                    })}
                  </div>
                  <div className={cx('sidebar')} style={{ right: (windowWidth - containerWidth) / 2 }}>
                    <div className={cx('overlay')} />
                    <div className={cx('content-sidebar-with-text')}>
                      {/* todo: abstrack tip management */}
                      {showEmployeeTip ? (
                        <SidebarWithTextBubble
                          title={tip?.title}
                          description={tip?.description}
                          employeeAssignment={employeeAssignment}
                          makeGreen={false}
                          showContact
                          {...sidebarProps}
                        />
                      ) : (
                        <TipBox title={tip?.title} description={tip?.description} />
                      )}
                    </div>
                  </div>
                </div>
              );
            }}
          </Measure>
        </ErrorBoundary>
      </Grid>
      <MobileTipModal show={isModalOpen} onHide={setModalClose}>
        {showEmployeeTip ? (
          <SidebarWithTextBubble
            title={tip.title}
            description={tip.description}
            employeeAssignment={employeeAssignment}
            makeGreen={false}
            showContact
            {...sidebarProps}
          />
        ) : (
          <TipBox title={tip?.title} description={tip?.description} />
        )}
      </MobileTipModal>
    </Page>
  );
};
