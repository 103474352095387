import { Image } from '@belong/ui';
import LinkBase from 'corecomponents/LinkBase/LinkBase';
import { THEME_CONSTS } from 'themes/themes';

type Props = {
  disableLogo?: boolean;
  theme?: typeof THEME_CONSTS[keyof typeof THEME_CONSTS];
};

const Logo = ({ disableLogo: disblableLink = false, theme = 'default' }: Props) => {
  const logoSrc = theme === 'default' ? '/logos/belong_logo_web.svg' : '/logos/belong_logo_web_white.svg';

  return (
    <div style={{ height: 27, width: 77 }}>
      {disblableLink ? (
        <Image src={logoSrc} alt="Belong logo" aria-hidden="true" className="block" />
      ) : (
        <LinkBase to="/">
          <Image src={logoSrc} alt="Belong logo" aria-hidden="true" className="block" />
        </LinkBase>
      )}
    </div>
  );
};

export default Logo;
