import { useContext } from 'react';
import { IconAlert } from '@belong/icons';
import { Text, Tooltip } from '@belong/ui';
import classNames from 'classnames/bind';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import { find } from 'lodash-es';
import { ConsentStatus, MaintenanceAssessment, MaintenanceResponsibility } from 'models/enums';
import PropTypes from 'prop-types';
import Checkbox from '../../../../../../../components/Checkbox/Checkbox';
import Spinner from '../../../../../../../components/Spinner/Spinner';
import ImprovementCard from '../../../../../ImprovementCard/ImprovementCard';
import { isItemResidentResponsible } from '../../../utils';
import { ApprovalContext } from '../../ApprovalContext';
import { ImprovementResponsibilitySelector } from '../improvement-responsibility-selector/improvement-responsibility-selector';
import styles from './BundleItem.module.css';

const cx = classNames.bind(styles);

export const BundleItem = ({
  isPreventative,
  item,
  onChange,
  onClick,
  price = null,
  inspectionType,
  isGTTLDisqualifiedDueHomeownerFixingItems,
  isDoNotExceedGroup,
}) => {
  const { isOnboarding, isMoveOut, isAdoptedLease } = inspectionType || {};
  const { loader } = useContext(ApprovalContext);
  const { maintenancePayments, maintenance } = item;
  const homeownerPayment = maintenancePayments.find(
    (payment) => payment.paidBy === MaintenanceResponsibility.Homeowner
  );

  const selected =
    (homeownerPayment?.percentage > 0 && homeownerPayment?.consentStatus === ConsentStatus.Approved) ||
    isItemResidentResponsible(item);

  const isLoading = !!find(
    loader.items,
    (loadingItem) => loadingItem.maintenance.uniqueId === item.maintenance.uniqueId
  );

  const isDisabled = isItemResidentResponsible(item) || item.maintenance.assessment === MaintenanceAssessment.Required;

  const handleLetsDoThisClick = () => onChange(!selected, [item]);

  const handleLetsDoThisSelect = (isImprovementSelected) => onChange(isImprovementSelected, [item]);

  const handleRespoinsibilityChange = (improvement) => {
    onChange(selected, [improvement]);
  };

  const isImprovementDoneByHomeowner =
    maintenance.proServiceResponsibility === MaintenanceResponsibility.Homeowner &&
    isGTTLDisqualifiedDueHomeownerFixingItems;

  return (
    <div className={cx('bundle-item', { selected })}>
      {selected && <div className={cx('outline')} />}
      <div className={cx('card-container')}>
        {isLoading && (
          <div className={cx('card-spinner')}>
            <Spinner dark />
          </div>
        )}
        <ImprovementCard
          borderRadius={false}
          height={220}
          improvement={item}
          isPreventative={isPreventative}
          mediaContainerClassName={cx('card-image-container')}
          onClick={onClick}
          price={typeof price === 'function' ? price(selected) : price}
          isDoNotExceedGroup={isDoNotExceedGroup}
          inspectionType={inspectionType}
          isHomeownerView
        />
        {isOnboarding && !isAdoptedLease && !item.maintenance.shouldPreventHomeownerFixing && (
          <div className="pt-md pl-md">
            <ImprovementResponsibilitySelector improvement={item} onChange={handleRespoinsibilityChange} />
          </div>
        )}
        <div
          className={cx('bottom', {
            'justify-end pl-sm pr-2xs -mb-xs': isOnboarding || isMoveOut,
            'justify-between': isImprovementDoneByHomeowner,
          })}
        >
          {!isOnboarding && !isMoveOut && (
            <ButtonBase className={cx('select-button-wrapper')} disabled={isDisabled} onClick={handleLetsDoThisClick}>
              <Checkbox
                alignCenter
                disabled={isDisabled}
                label="LET'S DO THIS"
                onSelect={handleLetsDoThisSelect}
                useOnChangeFormToHandle
                value={selected}
              />
            </ButtonBase>
          )}

          {isImprovementDoneByHomeowner && (
            <Tooltip
              content={
                <div className="bg-red text-white p-xs rounded mx-xs z-toast" style={{ maxWidth: 320 }}>
                  {`We cannot guarantee a list date when you chose to do your own improvement\u00a0work.`}
                </div>
              }
              contentProps={{ className: 'z-50' }}
              arrowProps={{
                className: 'fill-red fill-bg-red',
              }}
            >
              <button onClick={(e) => e.stopPropagation()} className="ml-2xs cursor-pointer" type="button">
                <IconAlert width={24} height={24} className="text-red" />
              </button>
            </Tooltip>
          )}

          <ButtonBase className={cx('show-details-button', 'place-self-end')} onClick={onClick}>
            <Text fontWeight="semibold">DETAILS</Text>
          </ButtonBase>
        </div>
      </div>
    </div>
  );
};

BundleItem.propTypes = {
  isPreventative: PropTypes.bool,
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  isGTTLDisqualifiedDueHomeownerFixingItems: PropTypes.bool,
  price: PropTypes.node,
  inspectionType: PropTypes.object,
  isDoNotExceedGroup: PropTypes.bool,
};
