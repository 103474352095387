import { BASE_PATHS } from '@belong/common';
import type { PropertySummary } from 'api/models';

export function getSaveAndExitPath({ properties }: Record<'properties', PropertySummary[]>) {
  if (properties?.length > 1) {
    return BASE_PATHS.HOMEOWNER_SETUP_FLOW;
  }

  if (properties?.length === 1) {
    return `${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${properties[0].basicInfo.propertyId}`;
  }

  return BASE_PATHS.ACCOUNTS;
}
