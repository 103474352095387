import { __assign } from '../node_modules/tslib/tslib.es6.mjs.js';
import { useState, useEffect } from 'react';

function useScript(_a) {
  var src = _a.src,
    onLoad = _a.onLoad,
    onLoadError = _a.onLoadError;
  var _b = useState({
      isLoading: true,
      error: null
    }),
    scriptState = _b[0],
    setScriptState = _b[1];
  useEffect(function () {
    var script = document.createElement('script');
    script.src = src;
    var handleLoad = function () {
      setScriptState(function (prevState) {
        return __assign(__assign({}, prevState), {
          isLoading: false
        });
      });
      onLoad === null || onLoad === void 0 ? void 0 : onLoad();
    };
    var handleError = function (err) {
      setScriptState({
        isLoading: false,
        error: err
      });
      onLoadError === null || onLoadError === void 0 ? void 0 : onLoadError(err);
    };
    script.addEventListener('load', handleLoad);
    script.addEventListener('error', handleError);
    document.body.appendChild(script);
    return function () {
      script.removeEventListener('load', handleLoad);
      script.removeEventListener('error', handleError);
      document.body.removeChild(script);
    };
  }, []);
  return {
    isLoading: scriptState.isLoading,
    loadError: scriptState.error
  };
}

export { useScript };
