import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BREAKPOINTS_WIDTHS, formatMoney, useWindowSize } from '@belong/common';
import { Button, Text } from '@belong/ui';
import PaymentMethodContainer from 'containers/PaymentMethodContainer/PaymentMethodContainer';
import {
  MaintenancePaymentPlan,
  MaintenancePaymentMethod,
  PaymentMethod,
  PaymentPreference,
  PaymentPriority,
} from 'models/enums';
import { ApprovalContext } from 'pages/PostInspectionFlow/steps/Improvements/Approval/ApprovalContext';
import { QuestionsOrConcernsModalFooter } from 'post-inspection/components/question-or-concern-modal-footer/question-or-concern-modal-footer';
import { STRINGS } from 'post-inspection/constants/strings';
import { selectFullUser } from 'store/redux/user/selectors';
import { getString } from 'strings';

export const PaymentMethodStep = ({ onNextStepClick }) => {
  const {
    homeownerPayment,
    onApprovalStepSubmit,
    homeownerReportPayment,
    setIsSubmitting,
    isLegacyInvoicing,
    isDoNotExceedGroup,
    onNext,
  } = useContext(ApprovalContext);
  const fullUser = useSelector(selectFullUser);
  const { width } = useWindowSize();

  const isInvoicedByGroup = isLegacyInvoicing && isDoNotExceedGroup;

  const isMobile = width <= BREAKPOINTS_WIDTHS.SM;

  const { groupDueNow } = homeownerReportPayment;

  const userPrimaryPaymentMethod = fullUser?.getPaymentMethodFor(
    PaymentPreference.Maintenance,
    (isInvoicedByGroup && PaymentPriority.Backup) || PaymentPriority.Primary
  );

  const handlePayNow = async () => {
    setIsSubmitting(true);

    const data = { ...homeownerPayment };

    data.deferPaymentUntilLease = null;
    data.installmentCount = 1;
    data.paymentPlan = MaintenancePaymentPlan.OneTime;
    data.paymentMethod =
      userPrimaryPaymentMethod?.paymentMethod === PaymentMethod.Ach
        ? MaintenancePaymentMethod.Check
        : userPrimaryPaymentMethod.paymentMethod;

    try {
      const depositResponse = await onApprovalStepSubmit({ groupPayment: data });

      if (depositResponse && isInvoicedByGroup) onNext({ refreshFlow: true });
      else onNextStepClick();
    } catch (err) {
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (groupDueNow === 0) {
      onNextStepClick();
    }
  }, [groupDueNow]);

  return (
    <div className="flex flex-col items-center justify-between pt-xl sm:pt-3xl w-full">
      <div className="flex flex-col items-center w-full">
        <div className="text-center sm:max-w-[340px] md:max-w-[700px]">
          {groupDueNow > 0 ? (
            <Text fontWeight="semibold" variant="h2">
              {getString(STRINGS['payment-method-step-title'], {
                dueNow: formatMoney(groupDueNow, 'DOLLARS_WITH_CENTS'),
              })}
            </Text>
          ) : (
            <>
              {userPrimaryPaymentMethod ? (
                <Text fontWeight="semibold" variant="h2">
                  {getString(STRINGS['payment-method-step-title-no-due-confirm'])}
                </Text>
              ) : (
                <Text fontWeight="semibold" variant="h2">
                  {getString(STRINGS['payment-method-step-title-no-due'])}
                </Text>
              )}
            </>
          )}
        </div>
        <div className="text-center pt-xs sm:max-w-[510px]">
          <Text>
            {STRINGS[isInvoicedByGroup ? 'payment-method-step-subtitle-do-not-exceed' : 'payment-method-step-subtitle']}
          </Text>
        </div>
        <div className="mb-xl sm:mb-0 mt-xl w-full">
          <div className="px-sm mb-lg">
            <PaymentMethodContainer
              maintenanceOnly
              defaultPriority={isInvoicedByGroup ? PaymentPriority.Backup : PaymentPriority.Primary}
              hasLabels={false}
            />
          </div>
          <QuestionsOrConcernsModalFooter>
            <Button
              disabled={!userPrimaryPaymentMethod}
              onClick={handlePayNow}
              size={isMobile ? 'fluid' : groupDueNow ? 'default' : 'large'}
            >
              {groupDueNow ? STRINGS['pay-now'] : STRINGS.next}
            </Button>
          </QuestionsOrConcernsModalFooter>
        </div>
      </div>
    </div>
  );
};
