import { Image, Text } from '@belong/ui';
import CircledNumber from 'components/CircledNumber/CircledNumber';
import DividerRow from 'components/DividerRow/DividerRow';
import Carousel from 'components/v2/Carousel/Carousel';
import { formatString } from 'strings';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';

function OurPromise() {
  const responsibilityDividerRowItems = [
    {
      icon: <Image src="/improvement-reports/pre_moveout_icon_01.svg" className="w-[96px] h-[96px]" alt="Accuracy" />,
      top: <CircledNumber>1</CircledNumber>,
      title: 'Accuracy',
      subtitle: formatString(
        `We'll help you take the guess work out of who owes what and why with our rigorous inspections.`
      ),
    },
    {
      icon: (
        <Image src="/improvement-reports/pre_moveout_icon_02.svg" className="w-[96px] h-[96px]" alt="Transparency" />
      ),
      top: <CircledNumber>2</CircledNumber>,
      title: 'Transparency',
      subtitle: formatString(`You'll never be in the dark on repairs, costs, or who is responsible.`),
    },
    {
      icon: <Image src="/improvement-reports/pre_moveout_icon_03.svg" className="w-[96px] h-[96px]" alt="Speed" />,
      top: <CircledNumber>3</CircledNumber>,
      title: 'Speed',
      subtitle: formatString(
        `We'll get your home loved as quickly as possible by focusing on the most crucial repairs.`
      ),
    },
  ];

  return (
    <>
      <div className="mb-2xl lg:xl">
        <Text variant="h3" fontWeight="semibold">
          {POST_INSPECTION_FLOW_STRINGS['responsibility.our_promise.title']}
        </Text>
      </div>
      <div className="mb-4xl lg:mb-3xl">
        <div className="hidden md:block">
          <DividerRow desktopColumns={3} items={responsibilityDividerRowItems} />
        </div>
        <div className="md:hidden">
          <Carousel pagingDotsClassName="green">
            {responsibilityDividerRowItems.map((item, index) => (
              <DividerRow.Item key={index} item={item} defaultMobileIconSize={false} />
            ))}
          </Carousel>
        </div>
      </div>
    </>
  );
}

export default OurPromise;
