import { EmployeeAssignmentDetailModel } from 'api/models';
import { getInspectionType } from 'pages/PostInspectionFlow/utils';
import { formatString } from 'strings';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import PostInspectionContentLayout from '../../../PostInspectionContentLayout/PostInspectionContentLayout';
import { getImprovementsAssignedEmployee } from '../utils';
import BelongStandards from './components/BelongStandards';
import DepositDeductions from './components/DepositDeductions';
import OurPromise from './components/OurPromise';
import WearAndTear from './components/WearAndTear';
import WearAndTearVsResidentDamage from './components/WearAndTearVsResidentDamage';

export const ResponsibilityPreMoveOutContent = () => {
  return (
    <>
      <OurPromise />

      <BelongStandards />

      <WearAndTear />
    </>
  );
};

const ResponsibilityMoveOutContent = () => {
  return (
    <>
      <WearAndTearVsResidentDamage />

      <WearAndTear />

      <DepositDeductions />
    </>
  );
};

type Props = {
  stepResource: {
    homeListingDetailsModel: {
      employeeAssignments: EmployeeAssignmentDetailModel[];
    };
  };
  onSaveAndNext: () => void;
  stepConfigs: { [key: string]: unknown };
  stepConfig: { [key: string]: unknown };
  flow: string;
  stepData: {
    isDepositFreeLease: boolean;
  };
};

const Responsiblity = ({ stepResource, onSaveAndNext, stepConfigs, stepConfig, flow, stepData }: Props) => {
  const currentLease = stepResource.homeListingDetailsModel?.leases?.find(
    (lease) => lease?.basicInfo?.leaseId === stepResource?.homeListingDetailsModel?.inspection?.leaseUniqueId
  );

  const isAdoptedHome = currentLease?.basicInfo?.areResidentsAdopted;

  const inspectionType = getInspectionType(flow);

  const assessedBy = getImprovementsAssignedEmployee({
    employeeAssignments: stepResource?.homeListingDetailsModel?.employeeAssignments,
    isMoveOut: inspectionType.isMoveOut,
  });

  const stepTitle = () => {
    if (isAdoptedHome) {
      return formatString(POST_INSPECTION_FLOW_STRINGS['responsibility.title.adopted_home']);
    }

    if (inspectionType.isPreMoveOut) {
      return formatString(POST_INSPECTION_FLOW_STRINGS['responsibility.title.pre_move_out']);
    }

    if (stepData?.isDepositFreeLease) {
      return formatString(POST_INSPECTION_FLOW_STRINGS['responsibility.title.move_out.deposit_free']);
    }

    return formatString(POST_INSPECTION_FLOW_STRINGS['responsibility.title.move_out']);
  };

  return (
    <PostInspectionContentLayout
      stepConfigs={stepConfigs}
      stepConfig={stepConfig}
      title={stepTitle()}
      employee={assessedBy}
      showCityBackground
    >
      <div className="justify-center mb-3xl hidden md:flex">
        <PostInspectionContentLayout.CtaButton onClick={() => onSaveAndNext()} extraSpace={800}>
          {formatString(POST_INSPECTION_FLOW_STRINGS['responsibility.next'])}
        </PostInspectionContentLayout.CtaButton>
      </div>

      <div className="md:hidden overflow-hidden w-0 h-0">
        <PostInspectionContentLayout.CtaButton onClick={() => onSaveAndNext()} displayfixed>
          {formatString(POST_INSPECTION_FLOW_STRINGS['responsibility.next'])}
        </PostInspectionContentLayout.CtaButton>
      </div>
      {inspectionType.isPreMoveOut ? <ResponsibilityPreMoveOutContent /> : <ResponsibilityMoveOutContent />}
    </PostInspectionContentLayout>
  );
};

export default Responsiblity;
