import React, { Component } from 'react';
import classNames from 'classnames/bind';
import Button, { BUTTON_TYPES } from 'components/Button/Button';
import { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import IconButton from 'components/IconButton/IconButton';
import String from 'components/String/String';
import Space from 'corecomponents/Space/Space';
import PropTypes from 'prop-types';
import styles from './FormLayout.module.css';

export const FormLayoutContext = React.createContext(undefined);

const cx = classNames.bind(styles);

const renderString = (node) => {
  if (typeof node === 'string') {
    return <String string={node} />;
  }
  return node;
};

export const SeperatorTypes = {
  HORIZONTAL: 'Horizontal',
};

export const Title = ({ title, header, className = '' }) => (
  <div className={cx(header ? 'title' : 'section-title', className)}>{renderString(title)}</div>
);

Title.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  header: PropTypes.bool,
};

Title.defaultProps = {
  header: false,
};

class FormLayout extends Component {
  static Title = Title;

  static FormLayoutHeader = ({
    subtitle,
    pretitle,
    title,
  }: {
    subtitle?: string;
    pretitle?: string;
    title?: string;
  }) => {
    const getTitle = () => {
      if (subtitle && title) {
        return (
          <div className={cx('form-header-wrapper')}>
            <Title header title={title} />
            <Space value="xs" />
            <div className={cx('subtitle')}>{renderString(subtitle)}</div>
          </div>
        );
      }

      if (pretitle && title) {
        return (
          <div className={cx('form-header-wrapper')}>
            <div className={cx('pretitle')}>{renderString(pretitle)}</div>
            <Space value="xs" />
            <Title header title={title} />
          </div>
        );
      }
      if (subtitle) {
        return (
          <div className={cx('form-header-wrapper')}>
            <div className={cx('subtitle')}>{renderString(subtitle)}</div>
          </div>
        );
      }
      if (title) {
        return (
          <div className={cx('form-header-wrapper')}>
            <Title header title={title} />
          </div>
        );
      }
      return null;
    };
    return getTitle();
  };

  //TODO: Remove any
  static Section = ({
    firstSection,
    sectionTitle,
    sectionTitleClassName,
    sectionSubTitle,
    separatorType,
    children,
    disableTitle,
    large,
    className,
  }: any) => {
    const getSectionTitle = () => {
      if (sectionTitle && sectionSubTitle) {
        return (
          <div className={cx('section-title-wrapper', { firstSection }, { disableTitle })}>
            <Title title={sectionTitle} className={sectionTitleClassName} />
            <Space value="xs" />
            <div className={cx('section-subtitle')}>{renderString(sectionSubTitle)}</div>
          </div>
        );
      }
      if (sectionSubTitle) {
        return (
          <div className={cx('section-title-wrapper', { firstSection }, { disableTitle })}>
            <div className={cx('section-subtitle')}>{renderString(sectionSubTitle)}</div>
          </div>
        );
      }
      if (sectionTitle) {
        return (
          <div className={cx('section-title-wrapper', { firstSection }, { disableTitle })}>
            <Title title={sectionTitle} className={sectionTitleClassName} />
          </div>
        );
      }
      return null;
    };
    return (
      <div className={cx('section-wrapper', separatorType, { large }, className)}>
        {getSectionTitle()}
        <div className={cx('section')}>{children}</div>
      </div>
    );
  };

  static BottomBar = ({
    ctaProps,
    previousStep,
    previousStepCustomLabel,
    previousStepProps = {},
    skip,
    callout,
    nextButtonWrapperProps = {},
    customClassName = '',
    previousContent,
  }) => (
    <>
      <div className={cx(['bottom-bar', customClassName])}>
        <div className={cx('previous')}>
          {previousContent}
          {(previousStep || !!Object.keys(previousStepProps).length) && (
            <IconButton
              icon={GENERAL_ICONS.BACK_ARROW}
              label={previousStepCustomLabel || 'Previous'}
              to={previousStep}
              {...previousStepProps}
            />
          )}
        </div>
        <div className={cx('next')}>
          {callout && <div className={cx('callout')}>{callout}</div>}
          {skip && <Button className={cx('skip')} buttonType={BUTTON_TYPES.TEXT} label="Skip" onClick={skip} />}
          <FormLayout.Button
            buttonClassNames={cx('button-container')}
            nextButtonWrapperProps={nextButtonWrapperProps}
            ctaProps={ctaProps}
          />
        </div>
      </div>

      <div className={cx('bottom-bar-mobile-wrapper')}>
        <div className={cx('bottom-bar-margin')} />
        <div className={cx('bottom-bar-mobile')}>
          {callout && <div className={cx('callout')}>{callout}</div>}
          {skip && <Button className={cx('skip')} buttonType={BUTTON_TYPES.TEXT} label="Skip" onClick={skip} />}
          <div className={cx('main-ctas')}>
            {(previousStep || !!Object.keys(previousStepProps).length) && (
              <div className={cx('previous')}>
                <Button
                  className={cx('previous-button')}
                  to={previousStep}
                  size="fit"
                  label={previousStepCustomLabel || 'Previous'}
                  {...previousStepProps}
                />
              </div>
            )}
            <FormLayout.Button
              buttonClassNames={cx('button-container', {
                withoutPrevious: !previousStep && !Object.keys(previousStepProps).length,
              })}
              nextButtonWrapperProps={nextButtonWrapperProps}
              ctaProps={ctaProps}
            />
          </div>
        </div>
      </div>
    </>
  );

  static Button = ({ buttonClassNames, nextButtonWrapperProps, ctaProps }) => (
    <div className={buttonClassNames} {...nextButtonWrapperProps}>
      <Button className={cx('cta-button')} type="submit" size="fit" {...ctaProps} />
    </div>
  );

  static Divider = ({ label }) => (
    <div className={cx('divider', { label })}>
      <span className={cx('divider-left-line')} />
      {label && <span>{label}</span>}
      <span className={cx('divider-right-line')} />
    </div>
  );

  // This should pass in the route to the previous page
  // and the Previous Step Icon should be here

  static propTypes = {
    children: PropTypes.node.isRequired,
    // Not sure if this should be here.
  };

  render() {
    const { children } = this.props;
    return (
      <FormLayoutContext.Provider value>
        <div className={cx('form-layout')}>{children}</div>
      </FormLayoutContext.Provider>
    );
  }
}

export default FormLayout;
