import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { STEP_GATE_NAMES, STEP_TIPS } from 'accounts/constants/steps';
import { useProperties } from 'accounts/hooks/homeowners/use-properties';
import { useSteps } from 'accounts/hooks/homeowners/use-steps';
import {
  getFilteredGateSteps,
  getStepPath,
  isCurrentPropertyOrUnitStep,
  isStepCompleted,
  isStepUnHidden,
} from 'accounts/utils/flow-steps';
import { getSetupFlowSteps } from 'accounts/utils/steps';
import { findIndex } from 'lodash-es';
import { FlowType } from 'models/enums';
import { BASE_PATHS } from 'routes/paths';
import {
  fetchStepData as fetchHomeInspectionStepData,
  updateStepData as updateHomeInspectionStepData,
} from 'store/redux/home-inspection-flow';
import {
  fetchStepData as fetchHomeListingStepData,
  updateStepData as updateHomeListingStepData,
} from 'store/redux/home-listing-flow';
import {
  fetchStepData as fetchHomeownerSetUpStepData,
  updateStepData as updateHomeownerSetUpStepData,
} from 'store/redux/homeowner-set-up-flow/actions';
import {
  fetchStepData as fetchUserPaymentStepData,
  updateStepData as updateUserPaymentStepData,
} from 'store/redux/user-payment-flow';
import { selectUser } from 'store/redux/user/selectors';
import { formatString } from 'strings';
import { useFlows } from './use-flows';
import { useSelectedProperty } from './use-selected-property';
import { useSelectedUnit } from './use-selected-unit';

export function useSetupFlowSteps() {
  const dispatch = useDispatch();
  const history = useHistory();
  // eslint-disable-next-line
  const { propertyId, unitId, step: stepParam, stepId, flowName } = useParams<any>();
  const user = useSelector(selectUser);
  const { flows, fetchFlows } = useFlows();

  const { properties, fetchProperties } = useProperties();
  const { selectedProperty } = useSelectedProperty();
  const { selectedUnit } = useSelectedUnit();
  const [goNext, setGoNext] = useState(false);

  const setupFlows = flows?.filter((flow) => {
    if (
      flow.flowType === FlowType.HomeownerSetup ||
      flow.flowType === FlowType.HomeInspection ||
      flow.flowType === FlowType.HomeListing
    ) {
      const isCurrentPropertyOrUnit = flow.steps?.some((step) => isCurrentPropertyOrUnitStep(step, selectedProperty));
      return isCurrentPropertyOrUnit;
    }

    if (flow.flowType === FlowType.UserPayment) {
      return flow.steps?.some((step) => step.dataUniqueId === user?.userId);
    }

    return false;
  });

  const propertyWithAdoptedResidents = selectedProperty?.units?.some((unit) => unit.isAdoptedAgreement);

  const { steps, allStepsByGate } = useSteps({ properties, setupFlows });
  const [loading, setLoading] = useState(true);

  const [stepsConfig, setStepsConfig] = useState();
  const [currentStepState, setCurrentStepState] = useState({
    currentStep: null,
    currentStepFormData: null,
  });

  /**
   * NOTE: This useEffect hook controls navigation between steps.
   * It sets a flag (goNext) to delay navigation until handleSaveAndNext() completes.
   * This ensures the correct data is used for navigation,
   * solving an issue where the last data wasn't being captured correctly.
   */
  useEffect(() => {
    if (goNext) {
      goNextStep();
      setGoNext(false);
    }
  }, [goNext]);

  const stepsByGate = allStepsByGate
    ? getFilteredGateSteps({
        allStepsByGate,
        unitId,
        selectedProperty,
        user,
      })
    : {};

  const { currentStep, currentStepFormData } = currentStepState;

  const fetchData = useCallback(
    async () => {
      const step = steps?.find((actualStep) => actualStep.uniqueId === stepId);
      if (!step) {
        setCurrentStepState({
          currentStep: null,
          currentStepFormData: null,
        });

        return null;
      }

      try {
        let currentStepFormDataResponse;
        if (step.flowType === FlowType.HomeownerSetup) {
          currentStepFormDataResponse = await dispatch(fetchHomeownerSetUpStepData(step.flowId, step));
        }
        if (step.flowType === FlowType.UserPayment) {
          currentStepFormDataResponse = await dispatch(fetchUserPaymentStepData(step.flowId, step));
        }
        if (step.flowType === FlowType.HomeInspection) {
          currentStepFormDataResponse = await dispatch(fetchHomeInspectionStepData(step.flowId, step));
        }
        if (step.flowType === FlowType.HomeListing) {
          currentStepFormDataResponse = await dispatch(fetchHomeListingStepData(step.flowId, step));
        }
        const newCurrentStepFormData = currentStepFormDataResponse || {};

        setCurrentStepState({
          currentStep: step,
          currentStepFormData: newCurrentStepFormData,
        });

        return newCurrentStepFormData;
      } catch (error) {
        console.log('Error', error);

        setCurrentStepState({
          currentStep: step,
          currentStepFormData: {},
        });

        return {};
      }
    },
    // steps never change but when it goes from not having length to having length, so this should be safe
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, stepId, steps?.length]
  );

  function isLastStep() {
    const filteredSteps = stepsByGate?.[currentStep?.gate]?.filter(isStepUnHidden);
    // eslint-disable-next-line
    const currentStepIndex = findIndex(filteredSteps, (step: any) => step.uniqueId === currentStep?.uniqueId);

    return currentStepIndex === filteredSteps?.length - 1;
  }

  async function goNextStep() {
    let filteredSteps = stepsByGate?.[currentStep?.gate]?.filter(isStepUnHidden);
    // eslint-disable-next-line
    const currentStepIndex = findIndex(filteredSteps, (step: any) => step.uniqueId === currentStep.uniqueId);
    const nextStepIndex = currentStepIndex + 1;
    const nextStep = filteredSteps?.[nextStepIndex];

    if (nextStep) {
      history.push(getStepPath(nextStep, propertyId, unitId));
    } else {
      if (currentStep.gate === STEP_GATE_NAMES.GetPaid) {
        history.push(`${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${propertyId}`);
      }

      const { units } = selectedProperty || {};
      // eslint-disable-next-line
      const currentUnitIndex = findIndex(units, (unit: any) => unit.basicInfo.unitId === unitId);
      // eslint-disable-next-line
      const nextUnitIndex = findIndex([...units?.slice(currentUnitIndex + 1)], (unit: any) => !unit.isFarOffMoveout);

      if (
        currentStep.gate === STEP_GATE_NAMES.BuildListing &&
        units?.length > 1 &&
        currentUnitIndex !== units?.length - 1 &&
        nextUnitIndex !== -1
      ) {
        // Go to the next unit
        const nextUnit = units?.[currentUnitIndex + 1];
        const nextUnitId = nextUnit.basicInfo.unitId;
        const newStepsByGate = getFilteredGateSteps({
          allStepsByGate,
          unitId: nextUnitId,
          selectedProperty,
          user,
        });

        filteredSteps = newStepsByGate?.[STEP_GATE_NAMES.InspectionSchedule]?.filter(isStepUnHidden);

        const [firstStep] = filteredSteps || [];

        if (!firstStep) {
          history.push(`${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${propertyId}`);
        } else {
          history.push({
            pathname: getStepPath(firstStep, propertyId, nextUnitId),
            search: 'showNextUnitBanner=true',
          });
        }
      } else {
        const gates = Object.keys(STEP_GATE_NAMES);
        // eslint-disable-next-line
        const currentGateIndex = findIndex(gates, (gateName: any) => gateName === currentStep.gate);

        filteredSteps = stepsByGate?.[gates[currentGateIndex + 1]]?.filter(isStepUnHidden);

        const [firstStep] = filteredSteps || [];

        if (!firstStep) {
          history.push(`${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${propertyId}`);
        } else {
          history.push(getStepPath(firstStep, propertyId, unitId));
        }
      }
    }
  }

  function goPreviousStep() {
    const filteredSteps = stepsByGate?.[currentStep.gate]?.filter(isStepUnHidden);
    // eslint-disable-next-line
    const currentStepIndex = findIndex(filteredSteps, (step: any) => step.uniqueId === currentStep.uniqueId);
    const step = filteredSteps?.[currentStepIndex - 1];

    history.push(getStepPath(step, propertyId, unitId));
  }

  async function handleSaveAndNext(values) {
    setLoading(true);

    try {
      if (currentStep.flowType === FlowType.HomeownerSetup) {
        await dispatch(updateHomeownerSetUpStepData(currentStep.flowId, currentStep, values));
      }
      if (currentStep.flowType === FlowType.UserPayment) {
        await dispatch(updateUserPaymentStepData(currentStep.flowId, currentStep, values));
      }
      if (currentStep.flowType === FlowType.HomeInspection) {
        await dispatch(updateHomeInspectionStepData(currentStep.flowId, currentStep, values));
      }
      if (currentStep.flowType === FlowType.HomeListing) {
        await dispatch(updateHomeListingStepData(currentStep.flowId, currentStep, values));
      }

      await fetchFlows();

      setGoNext(true);
    } catch (error) {
      console.log('Error', error);

      setLoading(false);

      throw error;
    }
  }

  async function handleSave(values) {
    setLoading(true);

    try {
      if (currentStep.flowType === FlowType.HomeownerSetup) {
        await dispatch(updateHomeownerSetUpStepData(currentStep.flowId, currentStep, values));
      }
      if (currentStep.flowType === FlowType.UserPayment) {
        await dispatch(updateUserPaymentStepData(currentStep.flowId, currentStep, values));
      }
      if (currentStep.flowType === FlowType.UserPayment) {
        await dispatch(updateHomeownerSetUpStepData(currentStep.flowId, currentStep, values));
      }
      if (currentStep.flowType === FlowType.HomeListing) {
        await dispatch(updateHomeListingStepData(currentStep.flowId, currentStep, values));
      }

      await fetchFlows();

      const fetchedData = await fetchData();

      return fetchedData;
    } catch (error) {
      console.log('Error', error);

      throw error;
    } finally {
      setLoading(false);
    }
  }

  function isValidUrl() {
    if (!flowName && !stepParam && !stepId) {
      return true;
    }

    if (flowName === 'success' && !stepParam && !stepId) {
      const allStepsCompleted = steps?.filter(isStepUnHidden)?.every(isStepCompleted);

      return !!allStepsCompleted;
    }

    const [stepBasedOnStepId] = steps?.filter((step) => step.uniqueId === stepId) ?? [];

    if (!stepBasedOnStepId) {
      return false;
    }

    const {
      group: { key: groupKey },
      key,
    } = stepBasedOnStepId;

    if (groupKey === flowName && key === stepParam) {
      return true;
    }

    return false;
  }

  function getSidebarTitleText() {
    const stepName = currentStepState?.currentStep?.stepName;
    const stepGroupName = currentStepState?.currentStep?.group?.name;

    if (stepName && stepGroupName) {
      const groupStrings = STEP_TIPS[stepGroupName];

      return groupStrings ? formatString(groupStrings[stepName]?.title) : '';
    }

    return '';
  }

  function getSidebarDescriptions() {
    const stepName = currentStepState?.currentStep?.stepName;
    const stepGroupName = currentStepState?.currentStep?.group?.name;
    if (!stepName || !stepGroupName) return [];

    const groupStrings = STEP_TIPS[stepGroupName];
    if (!groupStrings) return [];

    if (selectedUnit?.isAdoptedAgreement || propertyWithAdoptedResidents) {
      return Object.keys(groupStrings[stepName] || {}).includes('descriptions.adopted_resident')
        ? groupStrings[stepName]?.['descriptions.adopted_resident']
        : groupStrings[stepName]?.descriptions;
    }

    return groupStrings[stepName]?.descriptions;
  }

  function getSidebarDescriptionImages() {
    const stepName = currentStepState?.currentStep?.stepName;
    const stepGroupName = currentStepState?.currentStep?.group?.name;

    return STEP_TIPS?.[stepGroupName]?.[stepName]?.descriptionImages || [];
  }

  function getSidebarImage() {
    const stepName = currentStepState?.currentStep?.stepName;
    const stepGroupName = currentStepState?.currentStep?.group?.name;

    if (stepName && stepGroupName) {
      const groupStrings = STEP_TIPS[stepGroupName];

      return groupStrings ? groupStrings[stepName]?.image : undefined;
    }

    return undefined;
  }

  useEffect(() => {
    async function fetch() {
      setLoading(true);

      await fetchData();

      setLoading(false);
    }

    fetch();
  }, [fetchData]);

  useEffect(() => {
    async function init() {
      setLoading(true);

      const newStepsConfig = getSetupFlowSteps(propertyId, unitId);

      setStepsConfig(newStepsConfig);
      setLoading(false);
    }

    init();
  }, [propertyId, unitId]);

  return {
    properties,
    steps,
    allStepsByGate,
    loading,
    stepsConfig,
    currentStepFormData,
    currentStep,
    propertyId,
    unitId,
    stepsByGate,
    selectedProperty,
    fetchProperties,
    setLoading,
    getSidebarDescriptions,
    getSidebarTitleText,
    getSidebarImage,
    isValidUrl,
    handleSaveAndNext,
    goPreviousStep,
    isLastStep,
    handleSave,
    goNextStep,
    getSidebarDescriptionImages,
  };
}
