import { useSelector } from 'react-redux';
import { StepFormLayout } from 'accounts/components/step-form-layout';
import { USER_PAYMENT_METHOD_STRINGS } from 'accounts/constants/strings/user-payment-method.strings';
import { useSetupFlowStep } from 'accounts/hooks/homeowners/use-setup-flow-step-context';
import PaymentMethodContainer from 'containers/PaymentMethodContainer/PaymentMethodContainer';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { PaymentMethod, PaymentPreference, PaymentPriority } from 'models/enums/index';
import { selectFullUser } from 'store/redux/user/selectors';

export function UserPaymentMethods() {
  const { currentStepFormData, goPreviousStep, handleSaveAndNext } = useSetupFlowStep();
  const fullUser = useSelector(selectFullUser);

  function getInitialValues() {
    return {};
  }

  const primaryAccount = fullUser?.getPaymentMethodFor(PaymentPreference.Payout, PaymentPriority.Primary);

  if (!currentStepFormData) {
    return null;
  }

  return (
    <StepFormLayout
      title={USER_PAYMENT_METHOD_STRINGS.title}
      subTitle={USER_PAYMENT_METHOD_STRINGS.subtitle}
      onSubmit={handleSaveAndNext}
      initialValues={getInitialValues()}
      handleBackStep={goPreviousStep}
      ctaProps={{ disabled: !primaryAccount }}
      showUnitAddress={false}
      disableProgressBar
      getForm={() => (
        <FormLayout.Section>
          <Row>
            <Col md={6} xs={12}>
              <PaymentMethodContainer
                forcePaymentMethod={PaymentMethod.Ach}
                paymentType={PaymentPreference.Payout}
                primaryOnly
                showPriority={false}
                noCustomWidth
              />
            </Col>
          </Row>
        </FormLayout.Section>
      )}
    />
  );
}
