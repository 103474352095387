import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Text } from '@belong/ui';
import { MaintenancePaymentPlan } from 'models/enums';
import { ApprovalContext } from 'pages/PostInspectionFlow/steps/Improvements/Approval/ApprovalContext';
import { HomeownerReceiptPriceTable } from 'pages/PostInspectionFlow/steps/Improvements/Approval/PriceTable/PriceTable';
import {
  GTTLDate,
  TodayTimeCountDown,
} from 'pages/PostInspectionFlow/steps/Improvements/Components/DateTimeCountDown/DateTimeCountDown';
import { FinancingInfobox } from 'post-inspection/components/financing-infobox/financing-infobox';
import { FlaggedImprovementsWarning } from 'post-inspection/components/flagged-improvements-warning/flagged-improvements-warning';
import { LegacyPaymentPlanForm } from 'post-inspection/components/legacy-payment-plan-form/legacy-payment-plan-form';
import { PaymentHeroImage } from 'post-inspection/components/payment-hero-image/payment-hero-image';
import { PaymentSummaryFooter } from 'post-inspection/components/payment-summary-footer/payment-summary-footer';
import { STRINGS } from 'post-inspection/constants/strings';
import { useFlaggedImprovements } from 'post-inspection/hooks/use-flagged-improvements';
import { fetchHomeownerTimeline } from 'post-inspection/hooks/use-timeline';
import { BASE_PATHS } from 'routes/paths';

type Props = {
  isLegacyInvoicing?: boolean;
  onNextStepClick: () => void;
};

// todo: rename onNextStepClick to onNext
export const PaymentSummaryStep = ({ isLegacyInvoicing = false, onNextStepClick }: Props) => {
  const { push } = useHistory();
  const {
    bundles,
    flow,
    groupPayment,
    homeownerPayment,
    maximumMonthlyPaymentPlan,
    onImprovementToggle,
    homeownerReportPayment,
    rewards,
    inspectionType,
    onApprovalStepSubmit,
    isNewProjectManagementFee,
    priceRange,
    guaranteedTimeToList,
  } = useContext(ApprovalContext);
  const dispatch = useDispatch();
  const { homeUniqueId } = flow?.configuration || {};
  const [isReadyForGTTL, setIsReadyForGTTL] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { timelineEvents } = await dispatch(fetchHomeownerTimeline(homeUniqueId));
      const areKeysHandedOff = timelineEvents?.some((event) => event.timelineEventType === 'KeysHandedOff');

      setIsReadyForGTTL(areKeysHandedOff);
    };

    fetchData();
  }, [homeUniqueId]);

  const { isOnboarding, isPreMoveOut } = inspectionType;
  const isGTTLVisible = !guaranteedTimeToList?.disqualifiedReason && isOnboarding;

  function handleNextStepClick() {
    if (isPreMoveOut) {
      onApprovalStepSubmit({ groupPayment: { ...homeownerPayment, paymentPlan: MaintenancePaymentPlan.OneTime } });
      return push(`${BASE_PATHS.POST_INSPECTION_FLOW}/improvements/success`);
    }

    return onNextStepClick();
  }

  const { flaggedImprovements } = useFlaggedImprovements();

  return (
    <div id="post-inspection-improvements-modal">
      <div className="-mt-2xl -mx-sm sm:mt-0 sm:mx-0">
        <PaymentHeroImage />
      </div>
      <div className="flex flex-col justify-center items-center pt-xl pb-lg">
        <div className="max-w-[300px] text-center sm:max-w-none">
          <Text fontWeight="semibold" variant="h2">
            {isOnboarding ? STRINGS['ready-to-get-your-home-loved'] : STRINGS['ready-to-get-your-home-loved.move-out']}
          </Text>
        </div>

        {isGTTLVisible && isReadyForGTTL && (
          <div className="mt-xs text-center">
            Submit your deposit in the next
            <TodayTimeCountDown />
            and
            <br className="hidden md:inline" />
            we&apos;ll get your home professionally listed by
            <GTTLDate date={guaranteedTimeToList?.date} />.
          </div>
        )}

        {isGTTLVisible && isReadyForGTTL === false && (
          <div className="mt-xs text-center">
            Submit your deposit in the next
            <TodayTimeCountDown />
            and we&apos;ll get your home
            <br className="hidden md:inline" />
            professionally listed
            <span className="font-semibold text-green mx-2xs">within {guaranteedTimeToList?.days} days</span>
            of us having access to your {`vacant\u00a0home`}.
          </div>
        )}
      </div>

      {Boolean(flaggedImprovements.length) && !isLegacyInvoicing && <FlaggedImprovementsWarning />}

      <div className="mb-sm mx-xs sm:mx-sm">
        <HomeownerReceiptPriceTable
          inspectionType={inspectionType}
          groupPayment={groupPayment}
          bundles={bundles}
          homeownerPayment={homeownerPayment}
          maximumMonthlyPaymentPlan={maximumMonthlyPaymentPlan}
          onChange={onImprovementToggle}
          isLegacyInvoicing={isLegacyInvoicing}
          homeownerReportPayment={homeownerReportPayment}
          isNewProjectManagementFee={isNewProjectManagementFee}
          priceRange={priceRange}
          showSubtotal={false}
        />
        {!isLegacyInvoicing && (
          <div className="mt-sm mb-6xl md:mb-0">
            <FinancingInfobox />
          </div>
        )}
      </div>
      {isLegacyInvoicing ? (
        <div className="pb-sm px-sm">
          <LegacyPaymentPlanForm bundles={bundles} homeownerPayment={homeownerPayment} rewards={rewards} />
        </div>
      ) : (
        <PaymentSummaryFooter onNextStepClick={handleNextStepClick} />
      )}
    </div>
  );
};
