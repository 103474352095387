import { Image, Text } from '@belong/ui';
import Carousel from 'components/v2/Carousel/Carousel';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import { belongStandardsItems } from '../constants';

const BelongStandard = ({ item, isEven = false, isLastIndex = false }) => (
  <div className={`flex -mx-2sm mb-xl ${isLastIndex ? 'mb-2xl' : ''} ${isEven ? 'flex-row' : 'flex-row-reverse'}`}>
    <div className="flex-1 px-2sm mb-sm lg:mb-0">
      <Image src={item.imageUrl} className="rounded h-6xl" alt={item.title} />
    </div>
    <div className="flex-1 px-2sm flex flex-col justify-center">
      <div className="mb-xs">
        <Text fontWeight="semibold">{item.title}</Text>
      </div>
      <div>
        <Text>{item.body}</Text>
      </div>
    </div>
  </div>
);

function BelongStandards() {
  return (
    <>
      <div className="mb-xl lg:mb-2xl">
        <Text variant="h3" fontWeight="semibold" className="mb-xs">
          {POST_INSPECTION_FLOW_STRINGS['responsibility.belong_standards.title']}
        </Text>
        <Text>{POST_INSPECTION_FLOW_STRINGS['responsibility.belong_standards.body']}</Text>
      </div>

      <div className="mb-4xl lg:mb-3xl">
        {/* Desktop Belong standards */}
        <div className="hidden md:block">
          {belongStandardsItems.map((item, index) => {
            const isLastIndex = index === belongStandardsItems.length - 1;
            const isEven = index % 2 === 0;
            return <BelongStandard key={index} item={item} isLastIndex={isLastIndex} isEven={isEven} />;
          })}
        </div>

        {/* Mobile Belong standards */}
        <div className="md:hidden">
          <Carousel pagingDotsClassName="green">
            {belongStandardsItems.map((item, index) => {
              return <BelongStandard key={index} item={item} />;
            })}
          </Carousel>
        </div>
      </div>
    </>
  );
}

export default BelongStandards;
