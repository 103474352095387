import * as FullStory from '@fullstory/browser';
import { EmployeeAssignmentDetailModel, EmployeeEmployeeAssignmentModel } from 'api/models';

export const extractHomesFromProperties = (properties) => {
  return properties.reduce((acc, property) => {
    const homes = property.units.map((home) => ({
      homeId: home.basicInfo.unitId,
      propertyId: property.basicInfo.propertyId,
      propertyType: property.basicInfo.propertyType,
      address: {
        ...property.address,
        unitNumber: home.basicInfo.unitNumber,
      },
    }));
    return acc.concat(homes);
  }, []);
};

export const getInspectionType = (
  flow
): {
  isAdoptedLease: boolean;
  isOnboarding: boolean;
  isMoveOut: boolean;
  isPreMoveOut: boolean;
} => {
  const { flowSubType, isAdoptedLease } = flow?.configuration || {};
  if (!flowSubType) return;
  return {
    isAdoptedLease,
    isOnboarding: flowSubType === 'Onboarding', // adopted lease comes as onboarding
    isMoveOut: flowSubType === 'MoveOut',
    isPreMoveOut: flowSubType === 'PreMoveOut',
  };
};

// The only reason to do that is because Lily asked to "come back" if the changes don't pay off.
// Besides, we don't currently support feature flags.
export const groupRecommendedImprovements = true;

export const QUERY_PARAMS = {
  REPORT_APPROVED: 'reportApproved',
};

export const getEmployeeAssignment = ({ employeeAssignments, assignmentType }) =>
  employeeAssignments?.find((e) => e?.employeeAssignment?.assignmentType === assignmentType) ||
  employeeAssignments?.[0];

export const getAssignedFormattedEmployee = ({
  employeeAssignments,
  assignmentTypes,
}: {
  employeeAssignments: EmployeeAssignmentDetailModel[];
  assignmentTypes?: EmployeeEmployeeAssignmentModel['assignmentType'][];
}) => {
  const assignedEmployeeByType = assignmentTypes
    .map((assignmentType) =>
      employeeAssignments.find((assignment) => assignment.employeeAssignment.assignmentType === assignmentType)
    )
    // Get the first assigned employee that is not undefined
    .find(Boolean);

  const firstAssignedEmployee = employeeAssignments[0];

  // if we don't find any assigned employee for the assignment types
  // return the first assigned employee of the home
  const assignedEmployee = assignedEmployeeByType ?? firstAssignedEmployee;

  return {
    ...assignedEmployee.employee,
    ...assignedEmployee.user,
    assignment: assignedEmployee.employeeAssignment,
    profileImage: assignedEmployee.user.profileImageUrl,
    bannerImage: assignedEmployee.employee.bannerImageUrl,
  };
};

type FullStoryEvent = {
  eventName:
    | 'flow-started'
    | 'flow-step-viewed'
    | 'flow-step-completed'
    | 'flow-completed'
    | 'flow-exited'
    | 'flow-error'
    | 'flow-already-completed';
  metadata: {
    homeId: string;
    source: 'Email' | 'App' | 'Placemat';
    flowType: 'Improvements' | 'Pricing';
    flowSubType: 'Onboarding' | 'moveOut' | 'preMoveOut';
    // Added both stepEnum and stepDisplayName since the enum is not always the same as the display name.
    stepEnum: string; // e.g. 'get-started'
    stepDisplayName: string; // e.g. 'Intro'
  };
};

export const trackFullStoryEvent = ({ eventName, metadata }: FullStoryEvent) => {
  if (typeof window === 'undefined') return;

  FullStory.event(eventName, metadata);
};
