import { useHistory } from 'react-router';
import { formatDateTime, DATE_FORMATS, BASE_PATHS } from '@belong/common';
import { Text, Image } from '@belong/ui';
import { clsx } from 'clsx';
import CircledNumber from 'components/CircledNumber/CircledNumber';
import DividerRow from 'components/DividerRow/DividerRow';
import MultipleBelongEmployeeProfileView from 'components/MultipleBelongEmployeeProfileView/MultipleBelongEmployeeProfileView';
import Carousel from 'components/v2/Carousel/Carousel';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import Page from 'layouts/Page/Page';
import { getInspectionType, QUERY_PARAMS } from 'pages/PostInspectionFlow/utils';
import { formatString } from 'strings';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import { formatFirstLine } from 'utils/formatAddress';
import PostInspectionContentLayout from '../../../PostInspectionContentLayout/PostInspectionContentLayout';
import { getImprovementsAssignedEmployee } from '../utils';
import styles from './Intro.module.css';

const dividerRowItems = [
  {
    top: <CircledNumber>1</CircledNumber>,
    icon: <Image className={styles['divider-image']} src="/post_inspection/intro-1.png" alt="rent faster image" />,
    subtitle: formatString(POST_INSPECTION_FLOW_STRINGS['intro.divider'][0]),
  },
  {
    top: <CircledNumber>2</CircledNumber>,
    icon: (
      <Image
        className={styles['divider-image']}
        src="/post_inspection/intro-2.png"
        alt="Residents will stay longer image"
      />
    ),
    subtitle: formatString(POST_INSPECTION_FLOW_STRINGS['intro.divider'][1]),
  },
  {
    top: <CircledNumber>3</CircledNumber>,
    icon: (
      <Image
        className={styles['divider-image']}
        src="/post_inspection/intro-3.png"
        alt="treat your home with love image"
      />
    ),
    subtitle: formatString(POST_INSPECTION_FLOW_STRINGS['intro.divider'][2]),
  },
];

const Intro = ({ stepResource, onSaveAndNext, stepConfigs, stepConfig, flow }) => {
  const history = useHistory();

  const areNonHomeowerFixesStepsCompleted = stepConfigs
    ?.filter((step) => step.key !== 'homeowner-fixes' && !step.isHidden)
    .every((step) => step.status === 'Completed');

  /**
   * The homeowner cannot go back to this step once the report is approved.
   */
  if (areNonHomeowerFixesStepsCompleted) {
    history.push(
      `${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${stepConfig.home.propertyId}/setup-flow?${QUERY_PARAMS.REPORT_APPROVED}=true`
    );

    return null;
  }

  const { homeListingDetailsModel } = stepResource;

  const inspectionType = getInspectionType(flow);

  const { inspectorSelfie, inspectorWithHomeowner } = homeListingDetailsModel?.inspection?.inspectionDetail || {};

  const homeownerName = homeListingDetailsModel?.owner?.firstName;

  const assessedBy = getImprovementsAssignedEmployee({
    employeeAssignments: homeListingDetailsModel?.employeeAssignments,
    isMoveOut: inspectionType?.isMoveOut,
  });

  const assessedByEmployeeProfile = assessedBy
    ? {
        ...assessedBy,
        firstName: (
          <div>
            <Text as="span" variant="p1">
              Assessed By
            </Text>
            &nbsp;
            <Text as="span" fontWeight="semibold" variant="p1">
              {assessedBy.firstName}
            </Text>
          </div>
        ),
      }
    : undefined;

  let title;

  if (inspectionType.isOnboarding || inspectionType.isAdoptedLease) {
    title = formatString(POST_INSPECTION_FLOW_STRINGS['intro.title.onboarding'], {
      homeownerName,
    });
  } else if (inspectionType.isPreMoveOut) {
    title = formatString(POST_INSPECTION_FLOW_STRINGS['intro.title.pre_move_out'], {
      homeownerName,
    });
  } else if (inspectionType.isMoveOut) {
    title = POST_INSPECTION_FLOW_STRINGS['intro.title.move_out'];
  }

  let bannerImageUrl;
  if (inspectorWithHomeowner) {
    bannerImageUrl = inspectorWithHomeowner.url;
  } else if (inspectorSelfie) {
    bannerImageUrl = inspectorSelfie.url;
  } else {
    bannerImageUrl = homeListingDetailsModel?.basicInfo?.bannerImageUrl;
  }

  return (
    <PostInspectionContentLayout
      stepConfigs={stepConfigs}
      stepConfig={stepConfig}
      title={title}
      showCityBackground
      employee={assessedBy}
      isLogoVisible={false}
    >
      <div className="justify-center mb-2xl hidden md:flex">
        <PostInspectionContentLayout.CtaButton onClick={() => onSaveAndNext()} extraSpace={800}>
          {formatString(POST_INSPECTION_FLOW_STRINGS['intro.next'])}
        </PostInspectionContentLayout.CtaButton>
      </div>

      <div className="md:hidden overflow-hidden w-0 h-0">
        <PostInspectionContentLayout.CtaButton onClick={() => onSaveAndNext()} displayfixed>
          {formatString(POST_INSPECTION_FLOW_STRINGS['intro.next'])}
        </PostInspectionContentLayout.CtaButton>
      </div>

      {bannerImageUrl && (
        <div className={clsx('mb-2xl relative -ml-2sm sm:ml-0', styles.bannerWrapper)}>
          <Image className="sm:rounded object-cover" src={bannerImageUrl} alt="banner" />
          <div className={clsx('absolute h-full w-full sm:rounded top-0 flex flex-col-reverse', styles.bannerGradient)}>
            <div className="self-end mx-auto mb-lg text-center px-2sm sm:px-0">
              <Text variant="h3" className="text-white" fontWeight="semibold">
                {formatFirstLine(homeListingDetailsModel?.address, homeListingDetailsModel?.basicInfo?.unitNumber)}
              </Text>
            </div>
          </div>
        </div>
      )}

      {homeListingDetailsModel?.inspection?.completedOn && (
        <div className="mb-lg text-center">
          <Text fontWeight="semibold">
            Inspected On{' '}
            {formatDateTime({
              dateTime: homeListingDetailsModel.inspection.completedOn,
              format: DATE_FORMATS.MONTH_DAY_SHORT,
            })}
          </Text>
        </div>
      )}
      <Page.OffsetGrid ignoreOffset={[SPACE_TYPES.MD, SPACE_TYPES.LG, SPACE_TYPES.XL]}>
        <MultipleBelongEmployeeProfileView employees={[assessedByEmployeeProfile]} />
      </Page.OffsetGrid>
      <Space value={SPACE_TYPES.XXXL} />

      {inspectionType?.isOnboarding && (
        <>
          <Text variant="h3" fontWeight="semibold">
            {formatString(POST_INSPECTION_FLOW_STRINGS['intro.divider_section.title'])}
          </Text>
          <div className="mt-xs mb-xl">{formatString(POST_INSPECTION_FLOW_STRINGS['intro.divider_section.body'])}</div>

          {/* Desktop divider row */}
          <div className={styles['desktop-divider-row-container']}>
            <DividerRow items={dividerRowItems} />
          </div>

          {/* Mobile divider row */}
          <div className={styles['mobile-divider-row-container']}>
            <Carousel pagingDotsClassName="green" className={styles['mobile-carousel-image']}>
              {dividerRowItems.map((item, index) => (
                <DividerRow.Item key={index} item={item} defaultMobileIconSize={false} />
              ))}
            </Carousel>
          </div>
        </>
      )}
    </PostInspectionContentLayout>
  );
};

export default Intro;
