import { useEffect, useState } from 'react';
import { MoneyRange, Button } from '@belong/ui';
import { ANALYTICS_MODAL_NAMES } from 'analytics';
import classNames from 'classnames/bind';
import BulletList from 'components/BulletList/BulletList';
import { CheckboxFinalFormAdapter } from 'components/Checkbox/Checkbox';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import Icon, { ICONS } from 'components/Icon/Icon';
import { InputFinalFormAdapter } from 'components/Input/Input';
import Label from 'components/Label/Label';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import { SelectorFinalFormAdapter, SELECTOR_TYPES } from 'components/Selector/Selector';
import ShadowMediaCard from 'components/ShadowMediaCard/ShadowMediaCard';
import Carousel from 'components/v2/Carousel/Carousel';
import { BREAKPOINTS_WIDTHS } from 'consts/breakpoints';
import CarouselModal from 'containercomponents/Modals/CarouselModal/CarouselModal';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import { Flex, Box, Text } from 'design-system';
import { capitalize } from 'es-toolkit';
import FormCondition, { FIELD_CONDITION_COMPARISON_TYPES } from 'formcomponents/FormCondition/FormCondition';
import { useModal } from 'hooks/useModal';
import { useWindowSize } from 'hooks/useWindowSize';
import FormLayout from 'layouts/FormLayout/FormLayout';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import {
  MaintenanceRequester,
  MaintenanceResponsibility,
  MaintenanceAssessment,
  ConsentStatus,
  MediaType,
  SubOwnerVisibility,
  MaintenanceBundleType,
} from 'models/enums';
import { BundleItemOptionList } from 'pages/PostInspectionFlow/steps/Improvements/Approval/Bundle/BundleItemOptionList/BundleItemOptionList';
import { SubtaskPriceTable } from 'pages/PostInspectionFlow/steps/Improvements/Approval/PriceTable/PriceTable';
import { BUNDLE_CONFIG } from 'pages/PostInspectionFlow/steps/Improvements/utils';
import PropTypes from 'prop-types';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import { required } from 'utils/validation';
import { ResidentResponsibilityLabel, SharedResponsibilityLabel } from '../Labels/Labels';
import styles from './ImprovementModal.module.css';

const cx = classNames.bind(styles);

export const IMPROVEMENT_FROM = {
  IMPROVEMENT_REPORT: 'IMPROVEMENT_REPORT',
};

const ImprovementModal = ({
  show,
  onHide,
  onSubmit,
  loading,
  improvement,
  onChange,
  isHomeowner,
  isInvoiced,
  showPriceBreakdown,
  showResponsibilityPercentage,
  extraTags = [],
  groupPayment = [],
  rewards = [],
  from = '',
  inspectionType,
  onSave,
  isDoNotExceedGroup,
}) => {
  const [currentOptionId, setCurrentOptionId] = useState(null);
  const [hasImprovementBeenTracked, setHasImprovementBeenTracked] = useState(false);

  const isEditable = !!onSubmit;

  const { costOptions, maintenance, maintenancePayments, roomOrSpace, subtaskDueNow, displayAsPriceRange, priceRange } =
    improvement;

  const { media, proServiceResponsibility } = maintenance;

  const improvementMedia = media?.filter(
    (mediaItem) => ![SubOwnerVisibility.Consumer, SubOwnerVisibility.Internal].includes(mediaItem.subOwnerVisibility)
  );

  const isInChargeOfBelong = proServiceResponsibility === MaintenanceResponsibility.Belong;

  const [optionSelected, setOptionSelected] = useState(proServiceResponsibility);

  const homeownerPayment = maintenancePayments.find(
    (payment) => payment.paidBy === MaintenanceResponsibility.Homeowner
  );

  const residentPayment = maintenancePayments.find((payment) => payment.paidBy === MaintenanceRequester.Resident);

  const residentResponsiblity = residentPayment?.percentage === 100;

  const showResidentResponsibilityTag = isHomeowner && residentResponsiblity;

  const sharedResponsiblity = homeownerPayment?.percentage > 0 && residentPayment?.percentage > 0;

  const showFlaggedTag = isHomeowner && homeownerPayment?.flagged;

  const isApproved = homeownerPayment?.percentage > 0 && homeownerPayment?.consentStatus === ConsentStatus.Approved;

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isModalOpen, setModalOpen, setModalClose] = useModal(false);
  const { width } = useWindowSize();

  const isMobile = width <= BREAKPOINTS_WIDTHS.MD;

  const getInitialValues = () => ({
    ...homeownerPayment,
    isSelected: isApproved,
    proServiceResponsibility,
  });

  const handleHide = () => {
    onHide();
    setCurrentSlideIndex(0);
  };

  const handleOptionSelect = (newOptionId) =>
    setCurrentOptionId((optionId) => (newOptionId === optionId ? null : newOptionId));

  if (!improvementMedia.length) {
    improvementMedia.push({ url: '/defaultbackgroundimage.png', mediaType: MediaType.Image });
  }

  const _showPriceBreakdown = showPriceBreakdown && isHomeowner;

  const rawClassification = BUNDLE_CONFIG[maintenance.classification]?.displayName || maintenance.classification;
  const formattedClassification = rawClassification.replace(/\w+/g, capitalize);

  useEffect(() => {
    const trackImprovementVisit = async () => {
      const improvementMaintenancePayments = [...improvement.maintenancePayments];

      const trackedMaintenancePayments = improvementMaintenancePayments.map((maintenancePayment) => ({
        ...maintenancePayment,
        flagged: !!maintenancePayment.flagged,
        hasSeenDetails: true,
      }));

      const trackedImprovement = {
        ...improvement,
        maintenancePayments: trackedMaintenancePayments,
      };

      await onSave({
        bundles: [
          {
            items: [trackedImprovement],
          },
        ],
      });
    };

    if (isEditable && !hasImprovementBeenTracked) {
      trackImprovementVisit();

      setHasImprovementBeenTracked(true);
    }
  }, [hasImprovementBeenTracked, isEditable, onSave]);

  useEffect(() => {
    if (costOptions?.length) {
      setCurrentOptionId((id) => id ?? maintenance.estimatedCostId);
    }
  }, [costOptions, maintenance]);

  const optionalBundleTypes = [MaintenanceBundleType.Inspirational, MaintenanceBundleType.Recommended];
  const isItemOptional = optionalBundleTypes.includes(maintenance.assessment);
  const isShowResponsibilitySelection =
    inspectionType?.isOnboarding && !isItemOptional && !inspectionType?.isAdoptedLease;

  return (
    <ModalV2
      name={ANALYTICS_MODAL_NAMES.IMPROVEMENTS}
      showSpinner={loading}
      show={show}
      onHide={handleHide}
      closeButton
      noPadding
      className={cx('no-padding')}
      overflowHidden
      noBorder
    >
      <ModalLayout fixedBottom>
        {improvementMedia?.length > 0 && (
          <Carousel
            className={cx('carousel')}
            displayControlsBreakpoint={BREAKPOINTS_WIDTHS.MD}
            disableEdgeSwiping
            renderCenterLeftControls={improvementMedia.length === 1 ? <div /> : null}
            renderCenterRightControls={improvementMedia.length === 1 ? <div /> : null}
            leftControlPosition={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '50%', left: 20 }}
            rightControlPosition={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '50%', right: 20 }}
            renderBottomCenterControls={isMobile && improvementMedia.length > 1 ? undefined : null}
            defaultBottomPosition={false}
            cellSpacing={0}
            afterSlide={(slideIndex) => setCurrentSlideIndex(slideIndex)}
          >
            {improvementMedia.map((file, index) => (
              <ShadowMediaCard
                key={file.uniqueId}
                height={360}
                file={file}
                containerClassName={cx('media-card')}
                autoplay={currentSlideIndex === index}
                onClick={setModalOpen}
                avoidShadow
                topLeft={
                  <div className={cx('top-left-container')}>
                    {maintenance.requestedBy !== MaintenanceRequester.Belong && (
                      <Label>{POST_INSPECTION_FLOW_STRINGS['improvements.by-you']}</Label>
                    )}

                    {isDoNotExceedGroup && maintenance?.isComplimentaryRepair && (
                      <Label className="mr-xs" color="dark-green">
                        {POST_INSPECTION_FLOW_STRINGS['improvements.complimentary']}
                      </Label>
                    )}

                    {sharedResponsiblity && !inspectionType?.isMoveOut && (
                      <div className={cx('label-container')}>
                        <SharedResponsibilityLabel isHomeowner={isHomeowner} side="bottom" />
                      </div>
                    )}
                    {showResidentResponsibilityTag && !inspectionType?.isMoveOut && (
                      <div className={cx('label-container')}>
                        <ResidentResponsibilityLabel side="bottom" />
                      </div>
                    )}
                    {extraTags && (
                      <>
                        {extraTags.map((tag, idx) => (
                          <div className={cx('tag-container')} key={idx}>
                            {tag}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                }
                borderRadius={false}
              />
            ))}
          </Carousel>
        )}
        <ModalLayout.Content>
          <Box px={['sm', 0]} pt={['xl', 0]}>
            <div className={cx('content')}>
              <div className={cx('side-borders')} />
              <Flex>
                <Flex flexDirection="column" flex={1}>
                  {showFlaggedTag && (
                    <div className={cx('lets-discuss', 'mb-2xs md:mb-xs')}>
                      <GeneralIcon icon={GENERAL_ICONS.ICON_FLAG} />
                      <Text className={cx('text')} fontWeight="semibold">
                        {POST_INSPECTION_FLOW_STRINGS['improvements.flag.text']}
                      </Text>
                    </div>
                  )}
                  <div className={cx('title-container')}>
                    <div>
                      <div className={cx('title')}>{maintenance.summary}</div>{' '}
                    </div>
                  </div>
                  <BulletList flexWrap listItems={[roomOrSpace, formattedClassification]} />
                </Flex>
              </Flex>
              <Space value={SPACE_TYPES.LG} />
              <div className={cx('information-grid')}>
                <Icon className={cx('icon')} icon={ICONS.DESCRIPTION.DEFAULT} />
                <Text className={cx('title')} fontWeight="semibold">
                  Description
                </Text>
                <Text className={cx('detail')}>{maintenance.description}</Text>
              </div>
              {from === IMPROVEMENT_FROM.IMPROVEMENT_REPORT && maintenance.whyReason ? (
                <>
                  <Space value={SPACE_TYPES.XL} />
                  <div className={cx('information-grid')}>
                    <Icon className={cx('icon')} icon={ICONS.FINANCIAL_PERFORMANCE.DEFAULT} />
                    <Text className={cx('title')} fontWeight="semibold">
                      {maintenance.assessment === MaintenanceAssessment.Required && 'Why This Improvement Matters'}
                    </Text>
                    <Text className={cx('detail')}>{maintenance.whyReason}</Text>
                  </div>
                  <Space value={SPACE_TYPES.SM} />
                </>
              ) : (
                <Space value={SPACE_TYPES.XS} />
              )}
            </div>

            {isEditable ? (
              <Form
                initialValues={getInitialValues()}
                bottomPosition="-20px"
                bottomPositionMobile="80px"
                rightPosition="280px"
                errorProps={{ bubbleTriangleClassNames: cx('error-bubble-triangle') }}
                onSubmit={async (values) => {
                  try {
                    const { isSelected } = values;
                    const formValues = { ...values };

                    delete formValues.isSelected;

                    const formChanged =
                      values.flagged !== homeownerPayment.flagged || values.notes !== homeownerPayment.notes;

                    if (formChanged) {
                      improvement.maintenancePayments = [{ ...values }];
                    }

                    if (improvement.maintenance.proServiceResponsibility !== values.proServiceResponsibility) {
                      improvement.maintenance.proServiceResponsibility = values.proServiceResponsibility;
                    }

                    const selectedOption = improvement.costOptions.find((x) => x.costId === currentOptionId);

                    let isImprovementSelected = isSelected;

                    if (maintenance.assessment === MaintenanceAssessment.Required) {
                      isImprovementSelected = true;
                    }

                    if (currentOptionId) {
                      const costOptionWithNewSelection = improvement.costOptions.map((costOption) => ({
                        ...costOption,
                        isSelected: costOption.costId === currentOptionId,
                      }));

                      improvement = {
                        ...improvement,
                        totalEstimatedCost: selectedOption.optionDisplayCost,
                        costOptions: costOptionWithNewSelection,
                        maintenance: {
                          ...improvement.maintenance,
                          estimatedCostId: currentOptionId,
                        },
                      };
                    }

                    await onChange(isImprovementSelected, [improvement]);

                    onHide();
                  } catch (err) {
                    console.error(err);
                  }
                }}
                getForm={({ form, values }) => {
                  const { isSelected } = values;

                  const isSubtaskPriceTableVisible =
                    (costOptions?.length > 1 && isSelected) || costOptions?.length === 1;

                  return (
                    <FormLayout>
                      <div className={cx('content')}>
                        <div
                          className={cx({
                            'side-borders': true,
                            'side-borders-extra-height': values.flagged,
                          })}
                        />
                        {costOptions?.length > 1 && isInChargeOfBelong && (
                          <BundleItemOptionList
                            currentOptionId={currentOptionId}
                            isImprovementSelected={isSelected}
                            onSelect={handleOptionSelect}
                            options={costOptions}
                            showPrice={showPriceBreakdown}
                          />
                        )}
                        {_showPriceBreakdown && isSubtaskPriceTableVisible && (
                          <Box mt="lg">
                            {displayAsPriceRange ? (
                              <div className="flex justify-between items-center rounded border-2 border-solid border-gray bg-light-gray px-sm py-lg">
                                <Text fontWeight="semibold">Pricing Details</Text>
                                <Text className="flex gap-2xs font-semibold">
                                  <MoneyRange
                                    lower={priceRange.lowerBoundSubtotal}
                                    upper={priceRange.upperBoundSubtotal}
                                    format="DOLLARS_NO_CENTS"
                                    className="font-semibold"
                                  />
                                </Text>
                              </div>
                            ) : (
                              <SubtaskPriceTable
                                currentOptionId={currentOptionId}
                                groupPayment={groupPayment}
                                isInvoiced={isInvoiced}
                                isReadOnly={!isEditable}
                                item={improvement}
                                rewards={rewards}
                                showResponsibilityPercentage={showResponsibilityPercentage}
                                showPriceBreakdown={showPriceBreakdown}
                                subtaskDueNow={subtaskDueNow}
                                displayDropdown={false}
                              />
                            )}
                          </Box>
                        )}

                        {isShowResponsibilitySelection && !maintenance.shouldPreventHomeownerFixing && (
                          <Field
                            name="proServiceResponsibility"
                            component={SelectorFinalFormAdapter}
                            type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
                            validate={required}
                            tagClassName="-top-xs mr-0 lg:mr-xs"
                            fluid
                            onChangeCustom={(value) => setOptionSelected(value)}
                            buttons={[
                              {
                                label: 'Do It Myself',
                                key: MaintenanceResponsibility.Homeowner,
                              },
                              {
                                label: 'Let Belong Handle It',
                                key: MaintenanceResponsibility.Belong,
                                tag: 'Recommended',
                              },
                            ]}
                          />
                        )}
                        <Space value={SPACE_TYPES.XL} />
                        {optionSelected === MaintenanceResponsibility.Homeowner && (
                          <div className="mb-3xl md:mb-xl -mt-sm p-sm rounded border border-solid bg-red-translucent-light border-red bg-opacity-70">
                            <p className="leading-p1">{`Belong cannot guarantee a list date when you chose to do this improvement work on your\u00a0own.`}</p>
                          </div>
                        )}
                        {isHomeowner && (
                          <div className={cx('checkbox-container')}>
                            <Field
                              alignCenter
                              firstSection
                              useOnChangeFormToHandle
                              name="flagged"
                              component={CheckboxFinalFormAdapter}
                              label="I WANT TO DISCUSS"
                              alignWithField={false}
                            />
                          </div>
                        )}
                        <Space
                          value={
                            maintenance.assessment === MaintenanceAssessment.Required ? SPACE_TYPES.LG : SPACE_TYPES.XL
                          }
                        />
                        <FormCondition
                          conditions={[
                            [
                              {
                                fieldName: 'flagged',
                                compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
                                fieldValue: true,
                              },
                            ],
                          ]}
                          formValues={form.getState().values}
                        >
                          <FormLayout.Section>
                            <Field
                              name="notes"
                              placeholder="Comments"
                              component={InputFinalFormAdapter}
                              validate={required}
                              textarea
                            />
                          </FormLayout.Section>
                        </FormCondition>
                      </div>
                    </FormLayout>
                  );
                }}
                getFormBottomBar={(_formProps, nextButtonProps) => {
                  const isRequired = maintenance.assessment === MaintenanceAssessment.Required;

                  const bottomBar = (
                    <Flex flexDirection="column" width="100%">
                      {isItemOptional && (
                        <Flex
                          alignItems={['center']}
                          className={cx('pricing', 'mb-xl md:mb-0')}
                          flexDirection={['column', 'row']}
                          justifyContent="space-between"
                          paddingX="sm"
                          paddingY="lg"
                          width="100%"
                        >
                          <Flex flexShrink={0}>
                            <Field
                              alignCenter
                              alignWithField={false}
                              component={CheckboxFinalFormAdapter}
                              disabled={isRequired}
                              firstSection
                              label="LET'S DO THIS"
                              name="isSelected"
                              useOnChangeFormToHandle
                            />
                          </Flex>
                        </Flex>
                      )}
                      <div
                        className={cx(
                          { 'md:pt-xl px-sm': isItemOptional },
                          'flex items-center justify-center w-full pb-2xl border-y-0 border-x md:border-solid border-dark-gray ml-0'
                        )}
                      >
                        <Button type="submit" {...nextButtonProps} className="font-semibold">
                          Save & Close
                        </Button>
                      </div>
                    </Flex>
                  );

                  return (
                    <ModalLayout.BottomBar
                      className={cx([
                        { 'bottom-bar-wrapper': isItemOptional },
                        'white-bottom-bar',
                        'border-y-0 border-x-0 border-b md:border-solid border-dark-gray',
                      ])}
                      fixedBottom
                      leftSideNode={<Flex />}
                      makeNode={bottomBar}
                      noButton
                      noPadding
                      removeMarginTopAndBorderBottomBar
                      reverseOrderOnMobile
                    />
                  );
                }}
              />
            ) : (
              <div className={cx(['empty-footer', 'empty-footer-large'])} />
            )}
          </Box>
        </ModalLayout.Content>
      </ModalLayout>
      <CarouselModal
        show={isModalOpen}
        carouselV2
        images={improvementMedia}
        onHide={setModalClose}
        index={currentSlideIndex}
        ignoreModalExitAndEnterFunctions={false}
        fullPage={!isMobile}
        autoplay
        closeButton
      />
    </ModalV2>
  );
};

ImprovementModal.propTypes = {
  improvement: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  isHomeowner: PropTypes.bool,
  rewards: PropTypes.array,
  inspectionType: PropTypes.object,
  groupPayment: PropTypes.array,
  isInvoiced: PropTypes.bool,
  extraTags: PropTypes.array,
  showPriceBreakdown: PropTypes.bool,
  showResponsibilityPercentage: PropTypes.bool,
  from: PropTypes.oneOf(Object.values(IMPROVEMENT_FROM)),
  onSave: PropTypes.func,
  isDoNotExceedGroup: PropTypes.bool,
};

ImprovementModal.defaultProps = {
  loading: false,
  onSubmit: null,
  isHomeowner: true,
  isInvoiced: false,
  showPriceBreakdown: false,
};

export default ImprovementModal;
