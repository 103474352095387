import { connectRouter } from 'connected-react-router';
import { leaseSigningFlowReducer } from 'lease-signing-flow/store';
import { LEASE_SIGNING_FLOW } from 'lease-signing-flow/store/key';
import accounts, { ACCOUNTS_REDUCER } from './accounts/reducer';
import errorHandling, { ERROR_REDUCER } from './errorHandling/reducer';
import experiments, { EXPERIMENTS_REDUCER } from './experiments';
import homeownerAccounts, { HOMEOWNER_ACCOUNTS_REDUCER } from './homeowner-accounts/reducer';
import homeownerOnboardingLeadsReducer, {
  HOMEOWNER_ONBARDING_LEADRS_REDUCER,
} from './homeowner-onboarding/leads-reducer';
import homeownerOnboarding, { HOMEOWNER_ONBARDING_REDUCER } from './homeowner-onboarding/reducer';
import homeOwnerSetUpFlow, { HOMEOWNER_SET_UP_FLOW_REDUCER } from './homeowner-set-up-flow/reducer';
import home, { HOME_REDUCER } from './homes/reducer';
import initialPrice, { INITIAL_PRICE_REDUCER } from './initial-price/reducer';
import inspections, { INSPECTION_REDUCER } from './inspections';
import listingpage, { LISTING_PAGE_REDUCER } from './listing-page-for-homes/reducer';
import maintenance, { MAINTENANCE_REDUCER } from './maintenance/reducer';
import modal, { MODAL_REDUCER } from './modals/reducer';
import pros, { PRO_REDUCER } from './pros/reducer';
import renterAccounts, { RENTAL_ACCOUNT_REDUCER } from './renter-accounts/reducer';
import residentOnboarding, { RESIDENT_ONBOARDING_REDUCER } from './resident-onboarding/reducer';
import settings, { SETTINGS_REDUCER } from './settings';
import toast, { TOAST_REDUCER } from './toast';
import { UI_REDUCER, uiReducers } from './ui/reducer';
import user, { USER_REDUCER } from './user/reducer';

export default function createReducers(history) {
  return {
    router: connectRouter(history),
    [RENTAL_ACCOUNT_REDUCER]: renterAccounts,
    [HOMEOWNER_ONBARDING_REDUCER]: homeownerOnboarding,
    [HOMEOWNER_ONBARDING_LEADRS_REDUCER]: homeownerOnboardingLeadsReducer,
    [HOMEOWNER_ACCOUNTS_REDUCER]: homeownerAccounts,
    [MAINTENANCE_REDUCER]: maintenance,
    [MODAL_REDUCER]: modal,
    [USER_REDUCER]: user,
    [HOME_REDUCER]: home,
    [UI_REDUCER]: uiReducers,
    [LISTING_PAGE_REDUCER]: listingpage,
    [PRO_REDUCER]: pros,
    [RESIDENT_ONBOARDING_REDUCER]: residentOnboarding,
    [ACCOUNTS_REDUCER]: accounts,
    [HOMEOWNER_SET_UP_FLOW_REDUCER]: homeOwnerSetUpFlow,
    [ERROR_REDUCER]: errorHandling,
    [TOAST_REDUCER]: toast,
    [SETTINGS_REDUCER]: settings,
    [EXPERIMENTS_REDUCER]: experiments,
    [INSPECTION_REDUCER]: inspections,
    [LEASE_SIGNING_FLOW]: leaseSigningFlowReducer,
    [INITIAL_PRICE_REDUCER]: initialPrice,
  };
}
