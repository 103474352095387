/* eslint-disable max-len */

export const RESIDENT_ONBOARDING_STRINGS = {
  personal_info: {
    title: "Let's get to know you better, {firstName}.",
    subTitle: 'In order to set up your account to pay rent, we first need some personal info.',
    is_name_legal_question: "Is '{firstName} {lastName}' your legal name?",
    is_name_legal_yes: "Yes, that's correct.",
    is_name_legal_no: "No, it's different.",
    legal_first_name: 'Legal first name',
    legal_middle_name: 'Legal middle name',
    legal_last_name: 'Legal last name',
    date_of_birth: 'Date of Birth (MM/DD/YYYY)',
    full_ssn_itin: 'Full SSN or ITIN',
    current_address: 'Current address',
    unit_number: 'Unit',
    same_mailing_address: 'This is also my mailing address.',
    where_to_send_title: 'Where can we send you stuff?',
    mailing_address: 'Mailing address',
    next_button_text: 'Next',
  },
  identity_verification: {
    title: "Let's make sure you're you.",
    subTitle: "In order to verify your identity, you'll be asked to take a picture of your ID and take a selfie.",
    verify_button: 'Verify My Identity',
    done_button: 'Done',
  },
  background_check: {
    title: 'Everyone applying to a Belong Home must pass a background check.',
    subtitle: 'All personal information is handled completely securely and confidentially.',
    ssn_input: 'SSN',
    no_ssn_checkbox: "I don't have an SSN.",
    no_ssn_section_title: "Don't have a social security number? On a student visa?",
    no_ssn_section_text:
      "No problem. We'll ask you for additional documentation later in order to verify your history.",
    background_pull_auth: 'I authorize Belong to do a full background check, including a criminal database check.',
    next_button_text: 'Next',
    tip: {
      description:
        "Speeding ticket? Don't worry about that. Belong's criminal background check looks into federal, state and local criminal databases for felonies and misdemeanors. But seriously, try not to speed.",
    },
  },
  success_page: {
    title: 'Thanks!',
    subTitle: "Now, let's set up your payment accounts to get you paid.",
    to_do_title: "To Do's",
    to_do: {
      one: 'ADD A PAYMENT METHOD FOR RENT',
      // two: 'ADD A PAYMENT METHOD FOR MAINTENANCE',
      two: 'COMPLETE YOUR PROFILE',
    },
    next_button_text: 'Go to Accounts',
  },
};
