import { Text } from '@belong/ui';
import config from 'config/config';
import GoogleMapReact, { Props as GoogleMapReactProps } from 'google-map-react';
import { isNil } from 'lodash-es';
import { Card } from './card/card';
import { ComparableMarker } from './markers/comparable-marker';
import { CurrentHomeMarker } from './markers/current-home-marker';
import { RentedMarker } from './markers/rented-marker';

type ComparablesMapProps = {
  setActive: (index?: number) => void;
  homes?: any[];
  hover?: number;
  active?: number;
  handleApiLoaded: (map: any) => void;
  gesture?: string;
  zoomControlPosition?: string;
  hasPointer?: boolean;
  clusters?: any;
  points?: any;
  onClusterClick: any;
} & GoogleMapReactProps;

export function ComparablesMap({
  gesture = 'greedy',
  zoomControlPosition = 'RIGHT_TOP',
  setActive,
  homes = [],
  hover,
  active,
  handleApiLoaded,
  clusters,
  points,
  onClusterClick,
  ...rest
}: ComparablesMapProps) {
  return (
    <>
      <GoogleMapReact
        {...rest}
        onGoogleApiLoaded={({ map }) => {
          handleApiLoaded(map);
        }}
        bootstrapURLKeys={{
          key: `https://maps.googleapis.com/maps/api/js?key=${config.googleMapsApiKey}&libraries=placesgeometry,drawing&language=en`,
        }}
        options={(e) => {
          return {
            gestureHandling: gesture,
            clickableIcons: false,
            fullscreenControl: false,
            zoomControlOptions: {
              position: e.ControlPosition[zoomControlPosition],
            },
            scrollwheel: false,
          };
        }}
        center={{
          lat: parseFloat(homes[0].address.coordinates?.latitude || 0),
          lng: parseFloat(homes[0].address.coordinates?.longitude || 0),
        }}
        zoom={8}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } = cluster.properties;

          if (isCluster) {
            return (
              <Marker key={`cluster-${cluster.id}`} lat={latitude} lng={longitude}>
                <div
                  className="bg-navy rounded-full text-white flex items-center justify-center p-xs"
                  style={{
                    width: `${10 + (pointCount / points.length) * 20}px`,
                    height: `${10 + (pointCount / points.length) * 20}px`,
                  }}
                  onClick={onClusterClick({ cluster, latitude, longitude })}
                >
                  <Text fontWeight="semibold">{pointCount}</Text>
                </div>
              </Marker>
            );
          } else {
            if (cluster.properties.home.current) {
              return (
                <CurrentHomeMarker
                  key={cluster.properties.homeId}
                  lat={latitude}
                  lng={longitude}
                  home={cluster.properties.home}
                  isActive={active === cluster.properties.homeId}
                  isHover={hover === cluster.properties.homeId}
                />
              );
            }

            if (cluster.properties.home.recentlyOffMarket) {
              return (
                <RentedMarker
                  key={cluster.properties.homeId}
                  lat={latitude}
                  lng={longitude}
                  home={cluster.properties.home}
                  isActive={active === cluster.properties.homeId}
                  isHover={hover === cluster.properties.homeId}
                />
              );
            }

            return (
              <ComparableMarker
                key={cluster.properties.homeId}
                lat={latitude}
                lng={longitude}
                home={cluster.properties.home}
                isActive={active === cluster.properties.homeId}
                isHover={hover === cluster.properties.homeId}
              />
            );
          }
        })}
      </GoogleMapReact>

      {!isNil(active) && <Card home={homes[active]} onHide={() => setActive(undefined)} />}
    </>
  );
}

const Marker = ({ children }: any) => children;
