import { useState } from 'react';
import Countdown from 'react-countdown';
import { useDispatch, useSelector } from 'react-redux';
import { useTransition, animated, config } from 'react-spring';
import { COOKIES_CONFIG } from '@belong/common';
import { Button as UIButton } from '@belong/ui';
import { useIsRoommateWaitingOnPrimarySign } from 'accounts/hooks/leases';
import { ResidentAccountSummaryItem } from 'api/models';
import classNames from 'classnames/bind';
import Button, { BUTTON_SIZES } from 'components/Button/Button';
import FullPageSpinner from 'components/FullPageSpinner/FullPageSpinner';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import MetaDescription from 'components/Metatags/MetaDescription';
import MetaTitle from 'components/Metatags/MetaTitle';
import Cover from 'components/v2/Cover/Cover';
import { SCREENS } from 'containercomponents/Modals/LoginModal/login-modal.consts';
import { MODALS } from 'containercomponents/Modals/modal.consts';
import { differenceInDays } from 'date-fns';
import { Text } from 'design-system';
import { legacyParse } from 'forkedlibraries/date-fns-upgrade';
import { Grid } from 'forkedlibraries/react-bootstrap';
import { useInterval } from 'hooks/useInterval';
import { useModal } from 'hooks/useModal';
import { useWindowSize } from 'hooks/useWindowSize';
import Page from 'layouts/Page/Page';
import { allowedSections, sectionMap } from 'lease-signing-flow/constants/sections';
import { getMostRecentLease } from 'lease-signing-flow/utils/lease';
import { LeaseStatus } from 'models/enums';
import Home from 'models/home/Home';
import { parseCookies } from 'nookies';
import { BASE_PATHS } from 'routes/paths';
import { showModal } from 'store/redux/modals/actions';
import { fetchResidentLeases } from 'store/redux/renter-accounts/actions';
import { selectIsUserLoggedIn } from 'store/redux/user/selectors';
import { formatString } from 'strings';
import { LEASE_SIGNING_FLOW_STRINGS as STRINGS } from 'strings/lease-signing-flow.strings';
import { meta } from 'strings/meta.strings';
import { THEME_CONSTS } from 'themes/themes';
import { padNumber } from 'utils/padNumber';
import { pluralize } from 'utils/pluralize';
import styles from './landing.module.css';

const getSection = (section) => {
  try {
    const splitSectionNameArray = section.split('-');
    if (splitSectionNameArray.length > 1) {
      // Id is always last element
      const sectionId = splitSectionNameArray.pop();

      // We have sections that have '-' in their names, like In-Law unit, so we have to join again after popping the id.
      const sectionName = splitSectionNameArray.join('-');
      return [sectionName, sectionId];
    }
    return splitSectionNameArray;
  } catch (e) {
    return [];
  }
};

const cx = classNames.bind(styles);

const fadeAnimationConfig = {
  from: { opacity: 0 },
  enter: { opacity: 1 },
  leave: { opacity: 0 },
  expires: false,
  config: config.molasses,
};

type Props = {
  home: Home;
  isCosigner: boolean;
  lease: ResidentAccountSummaryItem;
  onSubmit: () => void;
  onLandingPageLogin: (user: any) => void;
};

export const Landing = ({ home, isCosigner, onSubmit, onLandingPageLogin, lease }: Props) => {
  const [currentSection, setCurrentSection] = useState(0);
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const dispatch = useDispatch();
  const [isModalOpen] = useModal();
  const { height } = useWindowSize();
  const cookies = parseCookies();

  const comeFromTheMobileApp = Boolean(cookies[COOKIES_CONFIG.MOBILE_APP.name] === 'true');
  const vh = height * 0.01;
  const viewHeight = 100 * vh;

  // Open to sugestions on how to make this better.
  const minHeightToFitContent = 650;

  const minHeight = Math.max(viewHeight, minHeightToFitContent);

  const offerExpired =
    home.leaseOffered?.status === LeaseStatus.Voided || home.leaseOffered?.status === LeaseStatus.Executed;

  const primaryResident = lease?.leaseInfo?.residents.find(
    (resident) => resident?.leaseInfo?.residentType === 'Primary'
  );

  const isRoommateWaitingOnPrimarySign = useIsRoommateWaitingOnPrimarySign(lease?.leaseInfo?.residents);

  const isRenewal = home.leaseOffered?.renewalNumber > 0;

  const formattedSections = offerExpired
    ? []
    : home.mediaSectionNames?.reduce((acc, section) => {
        const [sectionName, sectionId] = getSection(section);

        if (!sectionName || !allowedSections.includes(sectionName)) {
          return acc;
        }

        const currentMedia = home.tourMedia?.find((media) => media.ownerId === sectionId);

        if (!currentMedia?.url) {
          return acc;
        }

        acc.push({
          sectionName: sectionMap[sectionName] || sectionName.toLowerCase(),
          src: currentMedia?.url,
        });

        return acc;
      }, []);

  // Always add home as first section with the banner as image
  formattedSections?.unshift({ sectionName: 'home', src: home.basicInfo?.bannerImageUrl });
  const formattedSectionsLength = formattedSections.length;

  const sectionTransitions = useTransition(formattedSections[currentSection], fadeAnimationConfig);

  const leaseStartOn = home.leaseOffered?.leaseStartOn;
  const daysUntilLeaseStarts = differenceInDays(legacyParse(leaseStartOn), legacyParse(new Date()));

  const getCoverText = (item) => {
    if (offerExpired) {
      return STRINGS['landing.cover.title-expired'];
    }

    if (isRenewal) {
      return STRINGS['landing.cover.title-renewal'];
    }

    return daysUntilLeaseStarts > 0
      ? formatString(STRINGS['landing.cover.title1'], {
          days: pluralize(daysUntilLeaseStarts, 'day'),
          sectionName: item.sectionName,
          your: isCosigner ? 'their' : 'your',
        })
      : formatString(STRINGS['landing.cover.title2'], {
          sectionName: item.sectionName,
          your: isCosigner ? 'their' : 'your',
        });
  };

  const handleSubmit = async () => {
    if (isUserLoggedIn) {
      return onSubmit();
    }

    return dispatch(
      showModal(MODALS.LOGIN, {
        currentScreen: SCREENS.LOGIN_SCREEN,
        onSucessfulLogin: async (userObject) => {
          const accountLeases = await dispatch(fetchResidentLeases());

          const mostRecentLeaseForHome = getMostRecentLease(accountLeases, home.basicInfo.unitId);
          const { residents } = mostRecentLeaseForHome?.leaseInfo || {};

          const currentResident = residents?.find(
            (resident) => resident.basicInfo.userInfo.userId === userObject.userId
          );

          // If we have lease object (user is logged in), and current user is not in the lase, throw error.
          if (!currentResident) {
            // eslint-disable-next-line no-throw-literal
            throw {
              loginError: `Hmmm… looks like this email address is not the one we have on file. Please use the one you signed up with, or contact us to update it first.`,
            };
          }

          onLandingPageLogin(userObject);
        },
      })
    );
  };

  useInterval(() => {
    setCurrentSection((sectionIndex) => (sectionIndex + 1) % formattedSectionsLength);
  }, 5000);

  if (isModalOpen) {
    return <FullPageSpinner />;
  }

  return (
    <Page
      footer={false}
      header={!comeFromTheMobileApp}
      headerMainProps={{
        borderOnScroll: false,
        elevated: true,
        hasborder: false,
        theme: THEME_CONSTS.DARK,
        transparent: true,
      }}
    >
      <MetaTitle
        title={formatString(meta['lease-signing'].title, {
          address: `${home?.address?.streetAddress}${
            home?.address?.unitNumber ? ` Unit ${home?.address?.unitNumber}` : ''
          }, ${home?.address?.city}`,
        })}
      />
      <MetaDescription description={meta['lease-signing'].description} />
      <div className={cx('content-layout')} style={{ minHeight: `calc(${minHeight}px - 60px)` }}>
        <div className={cx('cover-container')}>
          {sectionTransitions((style, item) => (
            <animated.div className={cx('fade-container')} style={style}>
              <Cover
                className={cx('cover-image', { 'slideshow-animation': !offerExpired })}
                containerClassName={cx('cover')}
                overlay={<div className={cx('cover-overlay')} />}
                opacity={offerExpired ? 0.7 : 0.3}
                src={item.src}
              >
                <div className={cx('cover-content')}>
                  <Text color="white" fontSize="h1" fontWeight="semibold" textAlign="center">
                    {getCoverText(item)}
                  </Text>
                </div>
              </Cover>
            </animated.div>
          ))}
        </div>
        <div className={cx('footer-container')}>
          <Grid>
            <div className={cx('footer')}>
              {!isRoommateWaitingOnPrimarySign ? (
                <div className={cx('countdown-container')}>
                  <GeneralIcon icon={GENERAL_ICONS.ICON_CLOCK} />
                  {offerExpired ? (
                    <Text color="white" fontWeight="semibold" ml="xs">
                      {STRINGS['landing.fotter.text-expired']}
                    </Text>
                  ) : (
                    <Countdown
                      date={new Date(home.leaseOffered?.offerExpiresOn)}
                      renderer={({ hours, minutes, seconds }) => (
                        <Text color="white" fontWeight="semibold" ml="xs">
                          {formatString(STRINGS['landing.footer.text'], {
                            time: `${padNumber(hours)}:${padNumber(minutes)}:${padNumber(seconds)}`,
                          })}
                        </Text>
                      )}
                    />
                  )}
                </div>
              ) : (
                <div className="flex-1" />
              )}

              <div className={cx('button-container')}>
                {offerExpired ? (
                  <Button size={BUTTON_SIZES.FLUID_LONG_RESPONSIVE} to={BASE_PATHS.HOME}>
                    {STRINGS['landing.footer.cta-expired']}
                  </Button>
                ) : isRoommateWaitingOnPrimarySign ? (
                  <UIButton size={{ _: 'fluid', md: 'auto' }} className="px-md" disabled>
                    {formatString(STRINGS['landing.footer.cta-waiting-primary'], {
                      primaryName: primaryResident.basicInfo.userInfo.firstName,
                    })}
                  </UIButton>
                ) : (
                  <Button onClick={handleSubmit} size={BUTTON_SIZES.FLUID_LONG_RESPONSIVE}>
                    {isRenewal
                      ? STRINGS['landing.footer.cta-renewal']
                      : formatString(STRINGS['landing.footer.cta'], {
                          my: isCosigner ? 'their' : 'my',
                        })}
                  </Button>
                )}
              </div>
            </div>
          </Grid>
        </div>
      </div>
    </Page>
  );
};
