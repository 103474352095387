import { useEffect } from 'react';
import { COOKIES_CONFIG } from '@belong/common';
import config from 'config/config';
import ENV from 'config/environments';
import { parseCookies } from 'nookies';

export function RedditPixel() {
  const cookies = parseCookies();
  const trackingId = cookies[COOKIES_CONFIG.TRACKING_ID.name];

  useEffect(() => {
    if (config.env === ENV.PRODUCTION && trackingId) {
      const script = document.createElement('script');
      script.innerHTML = `
        !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);
        rdt('init','${config.redditPixelId}');
        rdt('track', 'PageVisit', { externalId: '${trackingId}' });
      `;

      document.body.appendChild(script);
    }
  }, [trackingId]);

  return null;
}
