import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchUserVerifications,
  fetchUserIdentityVerification,
  createUserIdentityVerification,
} from 'store/redux/user/actions';
import {
  selectUserIdentityVerification,
  selectUserIsLoadingIdentityVerification,
  selectUserIsLoadingVerifications,
} from 'store/redux/user/selectors';

export function useUserIdentityVerification(userId?: number, shouldCreateVerificationIfNecessary?: boolean) {
  const { isLoadingVerifications, identityVerification, isLoadingIdentityVerification } = useSelector((state) => ({
    isLoadingVerifications: selectUserIsLoadingVerifications(state),
    identityVerification: selectUserIdentityVerification(state),
    isLoadingIdentityVerification: selectUserIsLoadingIdentityVerification(state),
  }));
  const [hasFetchedIdentityVerification, setHasFetchedIdentityVerification] = useState(!!identityVerification);

  const dispatch = useDispatch();
  const fetchIdentityVerification = useCallback(
    async (_userId: number) => {
      try {
        const verifications = await dispatch(fetchUserVerifications(_userId));
        const identityVerificationId = verifications?.find((v) => v.type === 'Identity')?.id;
        let _identityVerification = null;
        if (identityVerificationId) {
          _identityVerification = await dispatch(fetchUserIdentityVerification(identityVerificationId));
        }
        if (
          (!_identityVerification ||
            (_identityVerification?.status !== 'Passed' && !_identityVerification.sessionToken)) &&
          shouldCreateVerificationIfNecessary
        ) {
          _identityVerification = await dispatch(createUserIdentityVerification(_userId));
        }
      } catch (err) {
        // TODO: Handle Error
      } finally {
        setHasFetchedIdentityVerification(true);
      }
    },
    [dispatch, shouldCreateVerificationIfNecessary]
  );

  useEffect(() => {
    if (!!userId && !identityVerification) {
      fetchIdentityVerification(userId);
    }
  }, [userId, identityVerification, fetchIdentityVerification]);

  return {
    hasFetchedIdentityVerification,
    isLoadingIdentityVerification: isLoadingVerifications || isLoadingIdentityVerification,
    identityVerification,
    refetchIdentityVerification: () => dispatch(fetchUserIdentityVerification(identityVerification.verificationId)),
  };
}
