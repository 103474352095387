import { IconCheck } from '@belong/icons';
import { Text } from '@belong/ui';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';

function DepositDeductions() {
  return (
    <>
      <Text variant="h3" fontWeight="semibold" className="mb-lg">
        {POST_INSPECTION_FLOW_STRINGS['responsibility.deposit_deduction.title']}
      </Text>

      <div className="grid md:grid-cols-2 gap-2xs">
        {POST_INSPECTION_FLOW_STRINGS['responsibility.deposit_deduction.items'].map((item, index) => (
          <div key={index} className="flex">
            <IconCheck width={20} className="mr-sm text-green" />
            <Text>{item}</Text>
          </div>
        ))}
      </div>
    </>
  );
}

export default DepositDeductions;
