import { TourRequestModel } from 'api/models';

export const isTourRequestActive = (tourRequest: TourRequestModel) => {
  const activeTourRequestStatuses: TourRequestModel['status'][] = [
    'SchedulingInProgress',
    'Submitted',
    'UpdatedAvailability',
  ];

  return activeTourRequestStatuses.includes(tourRequest.status);
};
