import {
  PAYMENT_STEPS,
  PAYMENT_STEPS_DO_NOT_EXCEED_GROUP,
  PAYMENT_STEP_KEYS,
} from 'post-inspection/constants/improvements-report';

type Params = {
  isLegacyInvoicing?: boolean;
  inspectionType?: {
    isPreMoveOut: boolean;
    isOnboarding: boolean;
  };
  isDoNotExceedGroup: boolean;
};

type Result = {
  steps: typeof PAYMENT_STEPS;
};

export const useImprovementReportPaymentSteps = ({
  isLegacyInvoicing = false,
  inspectionType,
  isDoNotExceedGroup = false,
}: Params): Result => {
  if (isDoNotExceedGroup) {
    return { steps: PAYMENT_STEPS_DO_NOT_EXCEED_GROUP };
  }
  if (isLegacyInvoicing || inspectionType.isPreMoveOut) {
    return {
      steps: PAYMENT_STEPS.filter((paymentStep) => paymentStep.key === PAYMENT_STEP_KEYS.Summary),
    };
  }

  return {
    steps: PAYMENT_STEPS,
  };
};
