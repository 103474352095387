// TODO: Regions: Fix it.
import { getOfficeAddress } from 'consts/address';

export const HOME_KEYS_STRINGS = {
  title: ' Keys, please!',
  sub_title: 'What keys or remotes will we need for your home?',
  sub_title_new:
    'What keys or remotes will we need for your home? We will use these to inspect your home, and make copies so we can attend to emergencies.',
  done_label: 'Next Section',

  'features.houseKey': 'House Key',
  'features.frontDoor': 'Front Door',
  'features.backDoor': 'Back Door',
  'features.sideDoor': 'Side Door',
  'features.lobbyMainEntrance': 'Lobby',
  'features.gate': 'Gate',
  'features.garage': 'Garage',
  'features.mailbox': 'Mailbox',
  'features.mailroom': 'Mailroom',
  'features.storage': 'Storage',
  'features.other': 'Other',
  'features.communalAmenities': 'Communal Amenities',

  'delivery.title': 'How would you like to get them to us?',
  'delivery.keysTitle': 'How would you like to get the keys to us?',
  'delivery.mail': 'By Mail',
  'delivery.dropOff': 'Drop them off',
  'delivery.dropOffSubtext': "(To Belong's Office)",
  'delivery.uber': 'Uber Them',
  'delivery.uberSubtext': '(Free)',
  'delivery.inspection': 'In Person',
  'delivery.inspectionSubtext': '(During Inspection)',
  'delivery.inspectionTextBody': 'Great! We’ll reach out to schedule a time that works for you!',
  'delivery.lockbox': 'Lockbox',
  'delivery.homeownerLockbox': 'My Own Lockbox',
  'delivery.belongLockbox': 'Belong-Placed Lockbox',
  'delivery.belong': 'Belong',
  'delivery.other': 'Other',
  'delivery.mailTextBody': 'Great! Please send them to',
  'delivery.officeAddress': (region) => getOfficeAddress(region),
  'delivery.dropOffTextBody1': 'Great! Please drop by anytime during business hours <span>(M-F 9AM - 7PM)</span>',
  'delivery.dropOffTextBody2': 'We can’t wait to see you!',
  'delivery.uberTextBody': 'Great! We’ll reach out to schedule a time that works for you!',
  'delivery.belongLockboxBody':
    'We’ll place a lockbox in a secure location. Your Homeowner Advisor will be in touch to confirm the location and the code. A reminder, we can only place lockboxes Monday through Friday. If you need a lockbox same day lockbox, please reach out to your Homeowner Advisor.',
  'delivery.belong.lockbox':
    "Great! We'll send a lockbox to your home right away. Your Homeowner Advisor will be in touch to confirm the location and code for your lockbox.",
  'delivery.other.banner':
    'No worries! Your Homeowner Advisor will be in touch to confirm the best way to collect keys for your home.',
  'delivery.belongLockboxInstructions': 'Please tell us when there is a good day for us place the lockbox',
  'delivery.belongLockboxPlaceholder': 'Date (MM/DD/YY)',
  'delivery.lockboxTitle': 'How do we access this lockbox?',
  'delivery.lockboxAccessInstructions': 'Lockbox Location',
  'delivery.lockboxCode': 'Lockbox Code',
  'delivery.code': 'Code',
  'delivery.mailboxTitle': 'Tell us about your mailbox...',
  'delivery.mailboxAccessInstructions': 'Mailbox Location',
  'delivery.mailboxNumber': 'Mailbox Number',
  'delivery.mailboxIssueTitle': 'Any issues receiving mail or packages at your home?',
  'delivery.mailboxIssueYes': 'Yes, there are issues.',
  'delivery.mailboxIssueNo': 'No, no issues.',
  'delivery.mailboxIssueDetails': 'Mail Issues',
  'delivery.handItToUs': 'Hand it to us at the inspection',
  'delivery.lockboxLocation.title': 'Let us know, where is your lockbox placed?',
  'delivery.lockboxLocation.subtitle':
    'Share a photo of your lockbox placement so we can be sure we know where to look when we arrive at your home.',
  'delivery.dragFile': 'Drag file here...',
  'delivery.save': 'Save',
  'delivery.homeAccess.title': 'Home Access Instructions',
  'delivery.location': 'Location',
  'delivery.actionRequired': 'Action Required',
  'delivery.update': 'Update',

  'form.name': 'What does it open?',
  'form.type': 'Key Type',
  'form.keyDetailsKey': '# of Keys',
  'form.hasKeyKey': "I don't have it",
  'form.keyDetailsAccess': 'Access Code',
  'form.hasKeyAccess': "I don't know it",
  'form.keyDetailsFob': '# of Fobs',
  'form.hasKeyFob': "I don't have it",
  'form.keyDetailsRemote': '# of Remotes',
  'form.hasKeyRemote': "I don't have it.",
};
