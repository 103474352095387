import { useDispatch } from 'react-redux';
import { StepFormLayout } from 'accounts/components/step-form-layout';
import { INSURANCE_DOCUMENTS_OPTIONS } from 'accounts/constants/property-insurance';
import { PROPERTY_INSURANCE_STRINGS } from 'accounts/constants/strings/property-insurance.strings';
import { useSetupFlowStep } from 'accounts/hooks/homeowners/use-setup-flow-step-context';
import Field from 'components/Field/Field';
import GreenInfoBox from 'components/GreenInfoBox/GreenInfoBox';
import { MEDIA_LABEL, NewMediaUploadAdapter, validateMedia } from 'components/NewMediaUploader/NewMediaUploader';
import { SelectorFinalFormAdapter, SELECTOR_TYPES } from 'components/Selector/Selector';
import { YesNoFinalFormAdapter } from 'components/Selector/YesNoFinalFormAdapter/YesNoFinalFormAdapter';
import String from 'components/String/String';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import { Row, Col } from 'forkedlibraries/react-bootstrap';
import Condition from 'formcomponents/Condition/Condition';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { IMAGE_FILE_TYPE, StepStatus } from 'models/enums/index';
import { uploadFiles } from 'store/redux/images/actions';
import { getString } from 'strings/index';
import { required } from 'utils/validation';

export function PropertyInsurance() {
  const dispatch = useDispatch();
  const { currentStepFormData, currentStep, setLoading, handleSaveAndNext, goPreviousStep } = useSetupFlowStep();

  function getInitialValues() {
    if (!currentStepFormData) {
      return {};
    }

    const initialValues = {
      insuranceDetails: {},
      insuranceFiles: [],
      ...currentStepFormData,
    };

    if (initialValues.insuranceFiles?.length) {
      initialValues.insuranceDocuments = {
        availableMedia: [...initialValues.insuranceFiles],
        stagingMedia: [],
        deletedMedia: [],
        mediaToDisplay: [...initialValues.insuranceFiles],
        later: false,
      };
      initialValues.insuranceFiles = null;
    } else if (!initialValues.insuranceFiles?.length && currentStep?.status === StepStatus.Incomplete) {
      initialValues.insuranceDocuments = {
        availableMedia: [],
        stagingMedia: [],
        deletedMedia: [],
        mediaToDisplay: [],
        later: true,
      };
    }

    return initialValues;
  }

  async function handleSubmit(values) {
    let valuesToModify = { ...values };
    const { insuranceDocuments } = valuesToModify;

    setLoading(true);

    try {
      const insuranceFiles = await dispatch(uploadFiles(IMAGE_FILE_TYPE.Insurance, insuranceDocuments));

      valuesToModify.insuranceFiles = insuranceFiles;
    } catch (err) {
      console.error(err);
    }

    setLoading(false);

    if (valuesToModify.insuranceDetails.hasHomeownerInsurance === false) {
      valuesToModify = {
        insuranceDetails: {
          hasHomeownerInsurance: false,
        },
      };
    }

    await handleSaveAndNext(valuesToModify);
  }

  function handleInsuranceDocumentsLater(form) {
    return (value) => {
      if (value) {
        form.change('insuranceDocuments', {
          availableMedia: [],
          stagingMedia: [],
          deletedMedia: [],
          mediaToDisplay: [],
          later: true,
        });
      }
    };
  }

  function InsuranceRequirements() {
    return (
      <GreenInfoBox>
        <GreenInfoBox.Title>
          <String string={PROPERTY_INSURANCE_STRINGS['liability.no_condition_title']} />
        </GreenInfoBox.Title>
        <GreenInfoBox.List list={PROPERTY_INSURANCE_STRINGS['liability.no_condition_sections']} />
        <Space value={SPACE_TYPES.XS} />
        <GreenInfoBox.RegularText>
          <String string={PROPERTY_INSURANCE_STRINGS['liability.no_conditions_bottom_1']} />
        </GreenInfoBox.RegularText>
        <Space value={SPACE_TYPES.XS} />
        <GreenInfoBox.RegularText>
          <String string={PROPERTY_INSURANCE_STRINGS['liability.no_conditions_bottom_2']} />
        </GreenInfoBox.RegularText>
      </GreenInfoBox>
    );
  }

  return (
    <StepFormLayout
      onSubmit={handleSubmit}
      title={PROPERTY_INSURANCE_STRINGS.title}
      initialValues={getInitialValues()}
      showUnitAddress={false}
      disableProgressBar
      handleBackStep={goPreviousStep}
      getForm={({ form }) => (
        <>
          <FormLayout.Section>
            <Field
              validate={required}
              component={YesNoFinalFormAdapter}
              name="insuranceDetails.hasHomeownerInsurance"
            />
            <Condition when="insuranceDetails.hasHomeownerInsurance" is={false}>
              <FormLayout.Section>{InsuranceRequirements()}</FormLayout.Section>
            </Condition>
          </FormLayout.Section>
          <Condition when="insuranceDetails.hasHomeownerInsurance" is>
            <FormLayout.Section sectionTitle={getString(PROPERTY_INSURANCE_STRINGS['liability.title'])}>
              <Field
                validate={required}
                component={YesNoFinalFormAdapter}
                name="insuranceDetails.hasLiabilityCovered"
              />
              <Condition when="insuranceDetails.hasLiabilityCovered" is={false}>
                {InsuranceRequirements()}
              </Condition>
            </FormLayout.Section>
            <Condition when="insuranceDetails.hasLiabilityCovered" is>
              <FormLayout.Section sectionTitle={PROPERTY_INSURANCE_STRINGS['liability.interested_party.title']}>
                <Row>
                  <Col md={8}>
                    <Field
                      name="insuranceDetails.isBelongInterestedParty"
                      component={SelectorFinalFormAdapter}
                      validate={required}
                      buttons={[
                        {
                          label: PROPERTY_INSURANCE_STRINGS['liability.interested_party.custom_option_1'],
                          key: true,
                        },
                        {
                          label: PROPERTY_INSURANCE_STRINGS['liability.interested_party.custom_option_2'],
                          key: false,
                        },
                      ]}
                      fluid
                      type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
                    />
                  </Col>
                </Row>
                <Condition when="insuranceDetails.isBelongInterestedParty" is={false}>
                  <FormLayout.Section>
                    <GreenInfoBox useGreenBoxLineHeight>
                      <String string={PROPERTY_INSURANCE_STRINGS.not_added_belong} />
                    </GreenInfoBox>
                  </FormLayout.Section>
                </Condition>
              </FormLayout.Section>
              <Condition when="insuranceDetails.isBelongInterestedParty" is>
                <FormLayout.Section sectionTitle={PROPERTY_INSURANCE_STRINGS.upload_pdf}>
                  <Field
                    name="insuranceDocuments.later"
                    component={SelectorFinalFormAdapter}
                    validate={required}
                    buttons={INSURANCE_DOCUMENTS_OPTIONS}
                    type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
                    onChangeCustom={handleInsuranceDocumentsLater(form)}
                  />
                </FormLayout.Section>
                <Condition when="insuranceDocuments.later" is={false}>
                  <Field
                    name="insuranceDocuments"
                    validate={validateMedia}
                    component={NewMediaUploadAdapter}
                    mediaLabel={MEDIA_LABEL.INSURANCE}
                    disableWebcam
                  />
                </Condition>
              </Condition>
            </Condition>
          </Condition>
        </>
      )}
    />
  );
}
