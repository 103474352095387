import { isChecked } from '@belong/common';
import { ButtonBase } from '@belong/ui';
import { DisclosureFormField } from 'accounts/components/form-fields/disclosure';
import { StepFormLayout } from 'accounts/components/step-form-layout';
import { DISCLOSURES_LIST, IGNORE_COMMENT_FIELDS } from 'accounts/constants/home-disclosures';
import { HOME_DISCLOSURES_STRINGS } from 'accounts/constants/strings/home-disclosures.strings';
import { useSetupFlowStep } from 'accounts/hooks/homeowners/use-setup-flow-step-context';
import { CheckboxFinalFormAdapter } from 'components/Checkbox/Checkbox';
import Field from 'components/Field/Field';
import { MultiSelectableFinalFormAdapter } from 'components/MultiSelectable/MultiSelectable';
import { Row, Col } from 'forkedlibraries/react-bootstrap';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { Disclosures } from 'models/enums';
import { formatString } from 'strings';
import { addressAndUnit } from 'utils/formatAddress';

export function HomeDisclosures() {
  const { currentStep, currentStepFormData, selectedProperty, unitId, goPreviousStep, handleSaveAndNext, handleSave } =
    useSetupFlowStep();
  const address = currentStep?.property?.address || {};
  const { units } = selectedProperty || {};
  const currentUnitIndex = units?.findIndex((unit) => unit.basicInfo.unitId === unitId);
  const availableDisclosures = DISCLOSURES_LIST.filter((d) =>
    currentStepFormData?.availableDisclosures?.includes(d?.name)
  );
  const filteredDisclosuresList =
    currentStepFormData?.hasMortgage === false &&
    !currentStepFormData?.disclosures?.types?.[Disclosures.MortgageRequiresFloodInsurance]
      ? availableDisclosures.filter((d) => d.name !== Disclosures.MortgageRequiresFloodInsurance)
      : [...availableDisclosures];

  const doneLabel =
    units?.length > 1 && currentUnitIndex !== units?.length - 1 && !units?.[currentUnitIndex + 1]?.isFarOffMoveout
      ? HOME_DISCLOSURES_STRINGS['done_label.units']
      : HOME_DISCLOSURES_STRINGS.done_label;

  function getInitialValues() {
    if (!currentStepFormData) {
      return {};
    }

    const { disclosures = {} } = currentStepFormData;

    if (disclosures.types) {
      Object.keys(disclosures.types).forEach((type) => {
        disclosures.types[type].selected = true;
      });
    } else {
      disclosures.types = {};
    }

    return { disclosures };
  }

  const handleViewDocument = async (values) => {
    try {
      const { document } = await handleSave(values);

      if (document?.documentUrl) {
        window.open(document?.documentUrl, '_blank');
      }
    } catch (error) {
      console.error('Error viewing document:', error);
    }
  };

  async function handleSubmit(values) {
    Object.keys(values.disclosures.types).forEach((type) => {
      if (values.disclosures.types[type].selected === false) {
        delete values.disclosures.types[type];
      }
    });

    const { document } = await handleSave(values);

    if (document?.documentUrl) {
      document.isSigned = true;

      handleSaveAndNext({ document });
    }
  }

  return (
    <>
      <StepFormLayout
        handleBackStep={goPreviousStep}
        onSubmit={handleSubmit}
        title={HOME_DISCLOSURES_STRINGS.title}
        subTitle={formatString(HOME_DISCLOSURES_STRINGS.subtitle, {
          address: addressAndUnit(address, address?.unitNumber),
        })}
        doneLabel={doneLabel}
        initialValues={getInitialValues()}
        getForm={({ values, form }) => {
          return (
            <FormLayout.Section>
              <Row className="-mb-7xl md:mb-0">
                <Col md={12}>
                  <Field
                    component={MultiSelectableFinalFormAdapter}
                    name="disclosures.types"
                    showTrashButton={false}
                    showCheckmark
                    options={filteredDisclosuresList.map((item) => ({
                      ...item,
                      formFields: !IGNORE_COMMENT_FIELDS.includes(item.name) ? (
                        <DisclosureFormField item={item} />
                      ) : null,
                    }))}
                    form={form}
                  />
                  <div className="mb-xl md:-mb-md" />
                  <div className="md:mt-3xl fixed md:relative bottom-2xl bg-gradient-to-t from-white via-white/100 to-transparent pb-sm w-full md:pb-0 md:-mb-2xl">
                    <Field
                      component={CheckboxFinalFormAdapter}
                      name="isSigned"
                      className="uppercase -pt-xs leading-p1 text-p1 md:text-body"
                      form={form}
                      alignWithField={false}
                      label={HOME_DISCLOSURES_STRINGS.checkbox_label}
                      validate={isChecked}
                    />

                    <ButtonBase
                      type="button"
                      className="ml-lg uppercase font-semibold text-p1 md:text-body relative -top-2xs md:top-0"
                      onClick={() => handleViewDocument(values)}
                    >
                      {HOME_DISCLOSURES_STRINGS.view_form}
                    </ButtonBase>
                  </div>
                </Col>
              </Row>
            </FormLayout.Section>
          );
        }}
      />
    </>
  );
}
