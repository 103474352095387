import { Fragment, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { COOKIES_CONFIG, PATHS, useModal } from '@belong/common';
import { Text, Toast, Image } from '@belong/ui';
import { Fallback } from 'accounts/components/fallback';
import { MeetYourTeamSection } from 'accounts/components/meet-your-team-section';
import { MyHomeTimeline } from 'accounts/components/my-home-timeline/my-home-timeline';
import { SetupFlowSections } from 'accounts/components/setup-flow-sections';
import { PROPERTY_PLACEMAT_STRINGS } from 'accounts/constants/strings/placemat.strings';
import { SetupFlowProvider } from 'accounts/contexts/setup-flow';
import { useCheckPropertyIdParam } from 'accounts/hooks/homeowners/use-check-property-id-param';
import { useSelectedProperty } from 'accounts/hooks/homeowners/use-selected-property';
import { useSetupFlowSections } from 'accounts/hooks/homeowners/use-setup-flow-sections';
import { useSteps } from 'accounts/hooks/homeowners/use-steps';
import { useUnits } from 'accounts/hooks/homeowners/use-units';
import { isCurrentPropertyOrUnitStep } from 'accounts/utils/flow-steps';
import clsx from 'clsx';
import { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import IconButton, { ALIGN_TYPES } from 'components/IconButton/IconButton';
import Spinner from 'components/Spinner/Spinner';
import { HomeInfo } from 'components/home-info/home-info';
import LinkBase from 'corecomponents/LinkBase/LinkBase';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { FlowStatus, FlowType } from 'models/enums';
import { parseCookies } from 'nookies';
import InitialEstimateModal from 'pages/Accounts/Components/InitialEstimateModal/InitialEstimateModal';
import { QUERY_PARAMS } from 'pages/PostInspectionFlow/utils';
import { BASE_PATHS } from 'routes/paths';
import { selectAccountProperties } from 'store/redux/homeowner-accounts/selectors';
import { selectUser, selectUserFlows } from 'store/redux/user/selectors';
import { formatString } from 'strings';
import { formatAddress } from 'utils/formatAddress';
import styles from './setup-flow.module.css';

const { Title } = FormLayout;

export function HomeownersSetupFlowPage() {
  const properties = useSelector(selectAccountProperties);
  const flows = useSelector(selectUserFlows);
  const history = useHistory();
  const location = useLocation();
  const user = useSelector(selectUser);
  const { units, unitIds } = useUnits();
  const { selectedProperty } = useSelectedProperty();
  const [isToastOpen, setIsToastOpen] = useState(() => {
    const parsedURL = new URLSearchParams(location.search);
    const reportApprovedQueryParam = parsedURL.get(QUERY_PARAMS.REPORT_APPROVED);

    return reportApprovedQueryParam === 'true';
  });

  const setupFlows = flows?.filter((flow) => {
    if (
      flow.flowType === FlowType.HomeownerSetup ||
      flow.flowType === FlowType.HomeInspection ||
      flow.flowType === FlowType.HomeListing
    ) {
      const isCurrentPropertyOrUnit = flow.steps?.some((step) => isCurrentPropertyOrUnitStep(step, selectedProperty));
      return isCurrentPropertyOrUnit;
    }

    if (flow.flowType === FlowType.UserPayment) {
      return flow.steps?.some((step) => step.dataUniqueId === user?.userId);
    }

    return false;
  });

  const { steps, allStepsByGate } = useSteps({ setupFlows, properties });

  const { propertySection, accountSection, shouldShowCommonSteps } = useSetupFlowSections({
    properties,
    allStepsByGate,
    units,
  });

  const [isInitialModalOpen, openInitialModal, closeInitialModal] = useModal();
  const [selectedUnit, setSelectedUnit] = useState();
  const [selectedAddress, setSelectedAddress] = useState<string>();
  const [selectedAddressModel, setSelectedAddressModel] = useState<string>();

  const cookies = parseCookies();

  const comeFromTheMobileApp = Boolean(cookies[COOKIES_CONFIG.MOBILE_APP.name] === 'true');
  const isLoading = !selectedProperty;

  useCheckPropertyIdParam();

  function handleAddHomeClick() {
    history.push(PATHS.HOMEOWNER_ONBOARDING_PROPERTY_ADDRESS);
  }

  function getTitle() {
    const isAdoptedResident = selectedProperty?.units?.some((unit) => unit.isAdoptedAgreement);
    if (isAdoptedResident) {
      return PROPERTY_PLACEMAT_STRINGS['title.adopted_resident'];
    }

    return PROPERTY_PLACEMAT_STRINGS['title.not_adopted_resident_and_home_not_loved'];
  }

  useEffect(() => {
    if (selectedProperty && !selectedProperty.getUnitsWithAgreements().length) {
      history.push(BASE_PATHS.HOMEOWNER_SETUP_FLOW);
    }
  }, [history, selectedProperty]);

  function openModalWithUnit(unit, address) {
    const formattedAddress = formatAddress(address, unit?.basicInfo?.unitNumber);

    setSelectedUnit(unit);
    setSelectedAddress(formattedAddress);
    setSelectedAddressModel(address);
    openInitialModal();
  }

  function calculateFlowProgress(betterRenewalFlow) {
    if (!betterRenewalFlow) return 0;

    const missedPayments = betterRenewalFlow.steps.filter((step) => step.stepName === 'MissedPayments');
    if (missedPayments.isHidden) return 0;
    const flowSteps = betterRenewalFlow.steps.filter((step) => !step.isHidden);
    const completedSteps = flowSteps.filter((step) => step.status === FlowStatus.Completed);
    const progress = (completedSteps.length * 100) / flowSteps.length;
    return progress;
  }

  const flowSection: {
    progressPercentage?: number;
    disabled?: boolean;
    displayName?: string;
    reportLink?: string;
  } = {};

  const hasBetterRenewals = flows.find((flow) => flow.flowType === 'HomeownerRenewal');

  if (hasBetterRenewals) {
    flowSection.progressPercentage = calculateFlowProgress(hasBetterRenewals);
    flowSection.disabled =
      hasBetterRenewals.status === FlowStatus.Completed || hasBetterRenewals.status === FlowStatus.Cancelled;
    flowSection.displayName = 'Review Renewal Offer';
    flowSection.reportLink = `${PATHS.HO_BETTER_RENEWALS_FLOW}?flowId=${hasBetterRenewals.uniqueId}`;
  }

  function handleToastClose() {
    setIsToastOpen(false);
  }

  return (
    <SetupFlowProvider
      properties={properties}
      steps={steps}
      allStepsByGate={allStepsByGate}
      units={units}
      unitIds={unitIds}
      loading={isLoading}
    >
      <FormLayout>
        <header className="flex justify-between items-end flex-wrap-reverse md:flex-nowrap md:mb-[4rem]">
          <Title
            header
            title={getTitle()}
            className="pb-sm mb-sm border-b border-0 border-solid border-gray w-full md:pb-0 md:mb-0 md:border-0 md:w-auto"
          />
          {!comeFromTheMobileApp && (
            <div className="flex w-full justify-end mb-xl md:mb-0 md:w-auto md:self-end">
              <IconButton
                paddingCustomClassName="leading-[22px]"
                align={ALIGN_TYPES.RIGHT}
                onClick={handleAddHomeClick}
                label="ADD HOME"
                icon={GENERAL_ICONS.PLUS}
                generalIconProps={{
                  scale: 0.9,
                }}
              />
            </div>
          )}
        </header>
        {hasBetterRenewals && (
          <div className="mb-3xl">
            <div className="mb-sm">
              <Text variant="h3" fontWeight="semibold">
                Review Renewal Offer
              </Text>
            </div>
            <LinkBase
              disabled={flowSection.disabled}
              className={clsx(styles.flowCard, 'flex flex-wrap justify-between items-center relative', {
                'bg-green-light-bg': flowSection.progressPercentage === 100,
                'cursor-pointer': !flowSection.disabled,
              })}
              {...(!flowSection.disabled && {
                href: flowSection.reportLink,
              })}
            >
              <div
                className={clsx('font-semibold text-body flex items-center mr-sm', {
                  'text-dark-gray': flowSection.disabled,
                  'text-navy': !flowSection.disabled,
                })}
              >
                <Text fontWeight="semibold" className="mr-xs">
                  {formatString(flowSection.displayName)}
                </Text>
              </div>
              <div className="flex items-center mt-sm w-full md:w-auto md:mt-0">
                {flowSection.disabled ? (
                  <>
                    {flowSection.progressPercentage === 100 ? (
                      <>
                        <Text fontWeight="semibold" className="text-green">
                          100%&nbsp;
                        </Text>
                        <Text fontWeight="semibold" className="text-dark-gray">
                          Complete
                        </Text>
                      </>
                    ) : (
                      <Text fontWeight="semibold" className="text-dark-gray">
                        Not Ready
                      </Text>
                    )}
                  </>
                ) : (
                  <>
                    <Text
                      fontWeight="semibold"
                      className={clsx('mr-2xs', {
                        'text-green': flowSection.progressPercentage === 100,
                        'text-red': flowSection.progressPercentage < 100,
                      })}
                    >
                      {flowSection.progressPercentage}%
                    </Text>
                    <Text fontWeight="semibold">Complete</Text>
                  </>
                )}
                <IconButton disabled={flowSection.disabled} align={ALIGN_TYPES.LEFT} icon={GENERAL_ICONS.FRONT_ARROW} />
              </div>
            </LinkBase>
          </div>
        )}
        {isLoading ? (
          <Spinner />
        ) : (
          <Fragment>
            <ErrorBoundary FallbackComponent={Fallback}>
              <SetupFlowSections
                properties={properties}
                accountSection={accountSection}
                propertySection={propertySection}
                shouldShowCommonSteps={shouldShowCommonSteps}
                openInitialModal={openModalWithUnit}
              />
              <HomeInfo units={units} accountProperty={selectedProperty} />
              <MeetYourTeamSection />
              <MyHomeTimeline />
              <Space value={SPACE_TYPES.XXXL} />
            </ErrorBoundary>
          </Fragment>
        )}
      </FormLayout>
      <InitialEstimateModal
        todos={null}
        address={selectedAddress}
        unit={selectedUnit}
        isOpen={isInitialModalOpen}
        onDismiss={closeInitialModal}
        addressModel={selectedAddressModel}
      />
      <Toast
        icon={<Image alt="" className="object-scale-down" src="icons/alert-white.svg" />}
        isVisible={isToastOpen}
        onClose={handleToastClose}
        variant="danger"
      >
        {`You cannot modify an approved report. If you'd like to make changes, please contact your homeowner\u00a0advisor.`}
      </Toast>
    </SetupFlowProvider>
  );
}
