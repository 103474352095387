import { useState } from 'react';
import { IconForward } from '@belong/icons';
import { Text } from '@belong/ui';
import ShadowMediaCard from 'components/ShadowMediaCard/ShadowMediaCard';
import Carousel from 'components/v2/Carousel/Carousel';
import { useModal } from 'hooks/useModal';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import { WearAndTearModal } from '../WearAndTearModal/WearAndTearModal';
import { wearAndTearItems } from '../constants';

const wearAndTearItemsDesktop = [];

// Splits the wearAndTearItems array into smaller arrays of size 2, to arrange them in pairs.
for (let i = 0; i < wearAndTearItems.length; i += 2) {
  wearAndTearItemsDesktop.push(wearAndTearItems.slice(i, i + 2));
}

const WearAndTearItem = ({ item, onClick }) => (
  <ShadowMediaCard
    topLeft={<Text className="text-white">{item.title}</Text>}
    topRight={<IconForward className="h-2sm text-white" />}
    onClick={onClick}
    imageUrl={item.imageUrl}
    height={194}
  />
);

function WearAndTear() {
  const [isModalOpen, openModal, closeModal] = useModal();
  const [currentWearAndTear, setCurrentWearAndTear] = useState<number | undefined>();

  return (
    <div className="mb-3xl">
      <Text variant="h3" fontWeight="semibold" className="mb-xs">
        {POST_INSPECTION_FLOW_STRINGS['responsibility.wear_and_tear.title']}
      </Text>

      <Text className="mb-2xl">{POST_INSPECTION_FLOW_STRINGS['responsibility.wear_and_tear.body']}</Text>

      <div className="hidden md:block">
        {wearAndTearItemsDesktop.map((row, index) => {
          const [leftItem, rightItem] = row;
          const isLastRow = index === wearAndTearItemsDesktop.length - 1;

          return (
            <div className={`flex ${isLastRow ? 'mb-0' : 'mb-lg'}`} key={index}>
              <div className="flex-1 mr-lg">
                <WearAndTearItem
                  item={leftItem}
                  onClick={() => {
                    openModal();
                    setCurrentWearAndTear(2 * index);
                  }}
                />
              </div>
              <div className="flex-1">
                <WearAndTearItem
                  item={rightItem}
                  onClick={() => {
                    openModal();
                    setCurrentWearAndTear(2 * index + 1);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="md:hidden">
        <Carousel pagingDotsClassName="green">
          {wearAndTearItems.map((item, index) => {
            return (
              <WearAndTearItem
                key={index}
                item={item}
                onClick={() => {
                  openModal();
                  setCurrentWearAndTear(index);
                }}
              />
            );
          })}
        </Carousel>
      </div>

      {isModalOpen && (
        <WearAndTearModal initialValueIndex={currentWearAndTear} show={isModalOpen} onHide={closeModal} />
      )}
    </div>
  );
}

export default WearAndTear;
