import { useRef, useState, useCallback } from 'react';
import { useScript } from './use-script.js';

var PERSONA_CDN_SCRIPT_URL = 'https://cdn.withpersona.com/dist/persona-v4.8.0.js';
var ON_COMPLETE_SAFE_DELAY = 5000;
function usePersona(_a) {
  var onScriptLoad = _a.onScriptLoad,
    onScriptLoadError = _a.onScriptLoadError;
  var _b = useScript({
      src: PERSONA_CDN_SCRIPT_URL,
      onLoad: onScriptLoad,
      onLoadError: onScriptLoadError
    }),
    isLoadingScript = _b.isLoading,
    scriptLoadError = _b.loadError;
  var clientRef = useRef(null);
  var isReady = !isLoadingScript && !scriptLoadError;
  var _c = useState(false),
    isVerifying = _c[0],
    setIsVerifying = _c[1];
  var _d = useState(false),
    isWaitingSafeDelay = _d[0],
    setIsWaitingSafeDelay = _d[1];
  var startVerification = useCallback(function (_a) {
    var inquiryId = _a.inquiryId,
      sessionToken = _a.sessionToken,
      onComplete = _a.onComplete,
      onError = _a.onError,
      onCancel = _a.onCancel,
      onEvent = _a.onEvent,
      _b = _a.onCompleteSafeDelay,
      onCompleteSafeDelay = _b === void 0 ? ON_COMPLETE_SAFE_DELAY : _b;
    if (!isReady) {
      // TODO: HANDLE
      return;
    }
    if (!inquiryId) {
      // TODO: HANDLE
      return;
    }
    if (clientRef.current) {
      clientRef.current.destroy();
      clientRef.current = null;
    }
    if (!!window.Persona) {
      setIsVerifying(true);
      // @ts-ignore:next-line
      clientRef.current = new window.Persona.Client({
        inquiryId: inquiryId,
        sessionToken: sessionToken,
        onReady: function () {
          var _a;
          (_a = clientRef.current) === null || _a === void 0 ? void 0 : _a.open();
        },
        onComplete: function (param) {
          setIsVerifying(false);
          setIsWaitingSafeDelay(true);
          setTimeout(function () {
            onComplete(param);
            setIsWaitingSafeDelay(false);
          }, onCompleteSafeDelay);
        },
        onError: function (param) {
          setIsVerifying(false);
          onError === null || onError === void 0 ? void 0 : onError(param);
        },
        onCancel: function (param) {
          setIsVerifying(false);
          onCancel === null || onCancel === void 0 ? void 0 : onCancel(param);
        },
        onEvent: function (param) {
          onEvent === null || onEvent === void 0 ? void 0 : onEvent(param);
        }
      });
    }
  }, [isReady]);
  return {
    isLoadingPersona: isLoadingScript,
    isVerifying: isVerifying,
    isWaitingSafeDelay: isWaitingSafeDelay,
    startVerification: startVerification
  };
}

export { usePersona };
