import { Component } from 'react';
import { PATHS, DATE_FORMATS, formatUtcDateTimeToTimeZone } from '@belong/common';
import { BelongProductLogo } from '@belong/ui';
import String from 'components/String/String';
import { capitalize } from 'es-toolkit';
import { Milestone } from 'models/enums/typings';
import HomeownerAccountProperty from 'models/homeownerAccounts/HomeownerAccountProperty';
import { getHomeownerQualificationFlowPath } from 'pages/Accounts/Pages/YourPortfolio/Properties/Properties.utils';
import PropTypes from 'prop-types';
import { formatAddress } from 'utils/formatAddress';
import ColoredText, { COLORS } from '../../components/ColoredText/ColoredText';
import Icon, { ICONS } from '../../components/Icon/Icon';
import AccountProperty from '../../containercomponents/AccountProperty/AccountProperty';
import { UnitStatus } from '../../models/enums';
import { HOMEOWNER_ONBOARDING_MODES } from '../../pages/HomeOwnerOnboarding/constants';
import { pluralize } from '../../utils/pluralize';

class HomeownerAccountPropertyContainer extends Component {
  renderPlanTypeTag() {
    const { showPROHomeTag } = this.props;
    if (showPROHomeTag) {
      return <BelongProductLogo variant="PRO" />;
    }
  }

  renderTag() {
    const { accountProperty } = this.props;

    if (!accountProperty.emptyAvailableUnits().length) {
      return accountProperty.units.length === 1 ? 'Rented' : 'Renting all units';
    }

    return (
      <>
        {this.renderPlanTypeTag()}
        <ColoredText
          text={`Looking for ${pluralize(accountProperty.emptyAvailableUnits()?.length ?? '', 'resident', capitalize)}`}
        />
      </>
    );
  }

  renderTitle() {
    const { accountProperty, status, isPublished } = this.props;
    const units = isPublished ? accountProperty.getPublishedUnits() : accountProperty.getUnitsByStatus(status);
    const numberOfUnits = units.length;
    let hasUnits = '';

    if (numberOfUnits > 1) {
      hasUnits = `<span> • </span><span>${pluralize(numberOfUnits ?? '', 'unit', capitalize)}</span>`;
    } else {
      const unit = units[0];

      if (unit?.basicInfo?.unitNumber) {
        hasUnits = `<span> Unit ${unit.basicInfo.unitNumber}</span>`;
      }
    }

    const getString = `${accountProperty.address.streetAddress}${hasUnits}`;
    return (
      <span>
        <String string={getString} />
      </span>
    );
  }

  renderSubtitles() {
    const { accountProperty } = this.props;
    const subtitles = [];
    const totalResidents = accountProperty.totalResidents() || 0;

    if (accountProperty.availableUnits().length) {
      subtitles.push(`${pluralize(totalResidents ?? '', 'resident', capitalize)} Currently`);
    }

    return subtitles;
  }

  renderProfiles() {
    const { accountProperty } = this.props;
    const unitProps = [];
    const totalResidents = accountProperty.totalResidents() || 0;
    let hasResidents = false;

    accountProperty.availableUnits().forEach((unit) => {
      const { residents = [], kids = [], pets = [] } = unit || {};
      hasResidents = [...residents, ...kids, ...pets].length > 0;

      unitProps.push({
        ...unit,
        label: unit.basicInfo.unitNumber,
      });
    });

    return {
      totalResidents,
      hasResidents,
      unitProps,
    };
  }

  renderPublishedUnits() {
    const { accountProperty } = this.props;
    const units = accountProperty.getPublishedUnits();

    if (!units.length) {
      return null;
    }

    return (
      <AccountProperty
        to={`${PATHS.HOMEOWNERS_ACCOUNT_PROPERTIES}/${accountProperty.basicInfo.propertyId}`}
        miniImageCardProps={{
          tag: this.renderTag(),
          title: this.renderTitle(),
          subtitles: this.renderSubtitles(),
          imageUrl: units[0].basicInfo.bannerImageUrl,
        }}
        unitProps={this.renderProfiles()}
      />
    );
  }

  renderInspectionCompletedUnits() {
    const { accountProperty } = this.props;

    if (!accountProperty.getUnitsByStatus(UnitStatus.InspectionCompleted).length) {
      return null;
    }

    return (
      <AccountProperty
        to={`${PATHS.HOMEOWNERS_ACCOUNT_PROPERTIES}/${accountProperty.basicInfo.propertyId}`}
        hover={false}
        miniImageCardProps={{
          tag: <ColoredText text="Inspection Completed" color={COLORS.GREEN} />,
          title: this.renderTitle(),
          image: <Icon icon={ICONS.SINGLE_PROPERTY.GRAY} />,
        }}
      />
    );
  }

  renderInspectionScheduledUnits() {
    const { accountProperty } = this.props;

    const inspectionScheduledUnits = accountProperty.getUnitsByStatus(UnitStatus.InspectionScheduled);

    if (!inspectionScheduledUnits.length) {
      return null;
    }

    let inspectionStatus = 'Scheduled';
    let finishingCopy = '';

    if (inspectionScheduledUnits[0].basicInfo.currentMilestone !== Milestone.InspectionScheduled) {
      inspectionStatus = 'Reserved';
    }

    if (inspectionScheduledUnits[0].basicInfo.currentMilestone === Milestone.AgreementSigned) {
      finishingCopy = 'Pending Complimentary Inspection Details';
    }

    const {
      basicInfo: { timeZone },
    } = inspectionScheduledUnits[0];

    return (
      <AccountProperty
        to={`${PATHS.HOMEOWNERS_ACCOUNT_PROPERTIES}/${accountProperty.basicInfo.propertyId}`}
        hover={false}
        miniImageCardProps={{
          tag: (
            <span>
              {this.renderPlanTypeTag()}
              <ColoredText
                text={
                  <String
                    string={`
                      Inspection ${inspectionStatus} <span> | </span>
                      <span>
                        ${formatUtcDateTimeToTimeZone({
                          dateTime: inspectionScheduledUnits[0].inspectionScheduledOn,
                          format: DATE_FORMATS.DEFAULT_DATE_TIME,
                          timeZone,
                        })}
                      </span>
                      <span>${finishingCopy}</span>
                    `}
                  />
                }
                color={inspectionStatus === 'Reserved' ? COLORS.ORANGE : COLORS.GREEN}
              />
            </span>
          ),
          title: this.renderTitle(),
          image: <Icon icon={ICONS.SINGLE_PROPERTY.GRAY} />,
        }}
      />
    );
  }

  renderInspectionPendingUnits() {
    const { accountProperty } = this.props;
    const unitProps = accountProperty.getUnitsByStatus(UnitStatus.InspectionPending);
    const totalResidents = accountProperty.totalResidents() || 0;
    let hasResidents = false;

    accountProperty.availableUnits().forEach((unit) => {
      const { residents = [], kids = [], pets = [] } = unit || {};
      hasResidents = [...residents, ...kids, ...pets].length > 0;
    });

    const dataObject = {
      totalResidents,
      hasResidents,
      unitProps,
    };

    if (!dataObject?.unitProps.length) {
      return null;
    }

    let to;
    // We need to ignore published agreements on this case, otherwise we would always redirect when adding a new property.
    if (accountProperty.getUnpublishedUnitsWithAgreements().length > 0) {
      to = `${PATHS.HOMEOWNERS_ACCOUNT_PROPERTIES}/${accountProperty.basicInfo.propertyId}`;
    }

    const formattedAddress = formatAddress(accountProperty?.address, accountProperty?.address?.unitNumber);

    const tagText =
      unitProps[0].basicInfo.currentMilestone === Milestone.None ? 'Pending Invitation' : 'Inspection Pending';

    return (
      <AccountProperty
        to={to}
        hover={Boolean(to)}
        unitProps={dataObject}
        propertyAddress={formattedAddress}
        miniImageCardProps={{
          tag: (
            <>
              {this.renderPlanTypeTag()}
              <ColoredText text={tagText} color={COLORS.RED} />
            </>
          ),
          title: this.renderTitle(),
          image: <Icon icon={ICONS.SINGLE_PROPERTY.GRAY} />,
        }}
      />
    );
  }

  renderApplicationPendingUnits() {
    const { accountProperty } = this.props;
    const applicationPendingUnits = accountProperty.getUnitsByStatus(UnitStatus.ApplicationPending);

    if (!applicationPendingUnits.length) {
      return null;
    }

    return (
      <AccountProperty
        to={`/homeowner/onboard?mode=${HOMEOWNER_ONBOARDING_MODES.RESUME}`}
        hover
        miniImageCardProps={{
          tag: (
            <>
              {this.renderPlanTypeTag()}
              <ColoredText text="Update Info" color={COLORS.DARKGRAY} />
            </>
          ),
          title: this.renderTitle(),
          image: <Icon icon={ICONS.SINGLE_PROPERTY.GRAY} />,
        }}
      />
    );
  }

  render() {
    const { status, isPublished, accountProperty, flows, isLeasingServiceProperty } = this.props;

    if (isPublished) {
      return this.renderPublishedUnits();
    }

    if (isLeasingServiceProperty) {
      return accountProperty.units.map((unit) => {
        const homeownerLeasingQualificationFlow = flows.find(
          (flow) => flow.steps[0].dataUniqueId === unit.basicInfo.unitId
        );
        const unitNumber = unit?.basicInfo?.unitNumber ? ` Unit ${unit.basicInfo.unitNumber}` : '';
        const placementsFlowHref = getHomeownerQualificationFlowPath(homeownerLeasingQualificationFlow);
        return (
          <div className="mb-xl" key={unit.basicInfo.unitId}>
            <AccountProperty
              to={placementsFlowHref}
              hover
              miniImageCardProps={{
                tag: (
                  <>
                    <BelongProductLogo variant="Leasing" className="mb-2xs" />
                    <ColoredText text="Incomplete" color={COLORS.DARKGRAY} />
                  </>
                ),
                title: `${accountProperty.address.streetAddress}${unitNumber}`,
                image: <Icon icon={ICONS.SINGLE_PROPERTY.GRAY} />,
              }}
              footerLink={{
                label: "LET'S COMPLETE",
                href: placementsFlowHref,
              }}
            />
          </div>
        );
      });
    }

    switch (status) {
      case UnitStatus.InspectionCompleted:
        return this.renderInspectionCompletedUnits();
      case UnitStatus.InspectionScheduled:
        return this.renderInspectionScheduledUnits();
      case UnitStatus.InspectionPending:
        return this.renderInspectionPendingUnits();
      case UnitStatus.ApplicationPending:
        return this.renderApplicationPendingUnits();
      default:
        return null;
    }
  }
}

HomeownerAccountPropertyContainer.propTypes = {
  accountProperty: PropTypes.instanceOf(HomeownerAccountProperty).isRequired,
  status: PropTypes.oneOf(Object.values(UnitStatus)),
  isPublished: PropTypes.bool,
  isLeasingServiceProperty: PropTypes.bool,
  flows: PropTypes.array,
  showPROHomeTag: PropTypes.bool,
};

HomeownerAccountPropertyContainer.defaultProps = {
  status: null,
  isPublished: false,
  isLeasingServiceProperty: false,
  flows: [],
  showPROHomeTag: false,
};

export default HomeownerAccountPropertyContainer;
