import { PAYMENTS_FEE_CONST } from '@belong/common';
import { CreditCardBasicInfo, TransferInfoModel } from 'api/models';
import { FeeOptions } from 'models/enums/index';
import { GENERAL_ICONS } from '../../components/GeneralIcon/GeneralIcon';
import { CardType } from '../enums';

const PAYMENT_METHOD_MAX_LENGTH = {
  SHORT: 'SHORT',
  MEDIUM: 'MEDIUM',
  FULL: 'FULL',
} as const;

export type PaymentMethodMaxLength = typeof PAYMENT_METHOD_MAX_LENGTH[keyof typeof PAYMENT_METHOD_MAX_LENGTH];

type PaymentMethodType = TransferInfoModel['paymentMethod'];
type CardType = CreditCardBasicInfo['cardType'];

export const paymentMethodSubStringConverter = (string: string, length: PaymentMethodMaxLength) => {
  if (!string) {
    return null;
  }

  // Difference of 3 characters to only use ... when necessary.
  if (length === PAYMENT_METHOD_MAX_LENGTH.SHORT) {
    if (string.length < 15) {
      return string;
    }

    return string.substring(0, 12).concat('...');
  } else if (length === PAYMENT_METHOD_MAX_LENGTH.MEDIUM) {
    if (string.length < 21) {
      return string;
    }

    return string.substring(0, 17).concat('...');
  }

  return string;
};

export const getPaymentMethodName = (
  {
    paymentMethod,
    accountLastDigits,
    referenceNumber,
    bankName,
    otherPaymentType,
  }: {
    paymentMethod: PaymentMethodType;
    accountLastDigits: string;
    referenceNumber: string;
    bankName: string;
    otherPaymentType: string;
  },
  lengthOfAccountName = PAYMENT_METHOD_MAX_LENGTH.MEDIUM
) => {
  if (paymentMethod === 'Ach') {
    return accountLastDigits
      ? `${paymentMethodSubStringConverter(bankName, lengthOfAccountName) || 'Account'} Ending ${accountLastDigits}`
      : 'Add Bank Account';
  } else if (paymentMethod === 'Check') {
    return `Check #${referenceNumber}`;
  } else if (paymentMethod === 'CreditCard') {
    return `Card Ending ${accountLastDigits}`;
  } else if (paymentMethod === 'WireTransfer') {
    return `Wire Transfer #${referenceNumber}`;
  } else if (paymentMethod === 'PayPal') {
    return `PayPal Transfer #${referenceNumber}`;
  } else if (paymentMethod === 'Other') {
    return `${otherPaymentType || paymentMethod} ${referenceNumber ? ` #${referenceNumber}` : ''}`;
  }
};

export const getPaymentMethodIcon = (paymentMethod: PaymentMethodType = 'Ach', paymentMethodType: CardType) => {
  if (paymentMethod === 'Ach') {
    return GENERAL_ICONS.ACH_ACCOUNT;
  } else if (paymentMethod === 'CreditCard') {
    switch (paymentMethodType) {
      case CardType.Visa:
        return GENERAL_ICONS.VISA;
      case CardType.MasterCard:
        return GENERAL_ICONS.MASTER_CARD;
      case CardType.Maestro:
        return GENERAL_ICONS.MAESTRO;
      case CardType.Cirrus:
        return GENERAL_ICONS.CIRRUS;
      case CardType.AmericanExpress:
        return GENERAL_ICONS.AMERICAN_EXPRESS;
      case CardType.Discover:
        return GENERAL_ICONS.DISCOVER;
      case CardType.JCB:
        return GENERAL_ICONS.JCB;
      case CardType.UnionPay:
        return GENERAL_ICONS.UNION_PAY;
      case CardType.DinersClub:
        return GENERAL_ICONS.DINERS_CLUB;
      default:
        return GENERAL_ICONS.CREDIT_CARD;
    }
  } else if (paymentMethod === 'WireTransfer') {
    return GENERAL_ICONS.WIRE_TRANSFER;
  } else if (paymentMethod === 'PayPal') {
    return GENERAL_ICONS.PAYPAL;
  } else if (paymentMethod === 'Check') {
    return GENERAL_ICONS.CHECK;
  } else {
    return GENERAL_ICONS.OTHER_PAYMENTS;
  }
};

export const getFeeFromPaymentMethod = (paymentDetails, hasFee) => {
  if (!hasFee) {
    return 'Free';
  }

  if (typeof paymentDetails === 'object') {
    if (paymentDetails.paymentMethod === 'CreditCard') {
      if (paymentDetails.isDomestic) {
        return `${PAYMENTS_FEE_CONST.CREDIT_CARD_FEE} Fee`;
      }
      return `${PAYMENTS_FEE_CONST.INTERNATIONAL_CARD_FEE} Fee`;
    }
  }
  if (typeof paymentDetails === 'string') {
    if (paymentDetails === 'CreditCard') {
      return `${PAYMENTS_FEE_CONST.CREDIT_CARD_FEE} Fee`;
    }
  }

  return 'Free';
};

export const getTypeofFeeSymbol = (typeOfFee) => {
  if (typeOfFee === FeeOptions.Percentage) {
    return '%';
  } else if (typeOfFee === FeeOptions.FlatFee) {
    return '$';
  }

  return '';
};

export const getFeeFromPaymentInfo = (fee, typeOfFee) => {
  if (fee) {
    return `${fee}${getTypeofFeeSymbol(typeOfFee)}`;
  }

  return '';
};
