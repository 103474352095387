import { Image, Text } from '@belong/ui';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';

function WearAndTearVsResidentDamage() {
  return (
    <>
      <Text variant="h3" fontWeight="semibold" className="mt-md md:mt-0 mb-lg">
        {POST_INSPECTION_FLOW_STRINGS['responsibility.wear_and_tear_vs_resident_damage.title']}
      </Text>

      <div className="flex flex-col md:flex-row gap-md mb-3xl">
        <div className="flex flex-col">
          <Image className="mb-xs" src="/improvement-reports/wear_and_tear_01.svg" alt="Resident Responsibilities" />
          <Text className="mb-2xs font-semibold">
            {POST_INSPECTION_FLOW_STRINGS['responsibility.wear_and_tear_vs_resident_damage.resident.title']}
          </Text>
          <Text variant="p1" className="leading-p2">
            {POST_INSPECTION_FLOW_STRINGS['responsibility.wear_and_tear_vs_resident_damage.resident.copy']}
          </Text>
        </div>

        <div className="flex flex-col">
          <Image className="mb-xs" src="/improvement-reports/wear_and_tear_02.svg" alt="Homeowner Responsibilities" />
          <Text className="mb-2xs font-semibold">
            {POST_INSPECTION_FLOW_STRINGS['responsibility.wear_and_tear_vs_resident_damage.homeowner.title']}
          </Text>
          <Text variant="p1" className="leading-p2">
            {POST_INSPECTION_FLOW_STRINGS['responsibility.wear_and_tear_vs_resident_damage.homeowner.copy']}
          </Text>
        </div>
      </div>
    </>
  );
}

export default WearAndTearVsResidentDamage;
