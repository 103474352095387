import { useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { useDispatch } from 'react-redux';
import { Text, Toast } from '@belong/ui';
import { StepFormLayout } from 'accounts/components/step-form-layout';
import { USER_TAXES_STRINGS } from 'accounts/constants/strings/user-taxes.strings';
import { useSetupFlowStep } from 'accounts/hooks/homeowners/use-setup-flow-step-context';
import type { HomeownerSetupTaxesDocumentationModel, HomeownerSetupDocumentationSignatureModel } from 'api/models';
import { DropdownFinalFormAdapter } from 'components/Dropdown/Dropdown';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { maskSSN, unmaskSSN, maskEIN, unmaskEIN } from 'components/Input/masks';
import Tooltip from 'components/Tooltip/Tooltip';
import {
  businessEntities,
  llcTaxClassifications,
} from 'containercomponents/Modals/ModalListOfItems/ApplianceList/listOfFormObjects';
import AddressField from 'fields/StandardFields/AddressField/AddressField';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import Condition from 'formcomponents/Condition/Condition';
import { useModal } from 'hooks/useModal';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { BusinessEntityTypes, LLCTaxClassification } from 'models/enums';
import { updateLegalIdentityInformation } from 'store/redux/user/actions';
import { formatString } from 'strings';
import {
  composeValidators,
  required,
  ssnValidation,
  einValidation,
  ssnValidationFirstTime,
  maxLength,
} from 'utils/validation';
import { UserPaymentW9 } from '../user-payment-w9/user-payment-w9';

export function UserTaxes() {
  const { currentStepFormData, handleSave, goPreviousStep, goNextStep } = useSetupFlowStep();
  const [isModalOpen, setModalOpen, setModalClose] = useModal();
  const [showSSNError, setShowSSNError] = useState(false);
  const [isErrorToastVisible, setIsErrorToastVisible] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [unsignedDocument, setUnsignedDocuments] = useState<HomeownerSetupDocumentationSignatureModel>();
  const dispatch = useDispatch();

  function getInitialValues() {
    if (!currentStepFormData) {
      return {};
    }

    const { data } = currentStepFormData || {};
    const formData: {
      user?: {
        tin?: {
          tin?: string;
          tinLastDigits?: string;
        };
      };
      entities?: unknown[];
    } = {};

    formData.user = data?.user;
    formData.entities = data?.entities;

    if (data?.user?.tin?.tinLastDigits) {
      formData.user.tin.tin = `xxx-xx-${data?.user?.tin?.tinLastDigits}`;
    }

    return { ...formData };
  }

  async function handleSubmit(values) {
    setShowSSNError(false);
    setIsErrorToastVisible(false);

    const entitiesWithTINType = values.entities.map((entity) => {
      return {
        ...entity,
        tin: {
          ...entity.tin,
          tinType: entity.entityType === 'Business' ? 'Ein' : 'Ssn',
        },
      };
    });

    const isExistingTIN = /^xxx-xx-?[0-9]{4}$/i.test(values.user?.tin.tin);

    try {
      if (values.user.tin.tin && !isExistingTIN) {
        await dispatch(
          updateLegalIdentityInformation({
            ssn: values.user.tin.tin,
            tinType: 'Ssn',
          })
        );
      }

      if (isExistingTIN) {
        delete values.user.tin;
      }

      const { w9 }: Record<'w9', HomeownerSetupTaxesDocumentationModel> = await handleSave({
        data: {
          entities: entitiesWithTINType,
          user: {
            ...values.user,
            ...(!isExistingTIN && {
              tin: {
                ...values.user.tin,
              },
            }),
          },
        },
      });

      const unsignedW9 = w9.entities.find((entity) => entity && !entity.isSigned);

      setUnsignedDocuments(unsignedW9);

      if (typeof unsignedW9 !== 'undefined') {
        setModalOpen?.(true);
      } else {
        goNextStep();
      }
    } catch (error) {
      console.error(error);

      if (error?.[0]?.errorCode === 'INVALID_ADDRESS') {
        setIsErrorToastVisible(true);
        setErrorText(error?.[0]?.message);
      } else {
        setShowSSNError(true);
      }
    }
  }

  function validateSSN(value) {
    const { data } = currentStepFormData || {};

    if (data?.user?.tin?.tinLastDigits) {
      return required(value) || ssnValidation(value);
    } else {
      return required(value) || ssnValidationFirstTime(value);
    }
  }

  function handleOutsideUsAddressCallback({ form, outsideUs, name }) {
    form.change(`${name}.address.outsideUS`, outsideUs);
  }

  const handleToastClose = () => {
    setIsErrorToastVisible(false);
  };

  return (
    <StepFormLayout
      title={USER_TAXES_STRINGS.title}
      onSubmit={handleSubmit}
      initialValues={getInitialValues()}
      handleBackStep={goPreviousStep}
      showUnitAddress={false}
      disableProgressBar
      getForm={({ form }) => {
        const { values } = form.getState();

        const hasBusiness = Boolean(values.entities?.filter((entity) => entity.entityType === 'Business').length);

        return (
          <>
            {!hasBusiness && (
              <FormLayout.Section firstSection sectionTitle={USER_TAXES_STRINGS.individual_title}>
                <Row>
                  <Col md={6}>
                    {showSSNError ? (
                      <Tooltip
                        open
                        contentStyle={{ width: 300, backgroundColor: '#ff5661', padding: '12px', marginTop: '20px' }}
                        on={[]}
                        arrowStyle={{ backgroundColor: 'transparent', color: '#ff5661' }}
                        trigger={
                          <div>
                            <Field
                              placeholder={USER_TAXES_STRINGS.full_ssn_itin}
                              component={InputFinalFormAdapter}
                              name="user.tin.tin"
                              validate={validateSSN}
                              mask={maskSSN}
                              unmask={unmaskSSN}
                              error
                            />
                          </div>
                        }
                      >
                        <Text>Ops! This SSN doesn’t see valid. Please try again.</Text>
                      </Tooltip>
                    ) : (
                      <Field
                        placeholder={USER_TAXES_STRINGS.full_ssn_itin}
                        component={InputFinalFormAdapter}
                        name="user.tin.tin"
                        validate={validateSSN}
                        mask={maskSSN}
                        unmask={unmaskSSN}
                      />
                    )}
                  </Col>
                </Row>
              </FormLayout.Section>
            )}
            {hasBusiness && (
              <>
                <FieldArray name="entities">
                  {({ fields }) => {
                    return fields.map((name, index) => {
                      const business = fields.value[index];

                      return (
                        <FormLayout.Section
                          key={index}
                          sectionTitle={formatString(USER_TAXES_STRINGS.business_title, {
                            businessName: business.name,
                          })}
                        >
                          <Row>
                            <Col md={6}>
                              <Field
                                placeholder={USER_TAXES_STRINGS.business_entity_type}
                                component={DropdownFinalFormAdapter}
                                name={`${name}.business.type`}
                                validate={required}
                                items={businessEntities}
                              />
                            </Col>
                            <Condition when={`${name}.business.type`} is={BusinessEntityTypes.LLC}>
                              <Col md={6}>
                                <Field
                                  placeholder={USER_TAXES_STRINGS.llc_tax_classification}
                                  component={DropdownFinalFormAdapter}
                                  name={`${name}.business.classification`}
                                  validate={required}
                                  items={llcTaxClassifications}
                                />
                              </Col>
                            </Condition>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <Field
                                placeholder={USER_TAXES_STRINGS.business_as}
                                component={InputFinalFormAdapter}
                                validate={maxLength(150)}
                                name={`${name}.business.dba`}
                              />
                            </Col>
                            {!(
                              business.type === BusinessEntityTypes.LLC &&
                              business.classification === LLCTaxClassification.SingleMemberLLC
                            ) && (
                              <Col md={6}>
                                <Field
                                  placeholder={USER_TAXES_STRINGS.ein}
                                  validate={composeValidators(required, einValidation)}
                                  component={InputFinalFormAdapter}
                                  name={`${name}.tin.tin`}
                                  mask={maskEIN}
                                  unmask={unmaskEIN}
                                />
                              </Col>
                            )}
                          </Row>
                          <Row>
                            <Col md={12}>
                              <AddressField
                                allowOutsideUs
                                outsideUs={business?.address?.outsideUS}
                                outsideUsAddressCallback={(outsideUs) =>
                                  handleOutsideUsAddressCallback({ form, outsideUs, name })
                                }
                                placeholder="Business Address"
                                form={form}
                                name={`${name}.address`}
                              />
                            </Col>
                          </Row>
                        </FormLayout.Section>
                      );
                    });
                  }}
                </FieldArray>
                <FormLayout.Section sectionTitle={USER_TAXES_STRINGS.verification}>
                  <Row>
                    <Col md={6}>
                      <Field
                        placeholder={USER_TAXES_STRINGS.full_ssn_itin}
                        component={InputFinalFormAdapter}
                        name="user.tin.tin"
                        validate={validateSSN}
                        mask={maskSSN}
                        unmask={unmaskSSN}
                      />
                    </Col>
                  </Row>
                </FormLayout.Section>
              </>
            )}
            <UserPaymentW9 showModal={isModalOpen} onHide={setModalClose} unsignedDocument={unsignedDocument} />
            <Toast isVisible={isErrorToastVisible} onClose={handleToastClose} variant="danger">
              {errorText}
            </Toast>
          </>
        );
      }}
    />
  );
}
