import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useModal } from '@belong/common';
import { ToastProps } from '@belong/ui';

/** Use it to show a dynamic toast when a query param is present*/
export const useToastWithQueryParams = ({
  urlToastKey = 'error',
  toastVariant = 'danger',
}: {
  urlToastKey?: string;
  toastVariant?: ToastProps['variant'];
}) => {
  const history = useHistory();
  const [isToastOpen, openToast, closeToast] = useModal();
  const [toastMessage, setToastMessage] = useState<string>();

  useEffect(() => {
    if (history.location.search) {
      const toastMessageFromQuery = new URLSearchParams(history.location.search).get(urlToastKey);
      if (!toastMessageFromQuery) return;
      setToastMessage(toastMessageFromQuery);
      openToast();
    }
  }, [history.location.search, openToast, urlToastKey]);

  return {
    isToastOpen,
    closeToast,
    toastConfig: {
      message: toastMessage,
      variant: toastVariant,
    },
  };
};
